<template>
  <div class="app-permissions-warning">
    <alert-svg />
    You don't have permissions to all products. Contact a content manager.
  </div>
</template>

<script>
import AlertSvg from '@/assets/images/icons/alert-triangle.svg'

export default {
  components: {
    AlertSvg
  }
}
</script>
<style lang="scss" scoped>
.app-permissions-warning {
  margin-left: 50px;
  margin-top: 20px;
  background: $orange-1;
  display: inline-block;
  color: white;
  padding: 3px;
  border: 1px solid $grey-3;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    margin-top: -4px;
  }
}
</style>
