<template>
  <div class="phone">
    <label><slot name="label" /></label>
    <div class="phone-screen">
      <div class="first-header"></div>
      <div class="second-header"></div>
      <slot name="content"  />
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.phone {
  border: 1px solid $space-gray;
  border-radius: 10px;
  padding: 20px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $space-gray-30;

  label {
    font-weight: 700;
  }

  .phone-screen {
    width: 375px;
    height: 812px;
    border: 1px solid $space-gray;
    background: white;
    display: flex;
    flex-direction: column;

    .first-header {
      height: 44px;
      width: 100%;
      background: $space-gray;
    }
    .second-header {
      height: 44px;
      width: 100%;
      background: $steel-gray;
    }
  }
}
</style>
