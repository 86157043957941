<template>
  <div
    v-if="topic"
    class="topics-preview-component"
  >
    <div class="topics-preview-container left">
      <div
        class="title"
        :class="{ 'root-title': rootTitle(), 'subtopic-title': subtopicClass(), 'error': errorTitle, 'selected-topic': selectedTopicClass() }"
        @click="clickedTitle"
      >
        <hierarchy-menu
          v-if="draftMode && canWriteToAsset"
          v-show="!editingTitle"
          :prop-asset.sync="topic"
        />
        <div
          v-if="!editingTitle"
          class="title-preview"
          v-html="topic.title"
        />
        <div
          v-if="editingTitle"
          class="edit-container"
        >
          <tinymce-editor
            ref="title-editor"
            v-model="topic.title"
            v-on-clickaway="editTitleToggle"
            :init="tinymceConfig({plugins: ['code', 'autoresize', 'lists'], forced_root_block : ''}, tinymceSmallConfig)"
            name="title"
            @onInit="setCaretPosition"
          />
        </div>
      </div>
      <error-message
        v-if="errorTitle"
        :message="errorMessage"
      />
      <div
        class="content"
        :class="{ 'root-content': rootTitle()}"
        @click="clickedContent"
      >
        <div
          v-if="!editingContent"
          :class="{ 'subtopic-content': subtopicClass(), 'empty-content': emptyContent()}"
          v-html="setPreviewContent(topic.content)"
        />
        <div
          v-if="editingContent"
          class="edit-container"
        >
          <tinymce-editor
            ref="content-editor"
            v-model="topic.content"
            v-on-clickaway="editContentToggle"
            :init="tinymceConfig({plugins: ['code', 'image', 'table', 'lists', 'autoresize'], forced_root_block : ''}, tinymceFullConfig)"
            use-custom-image-handler
            name="content"
            @onInit="setCaretPosition"
          />
        </div>
      </div>
      <div
        class="asset-tag-container"
        @click="modifyingTopic"
      >
        <asset-associations
          :prop-asset.sync="topic"
          :available-contexts="leftAvailableContexts"
          show-context-names="true"
        />
      </div>
    </div>

    <div class="right">
      <div
        class="primary-action-tag"
        @click="modifyingTopic"
      >
        <asset-associations
          :prop-asset.sync="topic"
          human-context="Primary Action"
          :available-contexts="rightAvailableContexts"
          show-context-names="true"
        />
      </div>
    </div>

    <preview
      v-for="child in childrenOfParentId(topic)"
      :key="child.id"
      :topicId="child.id"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Preview from './Preview'
import TinymceEditor from '@tinymce/tinymce-vue'
import tinymce from '../../../mixins/tinymce.js'
import { mixin as clickaway } from 'vue-clickaway2'
import HierarchyMenu from './HierarchyMenu.vue'
import AssetAssociations from '../shared/AssetAssociations.vue'

export default {
  name: 'Preview',
  components: {
    Preview,
    HierarchyMenu,
    TinymceEditor,
    AssetAssociations
  },
  mixins: [clickaway, tinymce],
  props: {
    topicId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      editingTitle: false,
      editingContent: false,
      errorTitle: false,
      errorMessage: '',
      leftAvailableContexts: [
        'Content',
        'Asset Tray'
      ],
      rightAvailableContexts: [
        'Primary Action'
      ],
      onLoad: true
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'topics',
      'topLevelAsset',
      'draftMode',
      'errors',
      'topicsByParentId',
      'modifiedAssets',
      'canWriteToAsset',
      'currentUser',
      'childrenOfParentId',
      'findTopicById'
    ]),
    topic () {
      return this.findTopicById(this.topicId)
    }
  },
  watch: {
    errors (val, oldVal) {
      var index = this.modifiedAssets.indexOf(this.topic)
      this.errorTitle = !!((val[index] && val[index].title))
      if (val[index] && val[index].title) {
        if (val[index].title[0].includes('characters')) {
          this.errorMessage = 'Title is too long (maximum is 255 characters)'
        }
        if (val[index].title[0].includes('blank')) {
          this.errorMessage = 'Title can\'t be blank (minimum is 2 characters)'
        }
      }
    }
  },
  mounted () {
    this.setPreviewContent(this.topic.content)
  },
  updated () {
    this.onLoad = false
  },
  methods: {
    ...mapActions([
      'updateTopic'
    ]),
    ...mapMutations([
      'addModifiedAssetId'
    ]),
    setPreviewContent (html) {
      // this method is to display video content properly
      const d = document.createElement('div')
      d.innerHTML = html || 'Enter content...'
      const iframes = d.getElementsByTagName('iframe')
      // iterate through all the iframes we have
      for (const iframeEl of Array.from(iframes)) {
        const parent = iframeEl.parentNode
        // If the parent already has iframe-container class then continue
        if (parent.classList.contains('iframe-container')) continue
        // Create new parent div for the iframe and add a class to them for styling
        const iframeContainerEl = document.createElement('div')
        iframeContainerEl.classList.add('iframe-container')
        const currentContent = parent.removeChild(iframeEl)
        iframeContainerEl.appendChild(currentContent)
        parent.appendChild(iframeContainerEl)
      }
      return d.outerHTML
    },
    modifyingTopic () {
      this.addModifiedAssetId({ id: this.topic.id })
    },
    rootTitle () {
      if (this.topic.live_layer) {
        var root = this.topics.filter(k => k.live_topic_id == this.topic.id)[0]
        return (root == this.topLevelAsset)
      } else {
        return (this.topic == this.topLevelAsset)
      }
    },
    subtopicClass () {
      if (this.topic.live_layer) {
        var root = this.topics.filter(k => k.live_topic_id == this.topic.id)[0]
        return (root != this.topLevelAsset)
      } else {
        return (this.topic != this.topLevelAsset)
      }
    },
    selectedTopicClass () {
      return this.asset.id == this.topic.id
    },
    emptyContent () {
      return !this.topic.content
    },
    editTitleToggle (ev) {
      if (this.draftMode) {
        if (this.$refs['title-editor']) {
          if (this.shortCircuit(ev)) return
        }
        this.editingTitle = !this.editingTitle
        if (this.editingTitle) {
          this.modifyingTopic()
          this.setCharPosition(ev, 'title-editor')
        }
      }
    },
    clickedTitle (ev) {
      if (this.canWriteToAsset) {
        this.editTitleToggle(ev)
      } else if (this.currentUser.permissions.can_write_change_requests) {
        this.$modal.show('change-request-modal', {
          currentContent: this.topic.title,
          assetId: this.topic.id,
          assetType: 'Topic',
          assetColumn: 'title'
        })
      }
    },
    clickedContent (ev) {
      if (this.canWriteToAsset) {
        this.editContentToggle(ev)
      } else if (this.currentUser.permissions.can_write_change_requests) {
        this.$modal.show('change-request-modal', {
          currentContent: this.topic.content,
          assetId: this.topic.id,
          assetType: 'Topic',
          assetColumn: 'content'
        })
      }
    },
    editContentToggle (ev) {
      if (this.draftMode) {
        if (this.$refs['content-editor']) {
          if (this.shortCircuit(ev)) return
        }
        this.editingContent = !this.editingContent
        if (this.editingContent) {
          this.modifyingTopic()
          this.setCharPosition(ev, 'content-editor')
        } else {
          this.$nextTick(() => {
            this.setPreviewContent(this.topic.content)
          })
        }
      }
    }
  }
}
</script>
<style lang="scss">
.topics-preview-component {
  .left {
    width: 80%;
    float: left;
    .topic-preview-section {
      padding-bottom: 100px;
    }
  }
  .right {
    width: 20%;
    float: left;
    padding-left: 20px;
    padding-top: 15px;
    .primary-action-tag {
      padding: 5px 0;
    }
  }

  .selected-topic {
    background-color: $blue-4 !important;
    color: $white-2;
    &.root-title {
      padding-left: 15px;
    }
  }
  .empty-content {
      color: $grey-3;
    }
  .edit-container {
    position:relative;
    border: 1px solid $blue-4;
    -webkit-box-shadow: 0px 0px 3px 3px $blue-5;
    box-shadow: 0px 0px 3px 3px $blue-5;
    z-index: 5;
    .mce-container {
      background: $white-2;
    }
  }
  .title {
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 22px;
    min-height: 40px;
    clear: both;
    .hierarchy-menu-container {
      width: 20px;
      float: right;
      right: 0px;
      font-weight: normal;
    }
    .title-preview {
      min-height: 24px;
    }
  }
  .content {
    min-height: 20px;
    margin-bottom: 20px;
    overflow: auto;
  }

  .root-title {
    font-size: 1.2em;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    .title-preview {
      min-height: 28px;
    }
  }
  .root-content {
    margin-top: 10px;
  }

  .subtopic-title {
    background-color: $grey-4;
    padding: 10px;
  }
  .iframe-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* The height of the video will now be 56.25% of the width */
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border: none;
    }
  }
  .subtopic-content {
    padding-top: 10px;
    padding-left: 20px;
    position: relative;
  }
  .asset-tag-button {
    margin: 15px 15px 15px 0;
  }

  .asset-tag-container {
    padding: 15px 0;
  }
}

</style>
