<template>
  <div
    v-if="row"
    class="hierarchy-item"
    @click="selectCategory()"
  >
    <folder-svg />
    <div
      class="hierarchy-title"
      :title="category.name"
      v-html="truncatedTitle()"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import axios from 'axios'
import FolderSvg from '@/assets/images/icons/folder.svg'

export default {
  components: {
    FolderSvg
  },
  props: [
    'row',
    'columnIndex'
  ],
  data () {
    return {
      showMenu: {}
    }
  },
  computed: {
    category () {
      return this.row.rowObject
    }
  },
  methods: {
    truncatedTitle () {
      if (this.category.name && (this.category.name.length > 55)) {
        return this.category.name.substring(0, 55) + '...'
      } else {
        return this.category.name
      }
    },
    selectCategory () {
      this.$store.commit('appHierarchy/selectRow', {
        columnIndex: this.columnIndex,
        row: this.row
      })

      //      The following makes the columns disappear properly when clicking around but
      //      it causes the focus in the hierarchy to jump as well...
      //        this.$store.commit('appHierarchy/clearHierarchyColumnsAfter', {
      //          columnIndex: this.columnIndex
      //        })

      // SET ASSET TYPE
      this.setAssetType({ assetType: 'category' })

      axios.get(railsRoutes.api_path({ type: 'categories', id: this.category.id }), {
        params: {
        }
      }).then(response => {
        var rows = response.data.categories.map(x => ({ rowType: 'Category', rowObject: x }))
        rows = rows.concat(response.data.items.map(x => ({ rowType: 'Item', rowObject: x })))
        rows = rows.concat(response.data.topics.map(x => ({ rowType: 'Topic', rowObject: x })))
        rows = rows.concat(response.data.attachments.map(x => ({ rowType: 'Attachment', rowObject: x })))
        // TODO: We'll just add more types in here

        this.$store.commit('appHierarchy/addHierarchyColumn', {
          parent: this.category,
          parentType: 'Category',
          rows: rows,
          columnIndex: this.columnIndex
        })
      })
    },
    ...mapMutations([
      'setAssetType'
    ]),
    ...mapActions([
    ])
  }
}
</script>
