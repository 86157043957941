<template>
  <div class="phones">
    <mobile-preview>
      <template v-slot:label>
        &nbsp;
      </template>
      <template v-slot:content>
        <div class="front">
          <div class="content">
            <div class="root">
              <h1>{{ topLevelAsset.title }}</h1>
              <div class="root-content" v-html="topLevelAsset.content" />
            </div>
            <div v-for="(topic, index) in subTopics(topLevelAsset.id)" class="accordion">
              <div class="handle" @click="showContent(index)">{{ topic.title }}</div>
              <div :class="['subtopic-content', { 'visible': showSubtopics.includes(index) }]" v-html="topic.content" />
            </div>
            <div class="topic-footer" />
          </div>
          <div class="footer-bar" />
        </div>
      </template>
    </mobile-preview>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MobilePreview from '../shared/MobilePreview.vue'

export default {
  components: {
    MobilePreview
  },
  data () {
    return {
      showSubtopics: []
    }
  },
  computed: {
    ...mapGetters([
      'topLevelAsset',
      'topics',
      'subTopics'
    ])
  },
  methods: {
    showContent (index) {
      if(this.showSubtopics.includes(index)) {
        // remove from index
        this.showSubtopics.splice(this.showSubtopics.indexOf(index), 1)
      } else {
        this.showSubtopics.push(index)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.phones {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-evenly;
  .choice ::v-deep p {
    margin: 0px;
  }

  .front {
    display: flex;
    flex-direction: column;

    .content {
      height: 680px;
      overflow-y: scroll;

      .root {
        padding: 0px 19px;
        h1 {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          margin-top: 16px;
          margin-bottom: 16px;
        }
        .root-content {
          padding-bottom: 45px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
        }
      }

      .accordion {
        .handle {
          min-height: 50px;
          border-top: 1px solid $space-gray;
          border-bottom: 1px solid $space-gray;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 14px 19px;

          font-family: "Open Sans";
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
        }
        .subtopic-content {
          display: none;
          padding: 23px 19px 23px 19px;
          background: $space-gray-30;

          font-family: "Open Sans";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
        }
        .visible {
          display: block !important;
          border-bottom: 1px solid $space-gray;
        }
      }
      .topic-footer {
        height: 188px;
      }

    }
    .footer-bar {
      height: 44px;
      background: $space-gray-30;
    }
  }
}
</style>