<template>
  <div class="toggle-box">
    <div :class="['edit', {'active': mode == 'edit'}]" @click="toggle('edit')">
      <edit-svg />
      Edit
    </div>
    <div :class="['preview', {'active': mode == 'preview'}]" @click="toggle('preview')">
      <preview-svg />
      Preview
    </div>
  </div>
</template>

<script>
import EditSvg from '@/assets/images/icons/edit-regular.svg'
import PreviewSvg from '@/assets/images/icons/mobile-alt-solid.svg'

export default {
  components: {
    EditSvg,
    PreviewSvg
  },
  data () {
    return {
      mode: 'edit'
    }
  },
  methods: {
    toggle(mode) {
      this.mode = mode
      this.$emit(mode)
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-box {
  border: 1px solid $space-gray;
  border-radius: 5px;
  display: flex;
  cursor: pointer;

  div {
    padding: 5px 8px;
    width: 120px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 18px;
      margin-right: 10px;
      color: $steel-gray;
    }
    transition: 0.3s;
  }
  .edit {
    border-right: 1px solid $space-gray;
  }
  .active {
    background: $eastern-blue;
    color: $smoke-gray;
    svg {
      color: $smoke-gray;
    }
  }
}
</style>