<template>
  <div class="marquee-container">
    <div
      :id="'deck-marquee-' + index"
      class="marquee-content-box"
    >
      <div class="marquee-content-section">
        <div class="marquee-asset-section">
            <div class="column">
              <label class="marquee-label weight-600">Icon Attachment ID</label>
              <input
                v-model="marquee.icon_id"
                class="marquee-input hlt-input"
                type="text"
                required
                :class="{'error': marquee.errors && marquee.errors.icon_id}"
                placeholder="Icon ID"
                :disabled="!draftMode"
              >
              <error-message
                v-if="marquee.errors && marquee.errors.icon_id"
                message="must have a valid ID"
              />
            </div>
            <div class="column">
              <label class="marquee-label weight-600">Image Attachment ID</label>
              <input
                v-model="marquee.image_id"
                class="marquee-input hlt-input"
                type="text"
                required
                :class="{'error': marquee.errors && marquee.errors.image_id}"
                placeholder="Write Attachment ID"
                :disabled="!draftMode"
              >
              <error-message
                v-if="marquee.errors && marquee.image_id"
                message="must have a valid attachment ID"
              />
            </div>
        </div>
        <div class="marquee-title marquee-area">
          <label class="marquee-label weight-600">Title</label>
          <input
            v-model="marquee.title"
            class="marquee-input hlt-input"
            type="text"
            required
            :class="{'error': marquee.errors && marquee.errors.title }"
            placeholder="Write marquee title"
            :disabled="!draftMode"
          >
          <error-message
            v-if="marquee.errors && marquee.errors.title"
            message="can't be blank"
          />
        </div>
        <div class="marquee-description marquee-area">
          <div
            class="marquee-container description-area"
            @click="e => moduleToggle(e, 'marqueeDescription', 'marquee-description')"
          >
            <div
              v-if="!editing['marqueeDescription']"
              class="marquee-edit-html-description"
            >
              <label
                class="container-label weight-600"
                for="c-description"
              >Description</label>
              <div
                :class="[{ 'empty-content': emptyContent(marquee.description) , 'disabled': !draftMode }, 'container-textarea']"
                name="c-description"
                v-html="marquee.description || 'Write asset description'"
              />
            </div>

            <div
              v-if="editing['marqueeDescription']"
              class="marquee-edit-active description-active"
            >
              <label
                class="container-label weight-600"
                for="description"
              >Description</label>
              <tinymce-editor
                ref="marquee-description"
                v-model="marquee.description"
                v-on-clickaway="e => moduleToggle(e, 'marqueeDescription', 'marquee-description')"
                :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                class="marquee-tinymce"
                name="title"
                @onInit="setCaretPosition"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TinymceEditor from '@tinymce/tinymce-vue'
import tinymce from '../../../mixins/tinymce'
import { mixin as clickaway } from 'vue-clickaway2'
import { mapGetters } from 'vuex'

export default {
  components: {
    TinymceEditor
  },
  mixins: [
    clickaway,
    tinymce
  ],
  props: {
    marquee: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    },
    draftMode: {
      type: Boolean
    }
  },
  data () {
    return {
      editing: {
        marqueeDescription: false
      }
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'errors'
    ])
  },
  methods: {
    saveToParent (value) {
      this.$emit('saveDisabled', value)
    },
    moduleToggle (event, prop, reference) {
      if (this.draftMode) {
        if (!event) return
        if (event.target.className.includes('mce') || event.target.id.includes('mce')) {
          return
        }
        Object.keys(this.editing).forEach(key => {
          this.editing[key] = prop == key ? !this.editing[prop] : false
        })
        this.setCharPosition(event, reference)
      }
    },
    emptyContent (content) {
      return content === null || content === ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .marquee-content-box {
    .marquee-content-section {
      padding: 0;
      .marquee-asset-section {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        height: 100%;
        .marquee-asset {
          margin-bottom: 1rem;
          .hlt-input, .hlt-select {
            width: 200px;
            margin-bottom: 0;
            @media (max-width: 1500px) {
              margin-right: 15px;
            }
          }
        }
      }
      .container-textarea {
        &.disabled {
          background: $grey-4;
        }
      }
    }
  }
  .column {
    padding-left: 0px;
  }

</style>
