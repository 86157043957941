<template>
  <div
    class="hierarchy-item"
    @click="selectApp()"
  >
    <div
      class="hierarchy-title"
      :title="app.internal_name"
    >
      <span class="app-title">{{ app.internal_name | truncate(55) }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import axios from 'axios'

export default {
  props: [
    'row',
    'columnIndex'
  ],
  data () {
    return {
      showMenu: {}
    }
  },
  computed: {
    app () {
      return this.row.rowObject
    },
    iconUrl () {
      return this.app.icon_url
    },
    ...mapGetters([
    ])
  },
  methods: {
    selectApp () {
      this.setAssetType({ assetType: 'app' })

      // TODO:
      // Maybe pass in the menu here with callback functions?
      // menu: {add: addCallback(), delete: deleteCallback()}
      // We only need 1 menu state really since the last selected will determine the menu state
      // Then when the button is clicked we just find the key and execute the callback

      // Another alternative is instead of callbacks we have each row type and its corresponding menu behavior defined
      // somewhere in a app_hierarchy_menu. One benefit is all the menu logic is contained in one place
      // somehow we'd need to organize it so it doesn't turn into a mess

      this.$store.commit('appHierarchy/selectRow', {
        columnIndex: this.columnIndex,
        row: this.row
      })

      // 1. query the api for nav groups
      // 2. push a new row into appHierarchyColumns
      axios.get(railsRoutes.api_path({ type: 'navigation_groups' }), {
        params: { 'app_id': this.app.id }
      }).then(response => {
        this.$store.commit('appHierarchy/addHierarchyColumn', {
          parent: this.app,
          parentType: 'App',
          rows: response.data.records.map(x => ({ rowType: 'NavigationGroup', rowObject: x })),
          columnIndex: this.columnIndex
        })
      })
    },
    ...mapMutations([
      'setAssetType'
    ]),
    ...mapActions([
    ])
  }
}
</script>
