export default {
  data () {
    return {
      editingAssetDescription: false
    }
  },
  methods: {
    setEditingAssetDescription (val = true) {
      this.editingAssetDescription = val
    }
  }
}
