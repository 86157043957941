<template>
  <div class="recommendation">
    <label>Catalog Page ID</label>
    <input v-model="recommendation.catalog_page_id" :disabled="!draftMode">
  </div>
</template>

<script>
export default {
  props: {
    recommendation: {
      type: Object,
      required: true
    },
    draftMode: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
