<template>
  <div class="catalog-page">
    <label>Catalog Page ID</label>
    <input v-model="catalogPage.catalog_page_id" :disabled="!draftMode">
  </div>
</template>

<script>
export default {
  props: {
    catalogPage: {
      type: Object,
      required: true
    },
    draftMode: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
