<template>
  <div class="asset-description" v-if="!editing">
    <div class="display-attachment">
      <img v-if="asset.display_attachment_url" :src="asset.display_attachment_url" alt="display attachment" />
      <p v-else>No Display Attachment Found</p>
    </div>
    <div class="display-text">
      <h3>{{ asset.display_title | truncate(50) }}</h3>
      <div class="meta">ID: {{ asset.id }} | Level: {{ level }}</div>
      <div class="display-description">{{ asset.display_description | truncate(76) }}</div>
    </div>
    <div>
      <button class="hlt-button secondary" v-if="!asset.live_layer" @click="setEditing">Modify</button>
    </div>
  </div>

  <div class="asset-description editing" v-else>
    <div class="display-text">
      <h3 class="id">ID: {{ asset.id }}</h3>
      <div class="row-one">
        <div class="display-title">
          <label>Display Title</label>
          <input v-model="asset.display_title" type="text" />
        </div>
        <div class="attachment-id">
          <label>Attachment ID</label>
          <input type="number" v-model="asset.display_attachment_id" />
        </div>
      </div>
      <div>
        <label>Display Description</label>
        <textarea :ref="'description'" v-model="asset.display_description" @input="adjustDescriptionHeight" />
      </div>
      <div class="difficulty-level">
        <div>
          <label>Difficulty Lower Bound</label>
          <input v-model="asset.difficulty_lower_bound" type="number" />
        </div>
        <div>
          <label>Difficulty Upper Bound</label>
          <input v-model="asset.difficulty_upper_bound" type="number" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    asset: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'assetType'
    ]),
    level () {
      if (this.asset.difficulty_lower_bound != this.asset.difficulty_upper_bound) {
        return `${this.asset.difficulty_lower_bound} - ${this.asset.difficulty_upper_bound}`
      } else {
        return `${this.asset.difficulty_upper_bound}`
      }
    }
  },
  mounted () {
    this.$watch('editing', (newEditing, oldEditing) => {
      if (newEditing && !oldEditing) {
        this.adjustDescriptionHeight()
      }
    }, { immediate: true })
  },
  methods: {
    adjustDescriptionHeight () {
      this.$refs.description.style.height = 'auto'
      const elHeight = this.$refs.description.scrollHeight
      this.$refs.description.style.height = (elHeight + 25) + 'px'
    },
    setEditing () {
      this.$emit('set-editing')
    }
  }
}
</script>

<style lang="scss" scoped>
.asset-description {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
  color: $space-black-20;
  padding: 40px 60px;

  border-bottom: 1px solid $space-gray-20;
  background: $smoke-gray;

  .display-attachment {
    img {
      min-width: 200px;
      width: 200px;
      border-radius: 8px;
    }
    margin-right: 40px;
  }
  .display-text {
    flex-grow: 1;
    h3 {
      margin-bottom: 0px;
      font-size: 2em;
      font-weight: 700;
      color: $space-black;
    }
    .meta {
      font-weight: 700;
    }
    .display-description {
      color: $space-gray;
    }
  }
  label {
    font-weight: 700;
    color: $space-black;
  }
  .row-one {
    display: flex;
    .display-title {
      flex-grow: 1;
    }
    .attachment-id {
      margin-left: 40px;
    }
  }
  .difficulty-level {
    display: flex;
    div {
      margin-right: 40px;
    }
  }
  .hlt-button {
    margin-left: 30px;
    min-width: 100px;
  }

  &.editing {
    .id {
      font-size: 1.5em;
    }
  }
}
</style>
