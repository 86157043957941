<template>
  <div>
    <div class="inner-content inner-solid">
      <div class="inner-top-bar sticky-top-bar">
        <h1>Edit Navigation Group</h1>
        <h4>ID: {{ asset.id }}</h4>
        <button
          :class="{ disabled : (saveDisabled) }"
          class="button solid-green-button"
          @click="save()"
        >
          Save
        </button>
      </div>
      <div class="edit-content-container">
        <div class="left-container">
          <div>
            <label>Name</label>
            <div class="medium-8">
              <input
                v-model="asset.name"
                type="text"
                :disabled="saveDisabled"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'

export default {
  data () {
    return {
      saveDisabled: false
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'currentUser'
    ])
  },
  created () {
    if (!this.currentUser.permissions.can_write_navigation_groups) {
      this.saveDisabled = true
    }
  },
  methods: {
    save () {
      if (!this.saveDisabled) {
        this.saveDisabled = true
        if (this.asset.id) {
          this.update({ asset: this.asset, url: railsRoutes.api_path({ type: 'navigation_groups' }) }).then(response => {
          //              this.edit(response.data.records[0])
            this.saveDisabled = false
            this.$notify({ title: 'Navigation Group Saved', type: 'success' })
          }).catch(error => {
            console.error(error)
            this.saveDisabled = false
            this.$notify({ title: 'Error saving Navigation Group', type: 'error' })
          })
        } else {
          // This should never happen
          this.saveDisabled = false
          this.$notify({ title: 'Error saving Navigation Group', type: 'error' })
        }
      }
    },
    deleteRecord () {
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    },
    ...mapActions([
      'edit',
      'update',
      'delete'
    ]),
    ...mapMutations([
      'setAssetType'
    ])
  }
}
</script>
