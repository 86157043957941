<template>
  <div class="duplicate-warning">
    <warning-svg />
    <span>
      <strong>WARNING:</strong> This asset may be duplicated. Please consider modifying this content to avoid duplication. Duplicate data makes long term maintenance untenable.
    </span>
  </div>
</template>

<script>
import WarningSvg from '@/assets/images/icons/warning-triangle.svg'

export default {
  components: {
    WarningSvg
  }
}
</script>

<style lang="scss">
  .duplicate-warning {
    display: flex;
    justify-content: space-evenly;
    background: lighten($orange-1, 30%);
    border: 2px solid $orange-1;
    border-radius: 5px;
    padding: 10px;
    color: darken($orange-1, 20%);
    margin-bottom: 10px;
    span {
      padding: 0;
      font-size: .85rem;
      margin: 0 10px;
      font-weight: 400;
    }
    .close-svg {
      cursor: pointer;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
