<template>
  <div class="attachments-edit-section">
    <delete-confirmation>
      <template slot="name">
        {{ asset.name }}
      </template>
      <template slot="body">
        <div>
          {{ appsCount }} apps use this attachment.
        </div>
        <div>
          Attached to {{ asset.attachables_count }} other objects.
        </div>
      </template>
    </delete-confirmation>
    <div class="asset-description-container">
      <div class="asset-description-inner">
        <asset-description :asset="asset" :editing="editingAssetDescription" @set-editing="setEditingAssetDescription"/>
      </div>
    </div>
    <div class="inner-content">
      <div class="edit-content-container">
        <div class="custom-preview">
          <label>Custom Preview</label>
          <div v-if="hasPreview">
            <img
              :src="asset.small_custom_preview_url"
              width="200"
            >
          </div>
          <div v-else>
            <img
              :src="asset.small_image_url"
              width="200"
              height="200"
            >
          </div>
          <input
            ref="custom_preview"
            type="file"
            class="upload-file-button"
            name="custom_preview"
            :disabled="!canWriteToAsset"
            @change="handleFileUpload('custom_preview'); save()"
          >
          <small
            v-if="(/video/i.test(asset.content_content_type))"
            class="preview-notice"
          >
            Notice: You can have either a video frame grab or a custom image, but not both.
          </small>
        </div>

        <div class="left-container">
          <app-permission-alert v-if="!hasAppPermission" />
          <div>
            <div class="medium-8 attachment-name">
              <label>Name</label>
              <input
                v-model="asset.name"
                type="text"
                :disabled="!canWriteToAsset"
                :class="{'error': errors['name'] }"
              >
              <error-message
                v-if="errors[&quot;name&quot;]"
                message="can't be blank"
              />
            </div>
          </div>
          <div>
            <div class="medium-8 attachment-name">
              <label>Watermark Attachment Id</label>
              <input
                v-model="asset.watermark_id"
                type="text"
                :disabled="!canWriteToAsset"
                :class="{'error': errors['watermark_id'] }"
              >
            </div>
          </div>
          <div>
            <label>Upload File</label>
            <div class="medium-8">
              <input
                ref="content"
                type="file"
                name="content"
                class="attachment_content"
                :disabled="!canWriteToAsset"
                @change="handleFileUpload('content')"
              >
            </div>
          </div>
          <div>
            <label>Info</label>
            <div class="medium-12">
              <div class="info medium-8">
                <div
                  v-if="asset.large_content_url != ''"
                  class="row"
                >
                  <div class="medium-2">
                    <label>Large Image URL</label>
                  </div>
                  <div
                    class="asset-url medium-10"
                    @click="copyToClipboard({selector: '#attachment_large_content_url'})"
                  >
                    <input
                      id="attachment_large_content_url"
                      type="hidden"
                      :value="asset.large_content_url"
                      class="hidden"
                    >
                    {{ asset.large_content_url }}
                  </div>
                </div>

                <div
                  v-else
                  class="row"
                >
                  <div class="medium-2">
                    <label>Original File URL</label>
                  </div>
                  <div
                    class="asset-url medium-10"
                    @click="copyToClipboard({selector: '#attachment_url'})"
                  >
                    <input
                      id="attachment_url"
                      type="hidden"
                      :value="asset.url"
                      class="hidden"
                      :disabled="true"
                    >
                    {{ asset.url }}
                  </div>
                </div>

                <div
                  v-if="(/video/i.test(asset.content_content_type))"
                >
                  <label>Video Embed Code</label>
                  <div
                    @click="copyToClipboard({selector: '#attachment_video_code'})"
                  >
                    <textarea
                      id="attachment_video_code"
                      v-html="videoEmbedCode"
                      readonly
                    />
                  </div>
                </div>

                <div
                  v-if="(/audio/i.test(asset.content_content_type))"
                >
                  <label>Audio Embed Code</label>
                  <div
                    @click="copyToClipboard({selector: '#attachment_audio_code'})"
                  >
                    <textarea
                      id="attachment_audio_code"
                      v-html="audioEmbedCode"
                      readonly
                    />
                  </div>
                </div>

                <div
                  v-if="(/image/i.test(asset.content_content_type))"
                >
                  <label>Image Embed Code</label>
                  <div
                    @click="copyToClipboard({selector: '#attachment_image_code'})"
                  >
                    <textarea
                      id="attachment_image_code"
                      v-html="imageEmbedCode"
                      readonly
                    />
                  </div>
                </div>


                <div
                  v-if="asset.transcription"
                >
                  <label>Transcription</label>
                  <div
                    @click="copyToClipboard({selector: '#attachment_transcription'})"
                  >
                    <textarea
                      id="attachment_transcription"
                      v-html="asset.transcription"
                      readonly
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="medium-2">
                    <label>File Type</label>
                  </div>
                  <div class="medium-10">
                    {{ asset.content_content_type }}
                  </div>
                </div>
                <div class="row">
                  <div class="medium-2">
                    <label>File Size</label>
                  </div>
                  <div class="medium-10">
                    {{ asset.content_file_size }}
                  </div>
                </div>
                <div class="row">
                  <div class="medium-2">
                    <label>Meta Data</label>
                  </div>
                  <div class="medium-10">
                    {{ asset.content_meta }}
                  </div>
                </div>
              </div>
              <div v-if="/video/i.test(asset.content_content_type)">
                <input
                  type="hidden"
                  name="custom_preview"
                >
                <h5>Custom Preview Frame Grab</h5>
                <div class="video">
                  <video
                    ref="video"
                    :src="asset.url"
                    width="600"
                    controls="true"
                    crossorigin="anonymous"
                  />
                  <br>
                  <button
                    class="button capture"
                    :class="{ disabled : !canWriteToAsset }"
                    type="button"
                    @click="canWriteToAsset && createSnapshot()"
                  >
                    Capture
                  </button>
                  <br>
                  <div
                    id="output"
                    style="display: inline-block; top: 4px; position: relative ;border: dotted 1px #ccc; padding: 2px;"
                  >
                    <canvas
                      ref="canvas"
                      crossorigin="anonymous"
                    >{{ base64 }}</canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <product-association
          v-if="currentUser.permissions.can_read_attachments_product_associations && asset.id"
          :asset="asset"
          class="product-association-container"
        />
      </div>
    </div>
    <metadata>
      <template v-slot:left>
        <div class="module-section section">
          <label for="notes">Notes</label>
          <textarea
            v-model="asset.notes"
            name="notes"
            class="hlt-textarea"
            :disabled="!canWriteToAsset"
          />
        </div>
      </template>
      <template v-slot:right>
      </template>
    </metadata>

    <edit-footer>
      <template v-slot:left>
        <div class="left" />
      </template>
      <template v-slot:center>
        <div
          v-if="canDeleteAsset"
          class="center"
        >
          <button
            :class="['hlt-button', 'secondary', 'warning', 'delete-button']"
            @click="canDeleteAsset && deleteRecord()"
          >
            Delete Attachment
          </button>
        </div>
      </template>
      <template v-slot:right>
        <div
          v-if="canWriteToAsset"
          class="right"
        >
          <button
            :class="[{ disabled : (saveDisabled || !canWriteToAsset) }, 'hlt-button', 'primary', 'save']"
            @click="!saveDisabled && showSaveModal()"
          >
            Save Changes
          </button>
        </div>
      </template>
    </edit-footer>

    <save-modal :asset="asset" @save='save' />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ProductAssociation from '../shared/ProductAssociation'
import railsRoutes from '../../../store/rails-routes'
import DeleteConfirmation from '../shared/DeleteConfirmation'
import AppPermissionAlert from '../shared/AppPermissionAlert'
import date from '../../../mixins/date'
import EditFooter from '../shared/page_components/EditFooter'
import Metadata from '../shared/Metadata'
import AssetDescription from '../shared/AssetDescription'
import AssetDescriptionMixin from '../../../mixins/asset-description'
import SaveModal from '../shared/SaveModal.vue'

export default {
  name: 'Edit',
  components: {
    ProductAssociation,
    DeleteConfirmation,
    AppPermissionAlert,
    EditFooter,
    Metadata,
    AssetDescription,
    SaveModal
  },
  mixins: [date, AssetDescriptionMixin],
  props: ['attachmentId'],
  data () {
    return {
      saveDisabled: false,
      base64: '',
    }
  },
  computed: {
    hasPreview () {
      return this.asset.small_custom_preview_url && this.asset.small_custom_preview_url != ''
    },
    appsCount () {
      if (this.asset.id) {
        return this.asset.apps.length
      } else {
        return 'unknown'
      }
    },
    videoEmbedCode () {
      return `<p>
    <video class="video-player" poster="${this.asset.large_custom_preview_url}" controls="controls" disablepictureinpicture="" controlslist="nodownload" >
      <source data-hlt-attachment-id="${this.asset.id}" src="${this.asset.url}" />
      Your browser does not support HTML5 video.
    </video>
  </p>`
    },
    audioEmbedCode () {
      return `<p class="audio-player">
    <audio controls="controls">
      <source data-hlt-attachment-id="${this.asset.id}" src="${this.asset.url}" type="${this.asset.content_content_type}" />
    </audio>
  </p>`
    },
    imageEmbedCode () {
      return `<p>
    <img class="image" src="${this.asset.large_content_url}" />
  </p>
`
    },
    ...mapGetters([
      'asset',
      'deleteAsset',
      'canWriteToAsset',
      'canDeleteAsset',
      'hasAppPermission',
      'currentUser',
      'errors'
    ])
  },
  watch: {
    deleteAsset (val, oldVal) {
      if (val === true) {
        if (this.asset.id) {
          this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'attachments' }) }).then(response => {
            if (this.$router) {
              this.$router.push({ path: railsRoutes.cms_path({ type: 'attachments' }) })
            }
          })
        }
      }
    },
    attachmentId (to, from) {
      this.loadAsset()
    }
  },
  created () {
    this.setAssetType({ assetType: 'attachment' })
    this.loadAsset()
  },
  methods: {
    ...mapActions([
      'edit',
      'attachmentUpdate',
      'delete',
      'apiAttachmentsById',
      'copyToClipboard'
    ]),
    ...mapMutations([
      'setAssetType',
      'setErrors',
      'resetErrors'
    ]),
    loadAsset () {
      this.apiAttachmentsById({ attachmentId: this.attachmentId }).then(response => {
        var record = response.data.records[0]
        this.edit(record)
      })
    },
    handleFileUpload (name) {
      this.base64 = ''
      this.asset[name] = this.$refs[name].files[0]
    },
    showSaveModal () {
      this.$modal.show('save-modal')
    },
    save () {
      if (!this.saveDisabled) {
        this.saveDisabled = true
        if (this.asset.id) {
          this.attachmentUpdate({ asset: this.asset, url: railsRoutes.api_path({ type: 'attachments' }) }).then(response => {
            this.edit(response.data.records[0])
            this.saveDisabled = false
            this.resetErrors()
            this.setEditingAssetDescription(false)
            this.$notify({ title: 'Attachment Saved', type: 'success' })
          }).catch(error => {
            console.error(error)
            this.saveDisabled = false
            if (error && error.response) {
              this.setErrors({ errors: error.response.data })
            }
            this.$notify({ title: 'Error saving Attachment', type: 'error' })
          })
        } else {
          // This should never happen
          this.saveDisabled = false
          this.$notify({ title: 'Error saving Attachment', type: 'error' })
        }
      }
    },
    deleteRecord () {
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    },
    createSnapshot () {
      var scaleFactor = 0.25
      var video = this.$refs.video
      var canvas = this.$refs.canvas
      var w = video.videoWidth * scaleFactor
      var h = video.videoHeight * scaleFactor

      canvas.width = w
      canvas.height = h

      var ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, w, h)
      this.base64 = canvas.toDataURL('image/png')

      this.asset.custom_preview = this.base64
      this.save()
    }
  }
}
</script>
<style lang="scss">
.attachments-edit-section {
  .asset-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .asset-description-inner {
      width: 100%;
    }
  }

  #attachment_video_code {
    padding: 0px;
    font-size: .7em;
    height: 120px;
  }
  #attachment_audio_code {
    padding: 0px;
    font-size: .7em;
    height: 100px;
  }
  #attachment_image_code {
    padding: 0px;
    font-size: .7em;
    height: 5px;
  }
  #attachment_transcription {
    padding: 0px;
    font-size: .7em;
    height: 100px;
  }
  .edit-content-container {
    padding: 15px 30px;
  }
  .attachment-name {
    input {
      margin-bottom: 0px;
    }
  }
  .inner-content {
    position: relative;
  }
  .upload-file-button {
    margin: .75rem 0;
  }
  .capture {
    margin: 1rem 0;
  }
  .preview-notice {
    line-height: 1.1;
    color: $blue-2;
  }
  .content-source-attachment-section {
    width: 350px;
    margin-top: 40px;
  }
  .tags-container {
    margin: 20px 0;
    width: 350px;
  }
  .notes {
    width: 50%;
    textarea {
      height: 200px;
    }
  }
}
</style>
