<template>
  <div
    class="hierarchy-item"
    @click="selectItem()"
  >
    <div
      class="hierarchy-title"
      :title="item.question"
      v-html="'Item: ' + truncatedTitle()"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  props: [
    'row',
    'columnIndex'
  ],
  data () {
    return {
      showMenu: {}
    }
  },
  computed: {
    item () {
      return this.row.rowObject
    }
  },
  methods: {
    truncatedTitle () {
      if (this.item.question && (this.item.question.length > 55)) {
        return this.item.question.substring(0, 55) + '...'
      } else {
        return this.item.question
      }
    },
    selectItem () {
      this.$store.commit('appHierarchy/selectRow', {
        columnIndex: this.columnIndex,
        row: this.row
      })

      // EDIT ASSET
      this.edit(this.item)

      // SET ASSET TYPE
      this.setAssetType({ assetType: 'item' })
    },
    ...mapMutations([
      'setAssetType'
    ]),
    ...mapActions([
      'edit'
    ])
  }
}
</script>
