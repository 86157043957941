<template>
  <div
    v-if="asset && currentUser.permissions.can_read_tags"
    class="tags"
  >
    <tag-multiselect
      v-if="tags"
      ref="multiselectTags"
      v-model="asset.tags"
      class="tag-section"
      track-by="name"
      label="name"
      :options="tags"
      :multiple="true"
      :show-labels="false"
      :searchable="true"
      placeholder="Please select a tag"
      :disabled="!canWriteToAsset || !currentUser.permissions.can_read_tags"
    />
  </div>
</template>

<script>
import TagMultiselect from '../../shared/TagMultiSelect'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    TagMultiselect
  },
  props: ['asset'],
  data () {
    return {
      selectedTag: null
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'tags',
      'canWriteToAsset'
    ])
  },
  created () {
    if (this.currentUser.permissions.can_read_tags && !this.tags) {
      this.getTags()
    }
  },
  methods: {
    ...mapActions([
      'getTags'
    ])
  }
}

</script>

<style lang="scss">
.tags {
  height: auto;
  min-height: 50px;
  max-width: 100%;
}
</style>
