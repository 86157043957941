<template>
  <div class="app-hierarchy">
    <div class="inner-content">
      <div class="hierarchy-container">
        <h1>ALPHA - App Hierarchy</h1>
        <h4>Here, there be dragons!</h4>
        <div class="hierarchy-inner-container">
          <column
            v-for="(column, i) in appHierarchyColumns"
            :key="i"
            :column-index="i"
          />
        </div>
      </div>
      <div class="lower-hierarchy">
        <topic-edit v-if="assetType == 'topic'" />
        <attachment-edit
          v-if="assetType == 'attachment'"
          :attachment-id="asset.id"
        />
        <item-edit v-if="assetType == 'item'" />
        <nav-group-edit v-if="assetType == 'navigation_group'" />
        <nav-item-edit v-if="assetType == 'navigation_item'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import Column from './Column.vue'
import railsRoutes from '../../../store/rails-routes'

import TopicEdit from '../../assets/topics/Edit.vue'
import AttachmentEdit from '../../assets/attachments/Edit.vue'
import ItemEdit from '../../assets/items/Edit.vue'
import NavGroupEdit from './NavigationGroupEdit.vue'
import NavItemEdit from './NavigationItemEdit.vue'
import axios from 'axios'

export default {
  components: {
    Column,
    TopicEdit,
    AttachmentEdit,
    ItemEdit,
    NavGroupEdit,
    NavItemEdit
  },
  data () {
    return {
      hierarchyVisible: true
    }
  },
  computed: {
    ...mapGetters([
      'appHierarachy/appHierarchyColumns',
      'currentUser',
      'assetType',
      'asset'
    ]),
    ...mapState('appHierarchy', {
      appHierarchyColumns: state => state.appHierarchyColumns,
      adding: state => state.adding
    })
  },
  created () {
    this.setAssetType({ assetType: null })

    // GET LIST OF NAV ICONS
    axios.get(railsRoutes.api_path({ type: 'navigation_icons' }), {
    }).then(response => {
      this.$store.commit('appHierarchy/setNavigationIcons', {
        navigationIcons: response.data.records
      })
    })

    // GET LIST OF NAV DESTINATIONS
    axios.get(railsRoutes.api_path({ type: 'navigation_destinations' }), {
    }).then(response => {
      this.$store.commit('appHierarchy/setNavigationDestinations', {
        navigationDestinations: response.data.records
      })
    })

    this.appHierarchyColumns.push({
      parent: null,
      parentType: null,
      rows: this.currentUser.apps.map(x => ({ rowType: 'App', rowObject: x }))
    })
  },
  methods: {
    /*
      addController() {
        console.log("ADDING")

        var columnIndex = 1 // Get this from the last selected column
        var row = "something" // Get this from the last selected row

        this.$store.commit('appHierarchy/setAdding', {
          adding: {
            columnIndex: 1,
            row: "something"
          }
        })

        // Somehow we need to:
        // 1. find the current active selection (last column with a selection)
        // 2. have the active row handle what happens on Add

      },
*/
    ...mapMutations([
      'setAssetType'
    ])
  }
}
</script>

<style lang="scss">
.hierarchy-container {
  width: 95%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  min-height: 30px;

  .hierarchy-inner-container {
    border: 4px solid $grey-1; // was grey-6
    border-radius: 4px;
    height: 250px;
    display: flex;
    overflow-x: scroll;
  }

  .slow-fade-enter-active {
    animation: slow-fade 1s ease both;
  }

  @keyframes slow-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .slide-fade-enter-active {
    animation: scale-down-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  .slide-fade-leave-active {
    animation: scale-up-ver-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    height: 0px;
  }

  @keyframes scale-up-ver-top {
    0% {
              transform: scaleY(1);
              transform-origin: 100% 0%;
    }
    100% {
              transform: scaleY(0.3);
              transform-origin: 100% 0%;
    }
  }

  @keyframes scale-down-ver-top {
    0% {
              transform: scaleY(0.3);
              transform-origin: 100% 0%;
    }
    100% {
              transform: scaleY(1);
              transform-origin: 100% 0%;
    }
  }

  .fold {
    height: 30px;
    color: white;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    bottom: -15px;
    left: 25%;
    width: 50%;
  }

  .folddown {
    border-bottom: 4px solid $grey-1;  // was grey-6
    div {
      position: absolute;
      top: 15px;
      width: 100%;
    }
  }
}

.hierarchy-column {
  height: 100%;
  min-width: 480px;
  max-width: 480px;
  overflow-y: scroll;
  background-color: #E2E2E2;

  .draggable {
    height: 100%;
    background-color: white;
    margin-right: 10px;
  }

  &.active {
    background-color: #EAF7FA;
  }

  .hierarchy-asset {
    padding: 4px 6px;
    min-height: 44px;
    border-bottom: 2px solid $grey-1;  // was grey-8
    background-color: white;

    svg {
      margin: auto 8px auto 4px;
    }

    &.active {
      background-color: $blue-4 !important;
      color: white;

      svg g {
        stroke: white;
      }
    }

    &.selected {
      background-color: $blue-5;
      color: #2e414d;

      svg g {
        stroke: white;
      }
    }

  }
}

.hierarchy-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 6px;
}

.hierarchy-item {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  .svg {
    margin-right: 5px;
  }
}

.hierarchy-menu {
  width: 12px;
  height: 24px;
  padding: 2px;
  margin-top: 0px;
  border-radius: 2px;
  .dot {
    width: 4px;
    height: 4px;
    background-color: $grey-3;
    border-radius: 45px;
    margin: 2px;
  }
}
.hierarchy-menu:hover, .hierarchy-menu.active{
  background-color: $blue-3; // was blue-8

  .dot {
    background-color: white;
  }
}

.hierarchy-menu-modal {
  color: black;
  margin: 0px;
  display: inline-block;
  position: absolute;

  ul {
    border: 2px solid $grey-4;
    margin: 0px;
    list-style: none;
    background-color: #f9f9f9;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px $drop-shadow-2;
    z-index: 1;

    li {
      border-bottom: 1px solid $grey-4;
      color: black;
      text-decoration: none;
      display: block;
      padding: 10px;
      padding-left: 15px;
      background-color: $grey-1;  // was grey-6
    }
    li:hover {
      background-color: $blue-5;
      color: white;
    }
  }
}

.lower-hierarchy {
  .inner-content {
    position: relative !important;
  }
}
</style>
