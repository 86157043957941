<template>
  <div
    class="hierarchy-item"
    @click="selectAttachment()"
  >
    <div
      class="hierarchy-title"
      :title="attachment.name"
      v-html="'Attachment: ' + truncatedName()"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  props: [
    'row',
    'columnIndex'
  ],
  data () {
    return {
      showMenu: {}
    }
  },
  computed: {
    attachment () {
      return this.row.rowObject
    }
  },
  methods: {
    truncatedName () {
      if (this.attachment.name && (this.attachment.name.length > 55)) {
        return this.attachment.name.substring(0, 55) + '...'
      } else {
        return this.attachment.name
      }
    },
    selectAttachment () {
      this.$store.commit('appHierarchy/selectRow', {
        columnIndex: this.columnIndex,
        row: this.row
      })

      // EDIT ASSET
      this.edit(this.attachment)

      // SET ASSET TYPE
      this.setAssetType({ assetType: 'attachment' })
    },
    ...mapMutations([
      'setAssetType'
    ]),
    ...mapActions([
      'edit'
    ])
  }
}
</script>
