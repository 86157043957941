<template>
  <div class="prompt-runner-container">
    <div class="flex">
      <div class="label-heading">
        Prompt Runners
      </div>
    </div>
    <div class="flex">
      <div class="prompt-runner-column pr-main-column">
        <label>ID</label>
      </div>
      <div class="prompt-runner-column pr-main-column">
        <label>Title</label>
      </div>
      <div class="prompt-runner-column pr-main-column">
        <label>Provider</label>
      </div>
      <div class="prompt-runner-column pr-main-column">
        <label>Action</label>
      </div>
    </div>
    <div
      class="prompt-runner-row"
      v-for="promptRunner in promptRunners"
    >
      <div
        class="product-association-column category-id-column"
      >
        {{ promptRunner.id }}
      </div>
      <div
        class="product-association-column category-id-column"
      >
        {{ promptRunner.title }}
      </div>
      <div
        class="product-association-column category-id-column"
      >
        {{ promptRunner.ai_provider }}
      </div>
      <div
        class="product-association-column category-id-column"
      >
        <a
          class="hlt-button functional"
          @click="perform(promptRunner.id)"
        >Duplicate</a>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import railsRoutes from '../../../store/rails-routes'

export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  computed: {
    promptRunners() {
      return this.asset.prompt_runners;
    },
  },
  methods: {
    perform(id) {
      const params = {
        type: 'prompts',
        id: id,
        action: 'perform',
      };
      axios.post(railsRoutes.api_path(params), {
        item_id: this.asset.id
      });
    },
  },
}
</script>

<style lang="scss">
.prompt-runner-container {
  width: 100%;
  padding: 20px 0;
  .sync-product-associations {
    .sync-button {
      background-color: $blue-4;
      border: none;
      color: $white-2;
      font-size: 0.75em;
      padding: 10px 15px;
      &:hover {
        background-color: $blue-3;
        border: none;
        color: $white-2;
        box-shadow: 0;
      }
    }
  }

  .prompt-runner-row {
    width: 100%;
    padding-bottom: 15px;
    display: flex;
    margin: 0;
    justify-content: space-between;
    .pr-product-select, .pr-category-select {
      .multiselect {
        margin: 0;
      }

      .multiselect__content-wrapper {
        width: fit-content;
        min-width: 200px;
      }

      .multiselect__option {
        width: 100%;
      }

      .multiselect__single {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 240px;
      }
    }
    .visibility-column {
      display: flex;
      align-items: center;
    }
  }

  .prompt-runner-column {
    font-weight: normal;
    max-height: 50px;
    width: 18%;
    box-sizing: border-box;
    padding: 0;
    flex: 1;
    margin-right: 2%;
    .order {
      border-radius: 5px;
      line-height: 35px;
      height: 35px;
    }
    label {
      font-weight: bold;
      margin-bottom: .25rem;
    }
    input {
      height: 45px;
      margin-top: 2px;
    }
    &:last-of-type {
      text-align: right;
      margin: 0;
    }
  }
  .pr-main-column {
    flex: 2;
    &.categories-column {
      flex: 3;
    }
  }
  .wide-column {
    flex: 6;
  }
}
</style>
