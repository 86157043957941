<template>
  <div>
    <top-nav />
    <decks-index v-if="this.$route.name == 'decks'" />
    <decks-edit v-if="this.$route.name == 'deck'" />
    <app-hierarchy v-if="this.$route.name === 'app-hierarchy'" />
    <catalogs-index v-if="this.$route.name == 'catalogs'" />
    <catalogs-edit v-if="this.$route.name == 'catalog'" />
    <catalog-pages-index v-if="this.$route.name == 'catalog_pages'" />
    <catalog-pages-edit v-if="this.$route.name == 'catalog_page'" />
  </div>
</template>

<script>
import TopNav from './TopNav.vue'
import CatalogsIndex from '../catalogs/Index'
import CatalogsEdit from '../catalogs/Edit'
import CatalogPagesIndex from '../catalog_pages/Index'
import CatalogPagesEdit from '../catalog_pages/Edit'
import DecksIndex from '../decks/Index'
import DecksEdit from '../decks/Edit'
import AppHierarchy from '../../app_manager/app_hierarchy/Main'
export default {
  components: {
    TopNav,
    DecksIndex,
    DecksEdit,
    AppHierarchy,
    CatalogsIndex,
    CatalogsEdit,
    CatalogPagesIndex,
    CatalogPagesEdit
  }
}
</script>
