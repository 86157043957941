<template>
    <modal
    name="save-modal"
    height="auto"
    :scrollable="false"
    class="vue-modal save-modal"
    width="45%"
  >
    <div class="vue-modal-inner">
      <div class="vue-modal-topbar">
        <h4>Are you sure you want to update live version?</h4>
      </div>
      <revised-date-modal-contents @checkedUpdateRevised="checkedUpdateRevised" @revisedChanged="revisedChanged" />

      <div class="vue-modal-bottombar modal-buttons">
        <button
          class="button cancel-button modal-button"
          @click="cancel()"
        >
          Cancel
        </button>
        <button
          class="button solid-green-button modal-button"
          @click="save()"
        >
          Yes, I'm sure
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import RevisedDateModalContents from './RevisedDateModalContents.vue'

export default {
  props: {
    asset: {
      type: Object,
      required: true
    }
  },
  components: {
    RevisedDateModalContents
  },
  data () {
    return {
      updateRevisedDate: false
    }
  },
  methods: {
    revisedChanged(date)  {
      if(this.updateRevisedDate) {
        this.asset.revised_at = date
      }
    },
    checkedUpdateRevised(val, date) {
      this.updateRevisedDate = val
      this.revisedChanged(date)
    },
    save () {
      this.$emit('save')
      this.cancel()
    },
    cancel () {
      this.updateRevisedDate = false
      this.$modal.hide('save-modal')
    },

  }
}
</script>

<style lang="scss" scoped>
.date-row {
  margin: 20px;
  input {
    margin-right: 10px;
  }
  .selected-date {
    display: inline;
    margin-left: 10px;
    cursor: pointer;
    color: $eastern-blue;
  }
}
.agreement-text {
  padding: 20px;
  color: $space-gray;
}
.cancel-button {
  background: $space-gray;
  border: 1px solid $slate-gray;
}
</style>