<template>
  <div class="catalog-pages-edit-container" v-if="asset && asset.apps && asset.apps != []">
    <div class="catalog-page-edit-content">
      <div class="main-container">
        <h4 class="hlt-header h4 weight-700 catalog-page-text-header">
          <h4 class="hlt-header h4 weight-700">Catalog Page ID {{ asset.id }}</h4>
          <span class="question-type">{{ asset.apps[0].internal_name }}</span>
        </h4>
        <div class="main-section">
          <div class="left-edit">
            <div class="main-catalog-page-container">
              <div class="catalog-page-name-section row">
                <div class="column">
                  <label class="catalog-page-label weight-600">Title</label>
                  <input
                    v-model="asset.title"
                    class="catalog-page-input hlt-input catalog-page-name-input"
                    type="text"
                    required
                    :class="{'error': errors['title'] }"
                    placeholder="Catalog page title"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors['title']"
                    message="Title can't be blank"
                  />
                  <span
                    v-if="!errors['title'] && draftMode"
                    class="helper-text required-title-text"
                  >Required</span>
                </div>
                <div class="column">
                  <label class="catalog-page-label weight-600">Icon Attachment ID</label>
                  <input
                    v-model="asset.icon_id"
                    class="catalog-page-input hlt-input"
                    type="text"
                    :class="{'error': errors['icon_id'] }"
                    placeholder="Attachment ID"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors['icon_id']"
                    message="Invalid ID"
                  />
                </div>

                <div class="column">
                  <label class="catalog-page-label weight-600">Hero Attachment ID</label>
                  <input
                    v-model="asset.hero_image_id"
                    class="catalog-page-input hlt-input"
                    type="text"
                    :class="{'error': errors['hero_image_id'] }"
                    placeholder="Hero Attachment ID"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors['hero_image_id']"
                    message="Invalid ID"
                  />
                </div>
              </div>

              <div class="catalog-page-name-section row">
                <div class="column">
                  <label class="catalog-page-label weight-600">Rating</label>
                  <input
                    v-model="asset.rating"
                    class="catalog-page-input hlt-input"
                    type="text"
                    :class="{'error': errors['rating'] }"
                    placeholder="Rating"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors['rating']"
                    message="Invalid ID"
                  />
                </div>
                <div class="column">
                  <label class="catalog-page-label weight-600">Purchase Order</label>
                  <input
                    v-model="asset.purchase_order_id"
                    class="catalog-page-input hlt-input catalog-page-name-input"
                    type="text"
                    required
                    :class="{'error': errors['purchase_order_id'] }"
                    placeholder="Purchase order id"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors['purchase_order_id']"
                    message="Purchase order id can't be blank"
                  />
                </div>

                <div class="column">
                  <label class="catalog-page-label weight-600">Questionnaire ID</label>
                  <input
                    v-model="asset.questionnaire_id"
                    class="catalog-page-input hlt-input catalog-page-name-input"
                    type="text"
                    required
                    :class="{'error': errors['questionnaire_id'] }"
                    placeholder="Questionnaire ID"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors['questionnaire_id']"
                    message="ID be blank"
                  />
                </div>
              </div>

              <div class="catalog-page-name-section row">
                <div class="column">
                  <label class="catalog-page-label weight-600">Enable Button Prefix</label>
                  <input
                    v-model="asset.enable_button_prefix"
                    class="catalog-page-input hlt-input catalog-page-name-input"
                    type="text"
                    required
                    :class="{'error': errors['enable_button_prefix'] }"
                    placeholder="Try Free"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors['enable_button_prefix']"
                    message="Enable button prefix invalid"
                  />
                </div>
                <div class="column">
                  <label class="catalog-page-label weight-600">Enable Button Text</label>
                  <input
                    v-model="asset.enable_button_text"
                    class="catalog-page-input hlt-input"
                    type="text"
                    :class="{'error': errors['enable_button_text'] }"
                    placeholder="Add"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors['enable_button_text']"
                    message="Enable button text invalid"
                  />
                </div>

                <div class="column">
                  <label class="catalog-page-label weight-600">Disable Button Text</label>
                  <input
                    v-model="asset.disable_button_text"
                    class="catalog-page-input hlt-input"
                    type="text"
                    :class="{'error': errors['disable_button_text'] }"
                    placeholder="Remove"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors['disable_button_text']"
                    message="Disable button text invalid"
                  />
                </div>
              </div>

              <div class="catalog-page-name-section row">
                <div class="column">
                  <label class="catalog-page-label weight-600">Description</label>

                  <div
                    v-if="!editing.description"
                    class="review-edit-html-description"
                    @click="e => moduleToggle(e, 'description', 'description')"
                  >
                    <div
                      :class="[{ 'empty-content': emptyContent(asset.description) , 'disabled': !draftMode }, 'container-textarea']"
                      name="c-description"
                      v-html="asset.description || 'Write description'"
                    />
                  </div>

                  <div
                    v-if="editing.description"
                    class="review-edit-active description-active"
                  >
                    <tinymce-editor
                      ref="review-description"
                      v-model="asset.description"
                      v-on-clickaway="e => moduleToggle(e, 'description', 'description')"
                      :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                      class="tinymce"
                      name="description"
                      @onInit="setCaretPosition"
                    />
                  </div>

                  <error-message
                    v-if="errors['description']"
                    message="Description can't be blank"
                  />
                </div>
              </div>

              <div class="catalog-page-name-section row">
                <div class="column">
                  <label class="catalog-page-label weight-600">Short Description</label>

                  <div
                    v-if="!editing.shortDescription"
                    class="review-edit-html-description"
                    @click="e => moduleToggle(e, 'shortDescription', 'shortDescription')"
                  >
                    <div
                      :class="[{ 'empty-content': emptyContent(asset.short_description) , 'disabled': !draftMode }, 'container-textarea']"
                      name="short-description"
                      v-html="asset.short_description || 'Write short description'"
                    />
                  </div>

                  <div
                    v-if="editing.shortDescription"
                    class="review-edit-active short-description-active"
                  >
                    <tinymce-editor
                      ref="short-description"
                      v-model="asset.short_description"
                      v-on-clickaway="e => moduleToggle(e, 'shortDescription', 'shortDescription')"
                      :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                      class="tinymce"
                      name="short-description"
                      @onInit="setCaretPosition"
                    />
                  </div>

                  <error-message
                    v-if="errors['short_description']"
                    message="Short description can't be blank"
                  />
                </div>
              </div>
            </div>

            <div class="main-section-container remove-modal-section">
              <h5 class="hlt-header h5 weight-600 catalog-page-text-subheader">
                Marquees
              </h5>
              <p
                v-if="!sortedMarquees"
                class="small-text weight-600"
              >
                Get started by adding a marquee
              </p>
              <div
                v-if="sortedMarquees"
                class="active-deck-sections"
              >
                <div
                  v-for="(marquee, index) in sortedMarquees"
                  :key="'marquee-' + index"
                >
                  <order-box
                    :order-object="marquee"
                    order-object-type="Marquee"
                    :order-object-id="marquee.order"
                    :anchor-key="'marquee-' + index"
                    :index="index"
                    :draft-mode="draftMode"
                    :is-draggable="false"
                    :group-size="sortedActiveMarquees.length"
                    :box-styles="boxStyles"
                    @move="moveMarqueeBox"
                  >
                    <template v-slot:headerTitle>
                      {{ marquee.title | truncate(40) }}
                    </template>

                    <template v-slot:content>
                      <marquee
                        :draft-mode="draftMode"
                        :marquee="marquee"
                        :index="index"
                      />
                    </template>
                  </order-box>
                </div>
              </div>

              <button
                v-if="draftMode"
                class="hlt-button secondary"
                @click="addNewMarquee"
              >
                Add Marquee
              </button>
            </div>

            <div class="main-section-container remove-modal-section">
              <h5 class="hlt-header h5 weight-600 catalog-page-text-subheader">
                Reviews
              </h5>

              <p
                v-if="!sortedReviews"
                class="small-text weight-600"
              >
                Get started by adding a review
              </p>
              <div
                v-if="sortedReviews"
                class="active-deck-sections"
              >
                <div
                  v-for="(review, index) in sortedReviews"
                  :key="'review-' + index"
                >
                  <order-box
                    :order-object="review"
                    order-object-type="Review"
                    :order-object-id="review.order"
                    :anchor-key="'review-' + index"
                    :index="index"
                    :draft-mode="draftMode"
                    :is-draggable="false"
                    :group-size="sortedActiveReviews.length"
                    :box-styles="boxStyles"
                    @move="moveReviewBox"
                  >
                    <template v-slot:headerTitle>
                      {{ review.title | truncate(40) }}
                    </template>

                    <template v-slot:content>
                      <review
                        :draft-mode="draftMode"
                        :review="review"
                        :index="index"
                      />
                    </template>
                  </order-box>
                </div>
              </div>

              <button
                v-if="draftMode"
                class="hlt-button secondary"
                @click="addNewReview"
              >
                Add Review
              </button>
            </div>

            <div class="main-section-container add-modal-section">
              <h5 class="hlt-header h5 weight-600 catalog-page-text-subheader">
                Add Modal
              </h5>
              <div
                class="modal-section"
              >
                <div class="row">
                  <div class="column">
                    <label class="catalog-page-label weight-600">Title</label>
                    <input
                      v-model="asset.add_title"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['add_title'] }"
                      placeholder="Add Title"
                      :disabled="!draftMode"
                    >
                    <error-message
                      v-if="errors['add_title']"
                      message="Add title missing"
                    />
                  </div>
                  <div class="column">
                    <label class="catalog-page-label weight-600">Icon Attachment ID</label>
                    <input
                      v-model="asset.add_icon_id"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['add_icon_id'] }"
                      placeholder="Attachment ID"
                      :disabled="!draftMode"
                    >
                    <error-message
                      v-if="errors['add_icon_id']"
                      message="Invalid ID"
                    />
                  </div>
                </div>
                <div>
                  <label class="catalog-page-label weight-600">Description</label>
                  <div
                    class="editing"
                  >
                    <div
                      v-if="!editing.addDescription"
                      class="review-edit-html-description"
                      @click="e => moduleToggle(e, 'addDescription', 'addDescription')"
                    >
                      <div
                        :class="[{ 'empty-content': emptyContent(asset.add_description) , 'disabled': !draftMode }, 'container-textarea']"
                        name="add-description"
                        v-html="asset.add_description || 'Write add description'"
                      />
                    </div>

                    <div
                      v-if="editing.addDescription"
                      class="review-edit-active add-description-active"
                    >
                      <tinymce-editor
                        ref="add-description"
                        v-model="asset.add_description"
                        v-on-clickaway="e => moduleToggle(e, 'addDescription', 'addDescription')"
                        :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                        class="tinymce"
                        name="add-description"
                        @onInit="setCaretPosition"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="column">
                    <label class="catalog-page-label weight-600">Affirmative Button Text</label>
                    <input
                      v-model="asset.add_affirmative_button_text"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['add_affirmative_button_text'] }"
                      placeholder="Add Affirmative Button Text"
                      :disabled="!draftMode"
                    >
                  </div>

                  <div class="column">
                    <label class="catalog-page-label weight-600">Negative Button Text</label>
                    <input
                      v-model="asset.add_negative_button_text"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['add_negative_button_text'] }"
                      placeholder="Add Negative Button Text"
                      :disabled="!draftMode"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="main-section-container remove-modal-section">
              <h5 class="hlt-header h5 weight-600 catalog-page-text-subheader">
                Remove Modal
              </h5>
              <div
                class="modal-section"
              >
                <div class="row">
                  <div class="column">
                    <label class="catalog-page-label weight-600">Title</label>
                    <input
                      v-model="asset.remove_title"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['remove_title'] }"
                      placeholder="Add Title"
                      :disabled="!draftMode"
                    >
                  </div>
                  <div class="column">
                    <label class="catalog-page-label weight-600">Icon Attachment ID</label>
                    <input
                      v-model="asset.remove_icon_id"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['remove_icon_id'] }"
                      placeholder="Attachment ID"
                      :disabled="!draftMode"
                    >
                  </div>
                </div>
                <div>
                  <label class="catalog-page-label weight-600">Description</label>
                  <div
                    v-if="!editing.removeDescription"
                    class="review-edit-html-description"
                    @click="e => moduleToggle(e, 'removeDescription', 'removeDescription')"
                  >
                    <div
                      :class="[{ 'empty-content': emptyContent(asset.remove_description) , 'disabled': !draftMode }, 'container-textarea']"
                      name="remove-description"
                      v-html="asset.remove_description || 'Write removal description'"
                    />
                  </div>

                  <div
                    v-if="editing.removeDescription"
                    class="review-edit-active remove-description-active"
                  >
                    <tinymce-editor
                      ref="remove-description"
                      v-model="asset.remove_description"
                      v-on-clickaway="e => moduleToggle(e, 'removeDescription', 'removeDescription')"
                      :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                      class="tinymce"
                      name="remove-description"
                      @onInit="setCaretPosition"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="column">
                    <label class="catalog-page-label weight-600">Affirmative Button Text</label>
                    <input
                      v-model="asset.remove_affirmative_button_text"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['remove_affirmative_button_text'] }"
                      placeholder="Add Affirmative Button Text"
                      :disabled="!draftMode"
                    >
                  </div>

                  <div class="column">
                    <label class="catalog-page-label weight-600">Negative Button Text</label>
                    <input
                      v-model="asset.remove_negative_button_text"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['remove_negative_button_text'] }"
                      placeholder="Add Negative Button Text"
                      :disabled="!draftMode"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="main-section-container add-modal-section">
              <h5 class="hlt-header h5 weight-600 catalog-page-text-subheader">
                Rate Limit Modal
              </h5>
              <div
                class="modal-section"
              >
                <div class="row">
                  <div class="column">
                    <label class="catalog-page-label weight-600">Title</label>
                    <input
                      v-model="asset.rate_limit_title"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['rate_limit_title'] }"
                      placeholder="Rate Limit Title"
                      :disabled="!draftMode"
                    >
                    <error-message
                      v-if="errors['rate_limit_title']"
                      message="Rate limit title missing"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="column">
                    <label class="catalog-page-label weight-600">Subtitle</label>
                    <input
                      v-model="asset.rate_limit_subtitle"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['rate_limit_subtitle'] }"
                      placeholder="Rate Limit Subtitle"
                      :disabled="!draftMode"
                    >
                    <error-message
                      v-if="errors['rate_limit_subtitle']"
                      message="Rate limit subtitle missing"
                    />
                  </div>
                </div>


                <div class="row">
                  <div class="column">
                    <label class="catalog-page-label weight-600">Affirmative Button Text</label>
                    <input
                      v-model="asset.rate_limit_affirmative_button_text"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['rate_limit_affirmative_button_text'] }"
                      placeholder="Rate Limit Affirmative Button Text"
                      :disabled="!draftMode"
                    >
                  </div>

                  <div class="column">
                    <label class="catalog-page-label weight-600">Negative Button Text</label>
                    <input
                      v-model="asset.rate_limit_negative_button_text"
                      class="catalog-page-input hlt-input"
                      type="text"
                      :class="{'error': errors['rate_limit_negative_button_text'] }"
                      placeholder="Rate Limit Negative Button Text"
                      :disabled="!draftMode"
                    >
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div class="right-edit">
            <div>
              <label class="hlt-header h6 weight-600 outline-heafuncitonalder">Purchase Enabled</label>
              <hlt-toggle
                class="choice-key-button"
                :value="asset.purchase_enabled"
                :labels="{
                  checked: 'On',
                  unchecked: 'Off'
                }"
                style-type="small"
                sync
                @change="draftMode && togglePurchaseEnabled()"
              />
            </div>
            <div>
              <label class="hlt-header h6 weight-600 outline-heafuncitonalder">Visibility</label>
              <hlt-toggle
                class="choice-key-button"
                :value="asset.enabled"
                :labels="{
                  checked: 'On',
                  unchecked: 'Off'
                }"
                style-type="small"
                sync
                @change="draftMode && toggleVisibility()"
              />
            </div>
          </div>
        </div>
      </div>
      <metadata />

      <delete-order-box-modal @orderBoxDeletion="deleteBox" />

      <modal
        name="overwrite-live-catalog-page-modal"
        height="auto"
        :scrollable="false"
        class="vue-modal"
        width="45%"
      >
        <div
          v-if="asset.id != undefined"
          class="vue-modal-inner"
        >
          <div class="vue-modal-topbar">
            <h4 class="overwrite-warning">
              Are you sure you want to save your draft and overwrite the live layer?
            </h4>
          </div>
          <div class="modal-container">
            <div>
              By clicking here, you accept full responsibility for your actions and attest that you are of currently sober mind and body capable of making such an important decision.
              This is a change you can't undo. Once you overwrite the live layer, the updated version will be sent out to users.
            </div>
          </div>

          <div class="vue-modal-bottombar modal-buttons">
            <button
              class="button solid-green-button modal-button"
              @click="overwrite()"
            >
              Yes Overwrite
            </button>
            <button
              class="button modal-button"
              @click="cancel()"
            >
              Cancel
            </button>
          </div>
        </div>
      </modal>

      <edit-footer>
        <template v-slot:left>
          <div class="left">
            <button
              class="hlt-button default draft"
              :class="{ active : draftMode }"
              @click="toggleDraft(true)"
            >
              Draft
            </button>
            <button
              class="hlt-button default live"
              :class="{ active : !draftMode }"
              @click="toggleDraft(false)"
            >
              Live
            </button>
          </div>
        </template>
        <template v-slot:center>
          <div
            v-if="draftMode"
            class="center"
          >
            <button
              v-if="!saveDisabled"
              class="hlt-button primary save-button"
              :class="{ grey: (!draftMode || !canWriteToAsset) }"
              @click="canWriteToAsset && draftMode && save()"
            >
              Save Changes
            </button>
            <button
              v-if="saveDisabled"
              class="hlt-button primary disabled save-button"
            >
              <spinner /> Save Changes
            </button>
          </div>
          <div
            v-else
            class="center"
          />
        </template>
        <template v-slot:right>
          <div
            v-if="draftMode"
            class="right"
          >
            <button
              class="hlt-button secondary overwrite"
              :class="{ grey: !draftMode || !canOverwriteLive }"
              @click="canWriteToAsset && canOverwriteLive && draftMode && overwriteLive()"
            >
              Overwrite Live
            </button>
          </div>
        </template>
      </edit-footer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Spinner from '../../assets/shared/Spinner'
import railsRoutes from '../../../store/rails-routes'
import axios from 'axios'
import Marquee from './Marquee'
import Review from './Review'
import OrderBox from '../../shared/OrderBox'
import DeleteOrderBoxModal from '../../shared/DeleteOrderBoxModal'
import EditFooter from '../../assets/shared/page_components/EditFooter'
import TinymceEditor from '@tinymce/tinymce-vue'
import tinymce from '../../../mixins/tinymce.js'
import HltToggle from '../../assets/shared/page_components/Toggle'
import Metadata from '../../assets/shared/Metadata'
import { mixin as clickaway } from 'vue-clickaway2'

export default {
  name: 'Edit',
  components: {
    Spinner,
    EditFooter,
    TinymceEditor,
    OrderBox,
    DeleteOrderBoxModal,
    Marquee,
    Review,
    HltToggle,
    Metadata
  },
  mixins: [
    tinymce,
    clickaway
  ],
  data () {
    return {
      draftMode: true,
      editing: {
        description: false,
        shortDescription: false,
        removeDescription: false,
        addDescription: false
      },
      saveDisabled: false,
      showAddSection: false,
      boxStyles: {
        header: ['shaded-background'],
        content: ['shaded-background']
      }
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset',
      'currentUser',
      'canWriteToAsset',
      'errors'
    ]),
    sortedReviews: {
      get () {
        const that = this
        if (this.asset.catalog_page_reviews) {
          return that._.orderBy(this.asset.catalog_page_reviews, ['order'], ['asc'])
        } else {
          return []
        }
      },
      set (value) {
        this.asset.catalog_page_reviews = this.orderArray(value, 'order')
      }
    },
    sortedMarquees: {
      get () {
        const that = this
        if (this.asset.catalog_page_marquees) {
          return that._.orderBy(this.asset.catalog_page_marquees, ['order'], ['asc'])
        } else {
          return []
        }
      },
      set (value) {
        this.asset.catalog_page_marquees = this.orderArray(value, 'order')
      }
    },
    sortedActiveMarquees () {
      return this.sortedMarquees.filter(x => !x.deleted)
    },
    sortedActiveReviews () {
      return this.sortedReviews.filter(x => !x.deleted)
    },
    canOverwriteLive () {
      return this.currentUser.permissions.can_overwrite_live_catalog_pages
    }
  },
  created () {
    this.setAssetType({ assetType: 'catalog_page' })
    this.apiCatalogPageById({ catalogPageId: this.$route.params.id }).then(response => {
      var record = response.data.records[0]
      this.edit(record)
      this.asset.catalog_page_reviews = this._.sortBy(this.asset.catalog_page_reviews, ['order']).filter(s => !s.deleted)
      this.asset.catalog_page_marquees = this._.sortBy(this.asset.catalog_page_marquees, ['order']).filter(s => !s.deleted)
      this.draftMode = !this.asset.live_layer
    })
  },
  methods: {
    ...mapActions([
      'edit',
      'delete',
      'update',
      'apiCatalogPageById'
    ]),
    ...mapMutations([
      'setAsset',
      'setAssetType',
      'setErrors',
      'resetErrors',
      'setOrderBoxReordering'
    ]),
    emptyContent (content) {
      return content === null || content === ''
    },
    moveReviewBox ({ index, card, direction }) {
      const incrementedSection = item => {
        item.order += 1
      }

      const decrementedSection = item => {
        item.order -= 1
      }

      const item = this.sortedReviews[index]
      if (direction === 'up') {
        const incrementedItem = this.sortedActiveReviews[(item.order - 1) - 1]
        incrementedSection(incrementedItem)
        decrementedSection(item)
      } else {
        const decrementedItem = this.sortedActiveReviews[(item.order - 1) + 1]
        incrementedSection(item)
        decrementedSection(decrementedItem)
      }

      const obj = {
        ...this.asset,
        cards: this.sortedReviews
      }
      this.setAsset({ asset: obj })
      this.setOrderBoxReordering(false)
    },

    moveMarqueeBox ({ index, card, direction }) {
      const incrementedSection = item => {
        item.order += 1
      }

      const decrementedSection = item => {
        item.order -= 1
      }

      const item = this.sortedMarquees[index]
      if (direction === 'up') {
        const incrementedItem = this.sortedActiveMarquees[(item.order - 1) - 1]
        incrementedSection(incrementedItem)
        decrementedSection(item)
      } else {
        const decrementedItem = this.sortedActiveMarquees[(item.order - 1) + 1]
        incrementedSection(item)
        decrementedSection(decrementedItem)
      }

      const obj = {
        ...this.asset,
        cards: this.sortedMarquees
      }
      this.setAsset({ asset: obj })
      this.setOrderBoxReordering(false)
    },
    disableSaving (value) {
      this.saveDisabled = value
    },
    save () {
      if (!this.saveDisabled) {
        this.saveDisabled = true
        if (this.asset.id) {
          this.update({ asset: this.asset, url: railsRoutes.api_path({ type: 'catalog_pages' }) }).then(response => {
            this.edit(response.data.records[0])
            this.saveDisabled = false
            this.resetErrors()
            this.$notify({ title: 'Catalog Page Saved', type: 'success' })
          }).catch(error => {
            this.saveDisabled = false
            if (error && error.response) {
              this.setErrors({ errors: error.response.data.errors })
            }
            this.$notify({ title: 'Error saving catalog page', type: 'error' })
          })
        } else {
          // This should never happen
          this.saveDisabled = false
          this.$notify({ title: 'Error saving catalog page', type: 'error' })
        }
      }
    },
    addNewReview () {
      this.asset.catalog_page_reviews.push({
        title: 'New Review',
        review_text: '',
        by_line: '',
        review_date: this.$date().format('YYYY-M-D'),
        rating: null,
        deleted: false,
        order: this.sortedActiveReviews.length + 1
      })
    },
    addNewMarquee () {
      this.asset.catalog_page_marquees.push({
        title: 'New Marquee',
        description: '',
        icon_id: null,
        image_id: null,
        deleted: false,
        order: this.sortedActiveMarquees.length + 1
      })
    },
    deleteBox (params) {
      let sortedObjects = []
      let sortedAttribute = null
      if (params.type == 'Marquee') {
        sortedObjects = this.sortedMarquees
        sortedAttribute = 'catalog_page_marquees'
      } else if (params.type == 'Review') {
        sortedObjects = this.sortedReviews
        sortedAttribute = 'catalog_page_reviews'
      }
      const localBoxes = sortedObjects
      localBoxes[params.index].deleted = true
      localBoxes[params.index].order = 9999999
      localBoxes[params.index].errors = {}

      // Adjust orders of everything after this section
      for (let x = params.index + 1; x < localBoxes.length; x++) {
        localBoxes[x].order -= 1
      }

      this.$set(this.asset, sortedAttribute, localBoxes)
    },
    displayAssetTypeName (assetBType) {
      if (assetBType === 'V3Flashcard') {
        return 'Item'
      } else {
        return assetBType
      }
    },
    togglePurchaseEnabled () {
      if (this.canWriteToAsset) {
        this.asset.purchase_enabled = !this.asset.purchase_enabled
      }
    },
    toggleVisibility () {
      if (this.canWriteToAsset) {
        this.asset.enabled = !this.asset.enabled
      }
    },
    moduleToggle (event, prop, reference) {
      if (this.draftMode) {
        if (!event) return
        if (event.target.className.includes('mce') || event.target.id.includes('mce')) {
          return
        }

        Object.keys(this.editing).forEach(key => {
          this.editing[key] = prop == key ? !this.editing[prop] : false
        })

        this.setCharPosition(event, reference)
      }
    },
    toggleDraft (val) {
      if ((this.asset.live_catalog_page_id && val === false) || (this.asset.draft_catalog_page_id && val === true)) {
        this.draftMode = !this.draftMode
        var recordId = null
        if (this.draftMode) {
          recordId = this.asset.draft_catalog_page_id
        } else {
          recordId = this.asset.live_catalog_page_id
        }
        if (this.$router) {
          this.$router.push({ path: `/catalog_pages/${recordId}/edit` })
          this.setAsset({ asset: {} })
        }
      }
    },
    overwrite () {
      if (this.canWriteToAsset && this.draftMode && this.canOverwriteLive) {
        axios.put(railsRoutes.api_path({ type: 'catalog_pages', id: this.asset.id, action: 'overwrite_live' }), {
          catalog_page: this.asset
        }).then(response => {
          if (response.data.records.length > 0) {
            const record = response.data.records[0]
            this.edit(record)
          }
          this.$notify({ title: 'Live Layer Overwriten', type: 'success' })
          if (this.$modal) {
            this.$modal.hide('overwrite-live-catalog-page-modal')
          }
        }).catch(error => {
          console.error('Error: ', error)
          this.$notify({ title: 'Error Overwriting Live Layer', type: 'error' })
          if (this.$modal) {
            this.$modal.hide('overwrite-live-catalog-page-modal')
          }
        })
      }
    },
    overwriteLive () {
      if (this.$modal) {
        this.$modal.show('overwrite-live-catalog-page-modal')
      }
    },
    cancel () {
      if (this.$modal) {
        this.$modal.hide('overwrite-live-catalog-page-modal')
      }
    }
  }
}
</script>

<style lang="scss">
.catalog-pages-edit-container {
  position: relative;
  height: 100%;

  label {
    margin: 0;
  }
  .small-text {
    font-size: 14px;
    color: $space-black-50;
  }
  .catalog-page-input {
    margin: 0 0 5px 0;
  }
  .catalog-page-name-input {
    margin: 0;
  }
  .helper-text {
    color: $space-black-50;
    font-size: .75rem;
  }
  .main-catalog-page-container {
    padding-bottom: 20px;
  }
  .outline-link {
    color: $space-black;
    transition: .3s;
    &:hover {
      color: $space-gray;
      cursor: pointer;
    }
  }
  .container-textarea {
    font-size: 14px;
    padding: 10px;
    height: auto;
    min-height: 150px;
    border: 1px solid $space-gray;
    background: $white-2;
    border-radius: 4px;
    &:hover {
      border: 1px solid $sky-blue;
    }
  }
  .empty-content {
    color: $space-gray;
  }
  .catalog-page-edit-content{
    display: flex;
    padding: 0px;
    justify-content: space-between;
    flex-direction: column;
  }
  .main-container {
    width: 100%;
    padding: 1.75rem 12rem;
    margin-bottom: 2rem;
    .main-section {
      display: flex;
      justify-content: space-between;
      .left-edit {
        flex: 4;
        padding-right: 44px;
      }
      .right-edit {
        flex: 1;
        div {
          margin-bottom: 10px;

          label {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .main-section-container {
    margin-bottom: 50px;
    padding: 20px;
  }
  .catalog-page-name-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .left-section {
      flex: 1;
      padding-right: 24px;
    }
  }
  .catalog-page-container {
    margin-bottom: 24px;
  }

  .asset-b-input {
    margin-bottom: 4px;
  }
  .section-area {
    padding-bottom: 24px;
  }
  .catalog-page-edit-active .mce-tinymce {
    border: 1px solid $sky-blue;
    border-radius: 4px;
  }
  .outline-title {
    font-size: 12.8px;
  }
  .outline-side-list {
    margin: 0;
    margin-bottom: 16px;
  }
  .outline-listing {
    list-style: none;
  }
  .outline-asset-title {
    font-size: 12.8px;
  }
  .outline-header {
    display: block;
    margin: 0;
    margin-bottom: 8px;
  }
  .disabled {
    background: $grey-4;
  }

  .association-section {
    padding: 1.75rem 12rem;
    border-top: 4px solid $grey-4;
    margin-bottom: 4rem;
  }
  .catalog-pages-footer {
    width: 100%;
    padding: 1.75rem 8rem;
    background: $blue-5;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    .catalog-page-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 5rem;
    }
    .catalog-page-select {
      width: 50%;
    }
  }
  .draft-live-footer {
    .hlt-select {
      margin: 0 30px;
      width: 300px;
    }
  }
  .modal-section {
    border: 1px solid $space-gray;
    background: $cloud-white;
    padding: 20px 40px 40px 40px;
  }
  .catalog-page-text-header {
    margin-bottom: 13px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    .question-type {
      margin-left: 12px;
      font-size: 14px;
      color: $space-gray;
    }
  }

  .catalog-page-text-subheader {
    margin: 0;
    padding-bottom: 8px;
  }
}
</style>
