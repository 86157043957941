<template>
  <div class="catalogs-edit-container" v-if="asset && asset.apps && asset.apps != []">
    <div class="catalog-edit-content">
      <div class="main-container">
        <h4 class="hlt-header h4 weight-700 catalog-text-header">
          <h4 class="hlt-header h4 weight-700">Catalog ID {{ asset.id }}</h4>
          <span class="question-type">{{ asset.apps[0].internal_name }}</span>
        </h4>
        <div class="main-section">
          <div class="left-edit">
            <div class="main-catalog-container">
              <h4>Recommendations</h4>

              <div v-if="userClassifications.length == 0">
                There are no user classifications setup for this app. Recommendations are linked to user classifications. Setup user classifications to use recommendations.
              </div>
              <div v-for="(userClassification, index) in userClassifications">
                <order-box
                  :order-object="userClassification"
                  order-object-type="UserClassification"
                  :order-object-id="userClassification.order"
                  :anchor-key="'userClassification-' + index"
                  :index="index"
                  :draft-mode="draftMode"
                  :is-draggable="false"
                  :is-deletable="false"
                  :is-movable="false"
                  :group-size="userClassifications.length"
                  :box-styles="boxStyles"
                >
                  <template v-slot:headerTitle>
                    {{ userClassification.name }} - {{ userClassification.display_name }}
                  </template>

                  <template v-slot:content>
                    <div v-for="(recommendation, i) in sortedRecommendations(userClassification)"
                      :key="'recommendation-' + i"
                    >
                      <!-- send userClassification instead so we can filter the data properly -->
                      <order-box
                        :order-object="recommendation"
                        order-object-type="Recommendation"
                        :order-object-id="recommendation.order"
                        :anchor-key="'recommendation-' + i"
                        :index="i"
                        :draft-mode="draftMode"
                        :is-draggable="false"
                        :group-size="sortedActiveRecommendations(userClassification).length"
                        :groupName="userClassification.name"
                        :box-styles="recommendationStyles"
                        @move="moveRecommendedBox(...arguments, userClassification)"
                      >
                        <template v-slot:headerTitle>
                        </template>

                        <template v-slot:content>
                          <recommendation :recommendation="recommendation" :draftMode="draftMode" />
                        </template>
                      </order-box>
                    </div>

                    <button
                      v-if="draftMode"
                      class="hlt-button secondary"
                      @click="addNewRecommendation(userClassification)"
                    >
                      Add Recommendation
                    </button>
                  </template>
                </order-box>

              </div>
            </div>



            <div class="main-catalog-container">
              <h4>Sections</h4>
              <div v-for="(section, index) in sortedCatalogSections">
                <order-box
                  :order-object="section"
                  order-object-type="Section"
                  :order-object-id="section.order"
                  :anchor-key="'section-' + index"
                  :index="index"
                  :draft-mode="draftMode"
                  :is-draggable="false"
                  :is-deletable="true"
                  :is-movable="true"
                  :group-size="sortedActiveCatalogSections.length"
                  :box-styles="boxStyles"
                  @move="moveCatalogSectionBox(...arguments, section, index)"
                >
                  <template v-slot:headerTitle>
                    {{ section.title }}
                  </template>

                  <template v-slot:content>
                    <div class="section-title">
                      <label>Title</label>
                      <input v-model="section.title" :disabled="!draftMode" />
                    </div>

                    <div v-for="(catalogPage, i) in sortedCatalogPages(section)"
                      :key="'catalogPage-' + i"
                    >
                      <!-- send userClassification instead so we can filter the data properly -->
                      <order-box
                        :order-object="catalogPage"
                        order-object-type="CatalogPage"
                        :order-object-id="catalogPage.order"
                        :anchor-key="'catalogPage-' + i"
                        :index="i"
                        :draft-mode="draftMode"
                        :is-draggable="false"
                        :group-size="sortedActiveCatalogPages(section).length"
                        :groupName="`${index}`"
                        :box-styles="recommendationStyles"
                        @move="moveCatalogPageBox(...arguments, section, index)"
                      >
                        <template v-slot:headerTitle>
                        </template>

                        <template v-slot:content>
                          <catalog-page :catalogPage="catalogPage" :draftMode="draftMode" />
                        </template>
                      </order-box>
                    </div>

                    <button
                      v-if="draftMode"
                      class="hlt-button secondary"
                      @click="addNewCatalogPage(section)"
                    >
                      Add Catalog Page
                    </button>
                  </template>
                </order-box>
              </div>
              <button
                v-if="draftMode"
                class="hlt-button secondary"
                @click="addNewSection"
              >
                Add Section
              </button>

            </div>


          </div>
          <div class="right-edit">
            <!-- TODO:
              Add validation checkboxes so users can see all the steps and their status to getting the catalog is production ready
            -->
          </div>
        </div>
      </div>
      <metadata />

      <delete-order-box-modal @orderBoxDeletion="deleteBox" />

      <modal
        name="overwrite-live-catalog-modal"
        height="auto"
        :scrollable="false"
        class="vue-modal"
        width="45%"
      >
        <div
          v-if="asset.id != undefined"
          class="vue-modal-inner"
        >
          <div class="vue-modal-topbar">
            <h4 class="overwrite-warning">
              Are you sure you want to save your draft and overwrite the live layer?
            </h4>
          </div>
          <div class="modal-container">
            <div>
              By clicking here, you accept full responsibility for your actions and attest that you are of currently sober mind and body capable of making such an important decision.
              This is a change you can't undo. Once you overwrite the live layer, the updated version will be sent out to users.
            </div>
          </div>

          <div class="vue-modal-bottombar modal-buttons">
            <button
              class="button solid-green-button modal-button"
              @click="overwrite()"
            >
              Yes Overwrite
            </button>
            <button
              class="button modal-button"
              @click="cancel()"
            >
              Cancel
            </button>
          </div>
        </div>
      </modal>

      <edit-footer>
        <template v-slot:left>
          <div class="left">
            <button
              class="hlt-button default draft"
              :class="{ active : draftMode }"
              @click="toggleDraft(true)"
            >
              Draft
            </button>
            <button
              class="hlt-button default live"
              :class="{ active : !draftMode }"
              @click="toggleDraft(false)"
            >
              Live
            </button>
          </div>
        </template>
        <template v-slot:center>
          <div
            v-if="draftMode"
            class="center"
          >
            <button
              v-if="!saveDisabled"
              class="hlt-button primary save-button"
              :class="{ grey: (!draftMode || !canWriteToAsset) }"
              @click="canWriteToAsset && draftMode && save()"
            >
              Save Changes
            </button>
            <button
              v-if="saveDisabled"
              class="hlt-button primary disabled save-button"
            >
              <spinner /> Save Changes
            </button>
          </div>
          <div
            v-else
            class="center"
          />
        </template>
        <template v-slot:right>
          <div
            v-if="draftMode"
            class="right"
          >
            <button
              class="hlt-button secondary overwrite"
              :class="{ grey: !draftMode || !canOverwriteLive }"
              @click="canWriteToAsset && canOverwriteLive && draftMode && overwriteLive()"
            >
              Overwrite Live
            </button>
          </div>
        </template>
      </edit-footer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Spinner from '../../assets/shared/Spinner'
import railsRoutes from '../../../store/rails-routes'
import axios from 'axios'
import Recommendation from './Recommendation'
import CatalogPage from './CatalogPage'
import OrderBox from '../../shared/OrderBox'
import DeleteOrderBoxModal from '../../shared/DeleteOrderBoxModal'
import EditFooter from '../../assets/shared/page_components/EditFooter'
import TinymceEditor from '@tinymce/tinymce-vue'
import tinymce from '../../../mixins/tinymce.js'
import Toggle from '../../assets/shared/page_components/Toggle'
import Metadata from '../../assets/shared/Metadata'
import { mixin as clickaway } from 'vue-clickaway2'

export default {
  name: 'Edit',
  components: {
    Spinner,
    EditFooter,
    TinymceEditor,
    OrderBox,
    DeleteOrderBoxModal,
    Recommendation,
    CatalogPage,
    Toggle,
    Metadata
  },
  mixins: [
    tinymce,
    clickaway
  ],
  data () {
    return {
      draftMode: true,
      editing: {
        description: false,
        shortDescription: false,
        removeDescription: false,
        addDescription: false,
      },
      saveDisabled: false,
      showAddSection: false,
      boxStyles: {
        header: ['shaded-background'],
        content: ['shaded-background']
      },
      recommendationStyles: {
        header: [''],
        content: ['']
      }
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset',
      'currentUser',
      'canWriteToAsset',
      'errors',
      'userClassifications'
    ]),
    sortedCatalogSections: {
      get () {
        let that = this
        if (this.asset.catalog_sections) {
          return that._.orderBy(this.asset.catalog_sections, ['order'], ['asc'])
        } else {
          return []
        }
      },
      set (value) {
        this.asset.catalog_sections = this.orderArray(value, 'order')
      }
    },
    sortedActiveCatalogSections () {
      return this.sortedCatalogSections.filter(x => !x.deleted)
    },
    canOverwriteLive () {
      return this.currentUser.permissions.can_overwrite_live_catalogs
    },
  },
  created () {
    this.setAssetType({ assetType: 'catalog' })
    this.apiCatalogById({ catalogId: this.$route.params.id }).then(response => {
      var record = response.data.records[0]
      this.edit(record)
      this.fetchUserClassifications({ appId: this.asset.apps[0].id })
      this.asset.catalog_reviews = this._.sortBy(this.asset.catalog_reviews, ['order']).filter(s => !s.deleted)
      this.asset.catalog_marquees = this._.sortBy(this.asset.catalog_marquees, ['order']).filter(s => !s.deleted)
      this.draftMode = !this.asset.live_layer
    })
  },
  methods: {
    ...mapActions([
      'edit',
      'delete',
      'update',
      'apiCatalogById',
      'fetchUserClassifications'
    ]),
    ...mapMutations([
      'setAsset',
      'setAssetType',
      'setErrors',
      'resetErrors',
      'setOrderBoxReordering'
    ]),
    sortedActiveRecommendations (userClassification) {
      return this.sortedRecommendations(userClassification).filter(x => !x.deleted)
    },
    sortedRecommendations (userClassification) {
      return this._.sortBy(this.asset.recommended_catalog_pages, ['order']).
                filter(x => x.classification_id == userClassification.id)
    },


    sortedActiveCatalogPages (section) {
      return this.sortedCatalogPages(section).filter(x => !x.deleted)
    },
    sortedCatalogPages (section) {
      return this._.sortBy(section.catalog_pages_catalog_sections, ['order'])
    },


    emptyContent (content) {
      return content === null || content === ''
    },

    moveCatalogSectionBox ({ index, catalogPage, direction }, section, sectionIndex) {
      let localTotalArray = this.sortedCatalogSections

      const incrementedSection = item => {
        item.order += 1
      }

      const decrementedSection = item => {
        item.order -= 1
      }
      let localSortedActive = _.orderBy(localTotalArray.filter(x => !x.deleted), ['order'], ['asc'])

      let item = localTotalArray[sectionIndex]
      if (direction === 'up') {
        let incrementedItem = localSortedActive[(item.order - 1) - 1]
        incrementedSection(incrementedItem)
        decrementedSection(item)
      } else {
        let decrementedItem = localSortedActive[(item.order - 1) + 1]
        incrementedSection(item)
        decrementedSection(decrementedItem)
      }

      let obj = {
        ...this.asset,
        catalog_sections: localTotalArray
      }

      this.setAsset({ asset: obj })
      this.setOrderBoxReordering(false)
    },


    moveCatalogPageBox ({ index, catalogPage, direction }, section, sectionIndex) {
      let localTotalArray = this.sortedCatalogSections

      const incrementedSection = item => {
        item.order += 1
      }

      const decrementedSection = item => {
        item.order -= 1
      }
      let localSection = localTotalArray[sectionIndex]

      let item = this.sortedCatalogPages(localSection)[index]
      if (direction === 'up') {
        let incrementedItem = this.sortedActiveCatalogPages(section)[(item.order - 1) - 1]
        incrementedSection(incrementedItem)
        decrementedSection(item)
      } else {
        let decrementedItem = this.sortedActiveCatalogPages(section)[(item.order - 1) + 1]
        incrementedSection(item)
        decrementedSection(decrementedItem)
      }

      localTotalArray[sectionIndex].catalog_pages_catalog_sections = this.sortedCatalogPages(localSection)

      let obj = {
        ...this.asset,
        catalog_sections: localTotalArray
      }

      this.setAsset({ asset: obj })
      this.setOrderBoxReordering(false)
    },

    moveRecommendedBox ({ index, recommendation, direction }, userClassification) {

      const incrementedSection = item => {
        item.order += 1
      }

      const decrementedSection = item => {
        item.order -= 1
      }

      let item = this.sortedRecommendations(userClassification)[index]

      if (direction === 'up') {
        let incrementedItem = this.sortedActiveRecommendations(userClassification)[(item.order - 1) - 1]
        incrementedSection(incrementedItem)
        decrementedSection(item)
      } else {
        let decrementedItem = this.sortedActiveRecommendations(userClassification)[(item.order - 1) + 1]
        incrementedSection(item)
        decrementedSection(decrementedItem)
      }

      // let obj = {
      //   ...this.asset,
      //   recommended_catalog_pages: this.sortedMarquees
      // }
      // this.setAsset({ asset: obj })
      this.setOrderBoxReordering(false)
    },
    disableSaving (value) {
      this.saveDisabled = value
    },
    save () {
      if (!this.saveDisabled) {
        this.saveDisabled = true
        if (this.asset.id) {
          this.update({ asset: this.asset, url: railsRoutes.api_path({ type: 'catalogs' }) }).then(response => {
            this.edit(response.data.records[0])
            this.saveDisabled = false
            this.resetErrors()
            this.$notify({ title: 'Catalog Saved', type: 'success' })
          }).catch(error => {
            this.saveDisabled = false
            if (error && error.response) {
              this.setErrors({ errors: error.response.data.errors })
            }
            this.$notify({ title: 'Error saving catalog', type: 'error' })
          })
        } else {
          // This should never happen
          this.saveDisabled = false
          this.$notify({ title: 'Error saving catalog', type: 'error' })
        }
      }
    },
    addNewRecommendation (userClassification) {
      this.asset.recommended_catalog_pages.push({
        classification_id: userClassification.id,
        catalog_id: this.asset.id,
        catalog_page_id: '',
        deleted: false,
        order: this.sortedActiveRecommendations(userClassification).length + 1
      })
    },
    addNewSection () {
      this.asset.catalog_sections.push({
        catalog_id: this.asset.id,
        title: '',
        catalog_pages_catalog_sections: [],
        deleted: false,
        order: this.sortedActiveCatalogSections.length + 1
      })
    },
    addNewCatalogPage (section) {
      section.catalog_pages_catalog_sections.push({
        catalog_section_id: section?.id,
        catalog_page_id: '',
        deleted: false,
        order: this.sortedActiveCatalogPages(section).length + 1
      })
    },
    deleteBox (params) {
      if (params.type == 'Recommendation' || params.type == 'Section') {
        this.deleteTopLevelBox(params)
      } else if (params.type == 'CatalogPage') {
        // This works entirely differnet than the other two because its nested not on the asset directly
        this.deleteNestedBox(params)
      }
    },
    deleteTopLevelBox(params) {
      let sortedObjects = []
      let sortedAttribute = null
      let userClassification = null

      if (params.type == 'Recommendation') {
        userClassification = this.userClassifications.filter(x => x.name == params.groupName)[0]
        sortedObjects = this.sortedRecommendations(userClassification)
        sortedAttribute = 'recommended_catalog_pages'
      } else if (params.type == 'Section') {
        sortedObjects = this.sortedCatalogSections
        sortedAttribute = 'catalog_sections'
      }

      let localTotalArray = this.asset[sortedAttribute]
      let indexInsideTotalArray = localTotalArray.indexOf(sortedObjects[params.index])

      localTotalArray[indexInsideTotalArray].deleted = true
      localTotalArray[indexInsideTotalArray].order = 9999999
      localTotalArray[indexInsideTotalArray].errors = {}

      // Adjust orders of everything after this section
      for (let x = params.index + 1; x < sortedObjects.length; x++) {
        // 1. find the index of the localBox inside the copy of the localTotalArray
        // 2. update the record inside the localTotalArray

        indexInsideTotalArray = localTotalArray.indexOf(sortedObjects[x])
        localTotalArray[indexInsideTotalArray].order -= 1
      }

      // 1. set this to the localTotalArray
      this.$set(this.asset, sortedAttribute, localTotalArray)
    },
    deleteNestedBox(params) {
      let sortedObjects = []
      let sortedAttribute = null
      let section = null

      if (params.type == 'CatalogPage') {
        // gropupName Hack to get section index in here
        section = this.sortedCatalogSections[parseInt(params.groupName)]
        sortedObjects = this.sortedCatalogPages(section)
        sortedAttribute = 'catalog_pages_catalog_sections'
      }

      let localTotalArray = this.sortedCatalogSections
      let indexInsideTotalArray = parseInt(params.groupName)

      let localSorted = this._.orderBy(localTotalArray[indexInsideTotalArray][sortedAttribute], ['order'], ['asc'])

      localSorted[params.index].deleted = true
      localSorted[params.index].order = 9999999
      localSorted[params.index].errors = {}

      // Adjust orders of everything after this page
      for (let x = params.index + 1; x < localSorted.length; x++) {
        localSorted[x].order -= 1
      }

      // 1. set this to the localTotalArray
      this.$set(this.asset, 'catalog_sections', localTotalArray)
    },

    displayAssetTypeName (assetBType) {
      if (assetBType === 'V3Flashcard') {
        return 'Item'
      } else {
        return assetBType
      }
    },
    togglePurchaseEnabled () {
      if (this.canWriteToAsset) {
        this.asset.purchase_enabled = !this.asset.purchase_enabled
      }
    },
    toggleVisibility () {
      if (this.canWriteToAsset) {
        this.asset.enabled = !this.asset.enabled
      }
    },
    moduleToggle (event, prop, reference) {
      if (this.draftMode) {
        if (!event) return
        if (event.target.className.includes('mce') || event.target.id.includes('mce')) {
          return
        }

        Object.keys(this.editing).forEach(key => {
          this.editing[key] = prop == key ? !this.editing[prop] : false
        })

        this.setCharPosition(event, reference)
      }
    },
    toggleDraft (val) {
      if ((this.asset.live_catalog_id && val === false) || (this.asset.draft_catalog_id && val === true)) {
        this.draftMode = !this.draftMode
        var recordId = null
        if (this.draftMode) {
          recordId = this.asset.draft_catalog_id
        } else {
          recordId = this.asset.live_catalog_id
        }
        if (this.$router) {
          this.$router.push({ path: `/catalogs/${recordId}/edit` })
          this.setAsset({ asset: {} })
        }
      }
    },
    overwrite () {
      if (this.canWriteToAsset && this.draftMode && this.canOverwriteLive) {
        axios.put(railsRoutes.api_path({ type: 'catalogs', id: this.asset.id, action: 'overwrite_live' }), {
          catalog: this.asset
        }).then(response => {
          if (response.data.records.length > 0) {
            let record = response.data.records[0]
            this.edit(record)
          }
          this.$notify({ title: 'Live Layer Overwriten', type: 'success' })
          if (this.$modal) {
            this.$modal.hide('overwrite-live-catalog-modal')
          }
        }).catch(error => {
          console.error('Error: ', error)
          this.$notify({ title: 'Error Overwriting Live Layer', type: 'error' })
          if (this.$modal) {
            this.$modal.hide('overwrite-live-catalog-modal')
          }
        })
      }
    },
    overwriteLive () {
      if (this.$modal) {
        this.$modal.show('overwrite-live-catalog-modal')
      }
    },
    cancel () {
      if (this.$modal) {
        this.$modal.hide('overwrite-live-catalog-modal')
      }
    }
  }
}
</script>

<style lang="scss">
.catalogs-edit-container {
  position: relative;
  height: 100%;

  label {
    margin: 0;
  }
  .small-text {
    font-size: 14px;
    color: $space-black-50;
  }
  .catalog-input {
    margin: 0 0 5px 0;
  }
  .catalog-name-input {
    margin: 0;
  }
  .helper-text {
    color: $space-black-50;
    font-size: .75rem;
  }
  .main-catalog-container {
    padding-bottom: 20px;
  }
  .outline-link {
    color: $space-black;
    transition: .3s;
    &:hover {
      color: $space-gray;
      cursor: pointer;
    }
  }
  .container-textarea {
    font-size: 14px;
    padding: 10px;
    height: auto;
    min-height: 150px;
    border: 1px solid $space-gray;
    background: $white-2;
    border-radius: 4px;
    &:hover {
      border: 1px solid $sky-blue;
    }
  }
  .empty-content {
    color: $space-gray;
  }
  .catalog-edit-content{
    display: flex;
    padding: 0px;
    justify-content: space-between;
    flex-direction: column;
  }
  .main-container {
    width: 100%;
    padding: 1.75rem 12rem;
    margin-bottom: 2rem;
    .main-section {
      display: flex;
      justify-content: space-between;
      .left-edit {
        flex: 4;
        padding-right: 44px;
      }
      .right-edit {
        flex: 1;
        div {
          margin-bottom: 10px;

          label {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .main-section-container {
    margin-bottom: 50px;
    padding: 20px;
  }
  .catalog-name-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .left-section {
      flex: 1;
      padding-right: 24px;
    }
  }
  .section-title {
    margin-bottom: 20px;
  }
  .catalog-container {
    margin-bottom: 24px;
  }

  .asset-b-input {
    margin-bottom: 4px;
  }
  .section-area {
    padding-bottom: 24px;
  }
  .catalog-edit-active .mce-tinymce {
    border: 1px solid $sky-blue;
    border-radius: 4px;
  }
  .outline-title {
    font-size: 12.8px;
  }
  .outline-side-list {
    margin: 0;
    margin-bottom: 16px;
  }
  .outline-listing {
    list-style: none;
  }
  .outline-asset-title {
    font-size: 12.8px;
  }
  .outline-header {
    display: block;
    margin: 0;
    margin-bottom: 8px;
  }
  .disabled {
    background: $grey-4;
  }

  .association-section {
    padding: 1.75rem 12rem;
    border-top: 4px solid $grey-4;
    margin-bottom: 4rem;
  }
  .catalogs-footer {
    width: 100%;
    padding: 1.75rem 8rem;
    background: $blue-5;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    .catalog-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 5rem;
    }
    .catalog-select {
      width: 50%;
    }
  }
  .draft-live-footer {
    .hlt-select {
      margin: 0 30px;
      width: 300px;
    }
  }
  .modal-section {
    border: 1px solid $space-gray;
    background: $cloud-white;
    padding: 20px 40px 40px 40px;
  }
  .catalog-text-header {
    margin-bottom: 13px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    .question-type {
      margin-left: 12px;
      font-size: 14px;
      color: $space-gray;
    }
  }

  .catalog-text-subheader {
    margin: 0;
    padding-bottom: 8px;
  }
}
</style>
