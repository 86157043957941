<template>
  <nav-container>
    <template v-slot:headerName>
      <div class="title">
        App
      </div>
    </template>
    <template v-slot:listItems>
      <ul class="menu">
        <li
          :class="assetClasses('decks')"
          class="nav-button decks"
          v-if="currentUser.permissions.can_read_decks"
        >
          <router-link :to="{ name: 'decks' }">Decks</router-link>
        </li>
        <li
          :class="assetClasses('app-hierarchy')"
          class="nav-button app-hierarchy"
        >
          <router-link :to="{ name: 'app-hierarchy' }">Hierarchy</router-link>
        </li>
        <li
          :class="assetClasses('catalogs')"
          class="nav-button catalogs"
          v-if="currentUser.permissions.can_read_catalogs"
        >
          <router-link :to="{ name: 'catalogs' }">Catalogs</router-link>
        </li>
        <li
          :class="assetClasses('catalog_pages')"
          class="nav-button catalog_pages"
          v-if="currentUser.permissions.can_read_catalog_pages"
        >
          <router-link :to="{ name: 'catalog_pages' }">Catalog Pages</router-link>
        </li>
      </ul>
    </template>
  </nav-container>
</template>

<script>
import { mapGetters } from 'vuex'
import NavContainer from '../../shared/NavContainer'
export default {
  components: {
    NavContainer
  },
  computed: {
    ...mapGetters([
      'isAppHierarchyRoute',
      'isDeckRoute',
      'isCatalogRoute',
      'isCatalogPageRoute',
      'currentUser'
    ])
  },
  methods: {
    assetClasses (type) {
      let active = false
      if (type == 'decks') {
        active = this.isDeckRoute
      }
      if (type == 'app-hierarchy') {
        active = this.isAppHierarchyRoute
      }
      if (type == 'catalogs') {
        active = this.isCatalogRoute
      }
      if (type == 'catalog_pages') {
        active = this.isCatalogPageRoute
      }
      return {
        'nav-item': true,
        'top-active': active
      }
    }
  }
}
</script>
