<template>
  <div class="hierarchy-column">
    <draggable
      v-model="rows"
      :group="rows"
      class="draggable"
    >
      <transition-group>
        <div
          v-for="(row, i) in rows"
          :key="'column-' + i"
          :row="row"
          class="hierarchy-asset"
          :class="{ 'selected': row.selected, 'active': isActive(row) }"
        >
          <app
            v-if="row.rowType == 'App' && currentUser.permissions.can_read_apps"
            :row="row"
            :column-index="columnIndex"
          />
          <navigation-group
            v-if="row.rowType == 'NavigationGroup' && currentUser.permissions.can_read_navigation_groups"
            :row="row"
            :column-index="columnIndex"
          />
          <navigation-item
            v-if="row.rowType == 'NavigationItem' && currentUser.permissions.can_read_navigation_items"
            :row="row"
            :column-index="columnIndex"
          />
          <category
            v-if="row.rowType == 'Category' && currentUser.permissions.can_read_categories"
            :row="row"
            :column-index="columnIndex"
          />
          <item
            v-if="row.rowType == 'Item' && currentUser.permissions.can_read_flashcards"
            :row="row"
            :column-index="columnIndex"
          />
          <topic
            v-if="row.rowType == 'Topic' && currentUser.permissions.can_read_topics"
            :row="row"
            :column-index="columnIndex"
          />
          <attachment
            v-if="row.rowType == 'Attachment' && currentUser.permissions.can_read_attachments"
            :row="row"
            :column-index="columnIndex"
          />
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Draggable from 'vuedraggable'
import App from './App.vue'
import NavigationGroup from './NavigationGroup.vue'
import NavigationItem from './NavigationItem.vue'
import Category from './Category.vue'
import Item from './Item.vue'
import Topic from './Topic.vue'
import Attachment from './Attachment'
import railsRoutes from '../../../store/rails-routes'
import axios from 'axios'

export default {
  components: {
    Draggable,
    App,
    NavigationGroup,
    NavigationItem,
    Category,
    Item,
    Topic,
    Attachment
  },
  props: [
    'columnIndex'
  ],
  data () {
    return {
      showMenu: {}
    }
  },
  computed: {
    rows: {
      get () {
        return this._.sortBy(this.appHierarchyColumns[this.columnIndex].rows, ['rowObject.order'])
      },
      set (value) {
        let updateOnce = false
        let batchUploadType = null

        if ((this.appHierarchyColumns[this.columnIndex].parentType == 'Category') &&
            this.appHierarchyColumns[this.columnIndex].parent.name.startsWith('attachments')) {
          updateOnce = true
          batchUploadType = 'attachments'
        }

        for (let i in value) {
          let url = null
          let rowType = null
          if (value[i].rowType == 'NavigationItem') {
            url = railsRoutes.api_path({ type: 'navigation_items' })
            rowType = 'navigation_item'
          } else if (value[i].rowType == 'Attachment') {
            url = railsRoutes.api_path({ type: 'attachments' })
            rowType = 'attachment'
          }

          if (url) {
            value[i].rowObject.order = parseInt(i) // only order things that can be ordered?

            // TODO: This update won't work as is because it relies on the global state assetType to shape the param names
            // we don't want to have to set that global asset type for every update
            let data = {}
            data[rowType] = value[i].rowObject

            if (!updateOnce) {
              axios.put(`${url}/${value[i].rowObject.id}.json`, data).then(response => {
                this.$notify({
                  title: 'Ordering saved',
                  type: 'success'
                })
              }).catch(error => {
                console.error(error)
                this.$notify({
                  title: 'Error saving order',
                  type: 'error'
                })
              })
            }
          }
        }

        if (updateOnce) {
          let url = null

          // This might need some massaging to work with other types of bulk updated columns
          let data = {
            app_id: this.selectedApp.id,
            category_id: this.appHierarchyColumns[this.columnIndex].parent.id
          }

          if (batchUploadType == 'attachments') {
            url = railsRoutes.api_path({ type: 'attachments' })
            data[batchUploadType] = value.map(x => x.rowObject)
          }
          axios.post(`${url}/batch_update_order.json`, data).then(response => {
            this.$notify({
              title: 'Ordering saved',
              type: 'success'
            })
          }).catch(error => {
            console.error(error)
            this.$notify({
              title: 'Error saving order',
              type: 'error'
            })
          })
          this.appHierarchyColumns[this.columnIndex].rows = value
        }
      }
    },
    selectedApp () {
      return this.$store.getters['appHierarchy/selectedApp']
    },
    ...mapGetters([
      'appHierarachy/appHierarchyColumns',
      'currentUser'
    ]),
    ...mapState('appHierarchy', {
      appHierarchyColumns: state => state.appHierarchyColumns
    })
  },
  methods: {
    isActive (row) {
      return row == this.$store.getters['appHierarchy/activeRow']
    },
    ...mapActions([
      'update'
    ])
  }
}
</script>
