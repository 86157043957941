<template>
  <div class="phones">
    <mobile-preview>
      <template v-slot:label>
        Front
      </template>
      <template v-slot:content>
        <div class="front">
          <div class="content">
            <div class="question" v-html="asset.question" />
            <div class="choices">
              <div
                class="choice"
                v-for="choice in choices"
                v-html="choice.raw_content" />
            </div>
          </div>
          <div class="footer-bar" />
        </div>
      </template>
    </mobile-preview>

    <mobile-preview>
      <template v-slot:label>
        Back
      </template>
      <template v-slot:content>
        <div class="back">
          <div class="content">
            <div class="third-header" />
            <div class="choices">
              <div
                class="choice"
                v-for="choice in choices"
              >
                <div class="raw-content" v-html="choice.raw_content" />
                <div class="rationale" v-html="choice.raw_rationale" />
              </div>
            </div>
            <div class="exam-tip-header">
              Exam Tip
            </div>
            <div class="exam-tip" v-html="asset.exam_tip" v-if="asset.exam_tip" />
            <div class="key-takeaway-header">
              Key Takeaway
            </div>
            <div class="key-takeaway" v-html="asset.key_takeaway" v-if="asset.key_takeaway" />
            <div class="explanation-header" v-if="asset.rationale">
              Explanation
            </div>
            <div class="explanation" v-html="asset.rationale" v-if="asset.rationale" />
          </div>
          <div class="footer-bar" />
        </div>
      </template>
    </mobile-preview>
  </div>
</template>

<script>
import MobilePreview from '../../shared/MobilePreview.vue'

export default {
  props: {
    asset: {
      type: Object,
      required: true
    }
  },
  components: {
    MobilePreview
  },
  computed: {
    choices () {
      if (this.asset.choices) {
        return this._.sortBy(this.asset.choices.filter(x => !x.deleted), ['sort_order'])
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.phones {
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  .choice ::v-deep p {
    margin: 0px;
  }

  .front {
    display: flex;
    flex-direction: column;

    .content {
      height: 630px;
      overflow-y: scroll;

      .question {
        margin-top: 68px;
        padding: 0px 16px;
        padding-bottom: 25px;
        border-bottom: 1px solid $space-gray-20;

        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
      .choice {
        padding: 18px 50px;
        border-bottom: 1px solid $space-gray-20;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .footer-bar {
      height: 94px;
      background: $space-gray-20;
    }
  }

  .back {
    display: flex;
    flex-direction: column;

    .content {
      height: 608px;
      overflow-y: scroll;

      .third-header {
        height: 76px;
        background: $space-gray-20;
      }

      .choice {
        border-bottom: 1px solid $space-gray-20;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        .raw-content {
          padding: 30px 24px;
          margin-left: 75px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
        }
        .rationale {
          margin-top: 0px;
          padding: 0px 24px;
          margin-bottom: 23px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .explanation-header,
      .exam-tip-header,
      .key-takeaway-header {
        padding: 0px 24px;
        font-family: "open sans";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-top: 42px;
      }
      .explanation,
      .key-takeaway,
      .exam-tip {
        margin: 0px;
        padding: 10px 24px;
        font-family: "open sans";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
      }

    }
    .footer-bar {
      width: 375px;
      height: 116px;
      background: $space-gray-20;
    }
  }
}
</style>
