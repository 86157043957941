<template>
  <div
    class="hierarchy-item"
    @click="selectItem()"
  >
    <folder-svg />
    <div
      class="hierarchy-title"
      :title="navigationItem.name"
      v-html="truncatedTitle()"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import axios from 'axios'
import FolderSvg from '@/assets/images/icons/folder.svg'

export default {
  components: {
    FolderSvg
  },
  props: [
    'row',
    'columnIndex'
  ],
  data () {
    return {
      showMenu: {}
    }
  },
  computed: {
    navigationItem () {
      return this.row.rowObject
    }
  },
  methods: {
    truncatedTitle () {
      if (this.navigationItem.name && (this.navigationItem.name.length > 55)) {
        return this.navigationItem.name.substring(0, 55) + '...'
      } else {
        return this.navigationItem.name
      }
    },
    selectItem () {
      this.$store.commit('appHierarchy/selectRow', {
        columnIndex: this.columnIndex,
        row: this.row
      })

      // EDIT ASSET
      this.edit(this.navigationItem)

      // SET ASSET TYPE
      this.setAssetType({ assetType: 'navigation_item' })

      // Use the nav item destination to determine what API endpoint needs to be fetched for the next column
      if (['flashcard_categories', 'attachment_categories', 'attachments', 'topic_categories', 'topics'].includes(this.navigationItem.navigation_destination.name)) {
        axios.get(railsRoutes.api_path({ type: 'categories' }), {
          params: { 'category_type_id': this.navigationItem.resource_id }
        }).then(response => {
          this.$store.commit('appHierarchy/addHierarchyColumn', {
            parent: this.navigationItem,
            parentType: 'NavigationItem',
            rows: response.data.records.map(x => ({ rowType: 'Category', rowObject: x })),
            columnIndex: this.columnIndex
          })
        })
      } else if (this.navigationItem.navigation_destination.name == 'navigation_group') {
        axios.get(railsRoutes.api_path({ type: 'navigation_groups', id: this.navigationItem.resource_id }), {
        }).then(response => {
          this.$store.commit('appHierarchy/addHierarchyColumn', {
            parent: this.navigationItem,
            parentType: 'NavigationItem',
            rows: response.data.records.map(x => ({ rowType: 'NavigationGroup', rowObject: x })),
            columnIndex: this.columnIndex
          })
        })
      }
    },
    ...mapMutations([
      'setAssetType'
    ]),
    ...mapActions([
      'edit'
    ])
  }
}
</script>
