import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '../store/rails-routes'

export default {
  computed: {
    ...mapGetters([
      'topics'
    ])
  },
  methods: {
    ...mapMutations([
      'setDeleteAsset',
      'setDeletableAsset',
      'addModifiedAssetId'
    ]),
    ...mapActions([
      'create',
      'edit',
      'addTopic',
      'selectTopic',
      'selectAsset'
    ]),
    newTopicParams (path) {
      return { asset: { parent_path: path }, url: railsRoutes.api_path({ type: 'topics' }) }
    },
    createChild (topic) {
      this.create(this.newTopicParams(topic.path)).then(response => {
        this.updateOrderings(response.data.records)
        this.selectAsset({ asset: topic })
        this.hideMenu()
        this.$notify({ title: 'Child Topic Saved', type: 'success' })
      })
    },
    createSibling (topic) {
      if (topic.root_id != topic.id) {
        var path = topic.path.split('.')
        path.pop()
        var parentPath = path.join('.')
        this.create(this.newTopicParams(parentPath)).then(response => {
          this.updateOrderings(response.data.records)
          this.selectAsset({ asset: topic })
          this.hideMenu()
          this.$notify({ title: 'Sibling Topic Saved', type: 'success' })
        })
      }
    },
    addNewAssetAssociation (topic) {
      this.hideMenu()
      if (this.$modal) {
        this.addModifiedAssetId({ id: topic.id })

        this.$modal.show('add-asset-association-modal', {
          availableContexts: this.availableContexts(topic),
          assetAssociation: {
            asset_a_id: topic.id,
            asset_a_type: 'Topic',
            asset_a_context: '',
            asset_b_id: '',
            asset_b_type: '',
            asset_b_context: '',
            button_text: '',
            order: ''
          },
          humanContext: 'Asset Association',
          saveText: 'Add',
          editText: 'Add'
        })
      }
    },
    showDeleteConfirmation (topic) {
      this.setDeletableAsset({ asset: topic })
      this.addModifiedAssetId({ id: topic.id })
      this.$modal.show('delete-modal')
    },
    updateOrderings (records) {
      if (this.topics) {
        records.forEach((a) => {
          this.addTopic({ record: a })
        })
      }
    },
    hideMenu () {
      if (this.showMenu != undefined) {
        this.showMenu = false
      }
    },
    availableContexts (topic) {
      const contexts = [
        'Content',
        'Asset Tray',
        'Primary Action'
      ]
      if (topic.asset_associations.filter(x => !x.deleted && (x.asset_a_context == 'Primary Action' || x.asset_a_context == null)).length > 0) {
        return contexts.filter(x => x != 'Primary Action')
      } else {
        return contexts
      }
    }
  }
}
