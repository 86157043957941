<template>
  <div class="hierarchy-menu-container">
    <div
      class="hierarchy-menu"
      :class="{ active : showMenu }"
      @click.stop="displayMenu()"
    >
      <div class="dot" /><div class="dot" /><div class="dot" />
    </div>

    <div
      v-if="showMenu"
      v-on-clickaway="hideMenu"
      class="hierarchy-menu-modal"
    >
      <ul>
        <li @click.stop="createChild(propAsset)">
          Create a new child
        </li>
        <li
          v-if="propAsset.id != topLevelAsset.id"
          @click.stop="createSibling(propAsset)"
        >
          Create a new sibling
        </li>
        <li @click.stop="addNewAssetAssociation(propAsset)" v-if="topLevelAsset.topic_type != 'Asset Tray'">
          Add Asset Association
        </li>
        <li @click.stop="showDeleteConfirmation(propAsset)">
          Delete
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import topicHierarchyMenu from '../../../mixins/topic-hierarchy-menu'

export default {
  mixins: [
    clickaway,
    topicHierarchyMenu
  ],
  props: ['propAsset'],
  data () {
    return {
      showMenu: false
    }
  },
  computed: {
    ...mapGetters([
      'topLevelAsset'
    ])
  },
  methods: {
    displayMenu () {
      this.showMenu = true
    }
  }
}
</script>
<style lang="scss" scoped>
.hierarchy-menu {
  margin-top: 6px;
  width: 24px;
  height: 24px;
  padding: 2px;
  padding-left: 8px;
  margin-top: 0px;
  border-radius: 2px;
  .dot {
    width: 4px;
    height: 4px;
    background-color: $grey-3;
    border-radius: 45px;
    margin: 2px;
  }
}
.hierarchy-menu:hover, .hierarchy-menu.active{
  background-color: $blue-3;

  .dot {
    background-color: white;
  }
}
.hierarchy-menu-modal {
  color: black;
  margin: 0px;
  display: inline-block;
  position: absolute;
  z-index: 10;

  ul {
    border: 2px solid $grey-3;
    margin: 0px;
    list-style: none;
    background-color: #f9f9f9;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px $drop-shadow-2;
    z-index: 1;

    li {
      border-bottom: 1px solid $grey-3;
      color: black;
      text-decoration: none;
      display: block;
      padding: 10px;
      padding-left: 15px;
      background-color: $white-1;
    }
    li:hover {
      background-color: $blue-4;
      color: white;
    }
  }
}
</style>
