<template>
  <table
    class="versions-table"
    aria-describedby="version-table"
  >
    <thead>
      <tr>
        <th id="version-index">
          Version
        </th>
        <th id="version-asset">
          Asset ID
        </th>
        <th id="version-date">
          Created At
        </th>
        <th id="version-creator">
          Created By
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(version, index) in asset.versions"
        :key="'version-' + version.id"
        :class="{ 'current' : version.id == asset.id}"
      >
        <td class="version-index">
          {{ index + 1 }}
        </td>
        <td class="version-id">
          <router-link
            :to="{ name: `${assetName}`, params: { id: version.id } }"
          >
            {{ version.id }}
          </router-link>
        </td>
        <td class="version-created-date">
          {{ prettyDate(version.created_at) }}
        </td>
        <td class="version-creator">
          {{ version.created_by }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import date from '../../../mixins/date'

export default {
  mixins: [date],
  props: {
    asset: {
      type: Object,
      required: true
    },
    assetName: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.versions-table {
  background-color: white;
  text-align: left;
  border-spacing: 0px;
  border-collapse: separate;
  border: 1px solid $space-gray-10;
  border-radius: 6px;
  font-size: .7em;
  th, tr, td {
    border: none;
    border-right: 0px;
    margin: 0px;
    padding: 0px;
  }
  tr {
    border-bottom: 1px solid $space-gray-10 !important;
    td {
      border-left: 1px solid $space-gray-10;
      padding: 5px;
      padding-left: 10px;
    }
    td:first-of-type {
      border-left: none;
    }
  }

  tr:first-of-type {
    th {
      padding: 5px;
      padding-left: 10px;
      color: $blue-2;
      border-bottom: 1px solid $space-gray-10;
      border-left: 1px solid $space-gray-10;
    }
    th:first-of-type {
      border-left: none;
      border-top-left-radius: 6px;
    }
    th:last-of-type {
      border-top-right-radius: 6px;
    }
  }

  tr:last-of-type {
    td:first-of-type {
      border-bottom-left-radius: 6px;
    }
    td:last-of-type {
      border-bottom-right-radius: 6px;
    }
  }
  .current {
    background: lighten($sky-blue, 25%);
  }
}
</style>
