<template>
  <div class="catalog-pages">
    <search />
  </div>
</template>

<script>
import Search from '../../shared/Search'
export default {
  components: {
    Search
  }
}
</script>

<style lang="scss" scoped>

</style>
