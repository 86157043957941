<template>
  <div class="card-container">
    <div
      :id="'deck-card-' + index"
      class="card-content-box"
    >
      <div class="card-content-section">
        <div class="card-asset-section">
          <div class="card-asset">
            <label class="card-label weight-600">Asset Type</label>
            <asset-select-type
              v-model="card"
              :disabled="!draftMode"
              :asset-types="['Attachment', 'Category', 'CategoryType', 'Deck', 'Grouping', 'Item', 'Learning Module', 'Topic', 'WuxTestSimulations']"
            />
            <error-message
              v-if="card.errors && card.errors.asset_type"
              message="must have an asset type"
            />
            <error-message
              v-if="card.errors && card.errors.asset"
              message="invalid asset"
            />
          </div>
          <div class="card-asset">
            <label class="card-label weight-600">Asset ID</label>
            <input
              v-model="card.asset_id"
              class="card-input hlt-input"
              type="text"
              required
              :class="{'error': card.errors && (card.errors.asset_id || card.errors.asset)}"
              placeholder="Write Asset ID"
              :disabled="!draftMode"
            >
            <error-message
              v-if="card.errors && card.errors.asset_id"
              message="must have a valid live ID"
            />
            <error-message
              v-if="card.errors && card.errors.asset"
              message="invalid asset"
            />
          </div>
          <div class="card-asset">
            <label class="card-label weight-600">Featured Attachment ID</label>
            <input
              v-model="card.featured_image_id"
              class="card-input hlt-input"
              type="text"
              required
              :class="{'error': card.errors && card.errors.featured_image_id}"
              placeholder="Write Attachment ID"
              :disabled="!draftMode"
            >
            <error-message
              v-if="card.errors && card.errors.featured_image_id"
              message="must have a valid attachment ID"
            />
          </div>
        </div>
        <div class="card-title card-area">
          <label class="card-label weight-600">Card Title</label>
          <input
            v-model="card.title"
            class="card-input hlt-input"
            type="text"
            required
            :class="{'error': card.errors && card.errors.title }"
            placeholder="Write card title"
            :disabled="!draftMode"
          >
          <error-message
            v-if="card.errors && card.errors.title"
            message="can't be blank"
          />
        </div>
        <div class="card-description card-area">
          <div
            class="card-container description-area"
            @click="e => moduleToggle(e, 'cardDescription', 'card-description')"
          >
            <div
              v-if="!editing['cardDescription']"
              class="card-edit-html-description"
            >
              <label
                class="container-label weight-600"
                for="c-description"
              >Asset Description</label>
              <div
                :class="[{ 'empty-content': emptyContent(card.description) , 'disabled': !draftMode }, 'container-textarea']"
                name="c-description"
                v-html="card.description || 'Write asset description'"
              />
            </div>

            <div
              v-if="editing['cardDescription']"
              class="card-edit-active description-active"
            >
              <label
                class="container-label weight-600"
                for="description"
              >Asset Description</label>
              <tinymce-editor
                ref="card-description"
                v-model="card.description"
                v-on-clickaway="e => moduleToggle(e, 'cardDescription', 'card-description')"
                :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                class="card-tinymce"
                name="title"
                @onInit="setCaretPosition"
              />
            </div>
          </div>
        </div>

        <div class="card-config card-area">
          <label class="card-label weight-600">Card Config</label>
          <textarea
            v-model="card.config"
            class="card-input hlt-input"
            :class="{'error': card.errors && card.errors.title }"
            placeholder="Card Config"
            :disabled="!draftMode"
            rows="5"
          >
          </textarea>
          <error-message
            v-if="card.errors && card.errors.config"
            message="can't be blank"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TinymceEditor from '@tinymce/tinymce-vue'
import tinymce from '../../../mixins/tinymce'
import { mixin as clickaway } from 'vue-clickaway2'
import { mapGetters } from 'vuex'
import AssetSelectType from '../../assets/shared/page_components/AssetSelectType'

export default {
  components: {
    TinymceEditor,
    AssetSelectType
  },
  mixins: [
    clickaway,
    tinymce
  ],
  props: {
    card: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    },
    draftMode: {
      type: Boolean
    }
  },
  data () {
    return {
      editing: {
        cardDescription: false,
        cardConfig: false,
      }
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'errors'
    ])
  },
  methods: {
    saveToParent (value) {
      this.$emit('saveDisabled', value)
    },
    moduleToggle (event, prop, reference) {
      if (this.draftMode) {
        if (!event) return
        if (event.target.className.includes('mce') || event.target.id.includes('mce')) {
          return
        }
        Object.keys(this.editing).forEach(key => {
          this.editing[key] = prop == key ? !this.editing[prop] : false
        })
        this.setCharPosition(event, reference)
      }
    },
    emptyContent (content) {
      return content === null || content === ''
    }
  }
}
</script>

<style lang="scss">
  .card-content-box {
    .card-content-section {
      padding: 0;
      .card-asset-section {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        height: 100%;
        .card-asset {
          margin-bottom: 1rem;
          .hlt-input, .hlt-select {
            width: 200px;
            margin-bottom: 0;
            @media (max-width: 1500px) {
              margin-right: 15px;
            }
          }
        }
      }
      .container-textarea {
        &.disabled {
          background: $grey-4;
        }
      }
    }
  }
</style>
