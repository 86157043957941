<template>
  <div>
    <div class="inner-content inner-solid">
      <div class="inner-top-bar sticky-top-bar">
        <h1>Edit Navigation Item</h1>
        <h4>ID: {{ asset.id }}</h4>
        <button
          class="button hollow-red-button"
          :class="{ disabled : !currentUser.permissions.can_delete_navigation_items }"
          @click="deleteRecord()"
        >
          Delete
        </button>
        <button
          :class="{ disabled : (saveDisabled) }"
          class="button solid-green-button"
          @click="save()"
        >
          Save
        </button>
      </div>
      <div class="edit-content-container">
        <div class="left-container">
          <div>
            <label>Name</label>
            <div class="medium-8">
              <input
                v-model="asset.name"
                type="text"
                :disabled="saveDisabled"
              >
            </div>
          </div>

          <div>
            <label>Active Name</label>
            <div class="medium-8">
              <input
                v-model="asset.active_name"
                type="text"
                :disabled="saveDisabled"
              >
            </div>
          </div>

          <div>
            <label>Description</label>
            <div class="medium-8">
              <textarea
                v-model="asset.description"
                rows="5"
                name="description"
                :disabled="saveDisabled"
              />
            </div>
          </div>

          <div>
            <label>Navigation Icon</label>
            <div class="medium-8">
              <select
                v-model="asset.navigation_icon_id"
                name="navigation_icon_id"
                class="nav-icon-selection"
                :disabled="saveDisabled"
              >
                <option value="null">
                  - Select a Navigation Icon -
                </option>
                <option
                  v-for="(navIcon, i) in navigationIcons"
                  :key="i"
                  :value="navIcon.id"
                  :navIcon="navIcon"
                >
                  {{ navIcon.name }}
                </option>
              </select>
            </div>
          </div>

          <div>
            <label>Active Navigation Icon</label>
            <div class="medium-8">
              <select
                v-model="asset.active_navigation_icon_id"
                name="active_navigation_icon_id"
                class="nav-icon-selection"
                :disabled="saveDisabled"
              >
                <option value="null">
                  - Select Active Navigation Icon -
                </option>
                <option
                  v-for="(navIcon, i) in navigationIcons"
                  :key="i"
                  :value="navIcon.id"
                  :navIcon="navIcon"
                >
                  {{ navIcon.name }}
                </option>
              </select>
            </div>
          </div>

          <div>
            <label>Web Navigation Icon</label>
            <div class="medium-8">
              <select
                v-model="asset.web_navigation_icon_id"
                name="web_navigation_icon_id"
                class="nav-icon-selection"
                :disabled="saveDisabled"
              >
                <option value="null">
                  - Select Web Navigation Icon -
                </option>
                <option
                  v-for="(navIcon, i) in navigationIcons"
                  :key="i"
                  :value="navIcon.id"
                  :navIcon="navIcon"
                >
                  {{ navIcon.name }}
                </option>
              </select>
            </div>
          </div>

          <div>
            <label>Show on Web Dashboard</label>
            <div class="medium-8">
              <input
                v-model="asset.show_on_dashboard"
                type="checkbox"
                :disabled="saveDisabled"
              >
            </div>
          </div>

          <div>
            <label>Web Dashboard Order</label>
            <div class="medium-8">
              <input
                v-model="asset.dashboard_order"
                type="number"
                min="0"
                step="1"
                :disabled="saveDisabled"
              >
            </div>
          </div>

          <div>
            <label>Navigation Destination</label>
            <div class="medium-8">
              <select
                v-model="asset.navigation_destination_id"
                name="navigation_destination_id"
                class="nav-destination-selection"
                :disabled="saveDisabled"
                @change="updateResources(true)"
              >
                <option value="null">
                  - Select a Navigation Destination -
                </option>
                <option
                  v-for="(destination, i) in navigationDestinations"
                  :key="i"
                  :value="destination.id"
                  :destination="destination"
                >
                  {{ destination.name }}
                </option>
              </select>
            </div>
          </div>

          <div>
            <label>Destination Resource</label>
            <div class="medium-8">
              <select
                v-model="asset.resource_id"
                name="resource_id"
                class="resource-selection"
                :disabled="saveDisabled"
              >
                <option value="null">
                  - Select a Resource -
                </option>
                <option
                  v-for="(resource, i) in resources"
                  :key="i"
                  :value="resource.id"
                  :resource="resource"
                >
                  {{ resource.id }}: {{ resource.name }}
                </option>
              </select>
            </div>
          </div>

          <div>
            <label>Theme Color</label>
            <div class="medium-8">
              <select
                v-model="asset.theme_color_id"
                name="theme_color_id"
                class="theme-color-selection"
                :disabled="saveDisabled"
              >
                <option value="null">
                  - Select a Theme Color -
                </option>
                <option
                  v-for="(color, i) in themeColors"
                  :key="i"
                  :value="color.id"
                  :color="color"
                >
                  {{ color.key }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import axios from 'axios'

export default {
  data () {
    return {
      saveDisabled: false,
      resources: [],
      themeColors: []
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'currentUser'
    ]),
    ...mapState('appHierarchy', {
      navigationIcons: state => state.navigationIcons,
      navigationDestinations: state => state.navigationDestinations.sort((a, b) => {
        return (a.name > b.name) - (a.name < b.name)
      })
    })
  },
  created () {
    // Grab the resources for the selected destination
    this.updateResources(false)

    axios.get(railsRoutes.api_path({ type: 'theme_colors' }), {
      params: { app_id: this.asset.app_id }
    }).then(response => {
      this.themeColors = response.data.records
    })

    if (!this.currentUser.permissions.can_write_navigation_items) {
      this.saveDisabled = true
    }
  },
  methods: {
    updateResources (changedDestination) {
      if (changedDestination) {
        this.asset.resource_id = null
      }

      if (this.asset.app_id != null) {
        this.resources = []

        var destination = this.navigationDestinations.find(x => x.id == this.asset.navigation_destination_id)
        var url = null
        if (destination) {
          if (destination.resource_model == 'CategoryType') {
            url = railsRoutes.api_path({ type: 'category_types' })
          } else if (destination.resource_model == 'V3Flashcard') {
            url = railsRoutes.api_path({ type: 'items' })
          } else if (destination.resource_model == 'Category') {
            url = railsRoutes.api_path({ type: 'categories' })
          } else if (destination.resource_model == 'PurchaseOrder') {
            url = railsRoutes.api_path({ type: 'purchase_orders' })
          } else if (destination.resource_model == 'NavigationGroup') {
            url = railsRoutes.api_path({ type: 'navigation_groups' })
          } else if (destination.resource_model == 'Attachment') {
            url = railsRoutes.api_path({ type: 'attachments' })
          } else if (destination.resource_model == 'Topic') {
            url = railsRoutes.api_path({ type: 'topics' })
          } else if (destination.resource_model == 'Monograph') {
            url = railsRoutes.api_path({ type: 'monographs' })
          } else if (destination.resource_model == 'LandingPage') {
            url = railsRoutes.api_path({ type: 'landing_pages' })
          }
        }

        if (url) {
          axios.get(url + '.json', {
            params: { app_id: this.asset.app_id }
          }).then(response => {
            this.resources = response.data.records
          })
        }
      }
    },
    save () {
      if (!this.saveDisabled) {
        this.saveDisabled = true
        if (this.asset.id) {
          this.update({ asset: this.asset, url: railsRoutes.api_path({ type: 'navigation_items' }) }).then(response => {
            this.edit(response.data.records[0])

            // TODO:
            // If the nav destination or resource id changes we are going to need to reselect this row
            // The destination and resource id determine what goes into the next column
            // problem is how do we get the columnIndex and row from within this component
            //        this.$store.commit('appHierarchy/selectRow', {
            //          columnIndex: this.columnIndex,
            //          row: this.row
            //        })

            this.saveDisabled = false
            this.$notify({ title: 'Navigation Item Saved', type: 'success' })
          }).catch(error => {
            console.log(error)
            this.saveDisabled = false
            this.$notify({ title: 'Error saving Navigation Item', type: 'error' })
          })
        } else {
          // This should never happen
          this.saveDisabled = false
          this.$notify({ title: 'Error saving Navigation Item', type: 'error' })
        }
      }
    },
    deleteRecord () {
      if (this.currentUser.permissions.can_delete_navigation_items) {
        if (this.$modal) {
          this.$modal.show('delete-modal')
        }
      }
    },
    ...mapActions([
      'edit',
      'update',
      'delete'
    ]),
    ...mapMutations([
      'setAssetType'
    ])
  }
}
</script>
