<template>
  <div
    v-if="topLevelAsset.id && rootTopic"
    class="topic-edit"
  >
    <delete-confirmation>
      <template slot="name">
        {{ deletableAsset.title }}
      </template>
      <template slot="body">
        <div v-if="asset.id == topLevelAsset.id">
          {{ appsCount }} apps use this topic.
        </div>
        <div>
          {{ descendantsCount }} descendants will also be deleted.
        </div>
      </template>
    </delete-confirmation>

    <save-modal @save="overwrite" :asset="topics.filter(k => k.id == topLevelAsset.id)[0]" v-if="asset.id != undefined" />

    <asset-associations-modal />

    <div class="asset-description-container">
      <div class="asset-description-inner">
        <asset-description :asset="rootTopic" :editing="editingAssetDescription" @set-editing="setEditingAssetDescription" />
      </div>
    </div>

    <div
      v-if="rootTopic.duplicate_content"
      class="duplicate-warning-container"
    >
      <duplicate-warning />
    </div>

    <div class="toggle-container" v-if="topLevelAsset.topic_type == 'Standard'">
      <preview-toggle @edit="mode = 'edit'" @preview="mode = 'preview'" />
    </div>

    <div class="topic-ids">
      <div>Draft Root ID: {{ asset.draft_root_id }}</div>
      <div>Live Root ID: {{ asset.live_root_id }}</div>
    </div>

    <app-permission-alert v-if="!hasAppPermission" />

    <div v-if="mode == 'edit'">
      <div class="hierarchy-container">
        <transition name="slide-fade">
          <div v-if="hierarchyVisible">
            <hierarchy-top-nav />

            <div class="hierarchy-inner-container">
              <hierarchy-column :parent-asset="null" />
              <hierarchy-column
                v-for="(pathAsset, i) in hierarchyPath"
                :key="'column' + i"
                :parent-asset="pathAsset"
              />
            </div>
          </div>
        </transition>
        <div
          :class="{ folddown : !hierarchyVisible, fold: true }"
          @click="toggleHierarchy()"
        >
          <div v-if="hierarchyVisible">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="66"
              height="25"
              viewBox="0 0 66 25"
            >
              <g
                fill="none"
                fill-rule="evenodd"
              >
                <rect
                  width="66"
                  height="25"
                  fill="#8A9399"
                  rx="4"
                />
                <path
                  stroke="#FFF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2.333"
                  d="M43 16L33 8l-10 8"
                />
              </g>
            </svg>
          </div>
          <transition name="slow-fade">
            <div v-if="!hierarchyVisible">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="66"
                height="25"
                viewBox="0 0 66 25"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect
                    width="66"
                    height="25"
                    fill="#E7E9EB"
                    rx="4"
                    transform="matrix(1 0 0 -1 0 25)"
                  />
                  <path
                    stroke="#FFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.333"
                    d="M42 9l-9 7-9-7"
                  />
                </g>
              </svg>
            </div>
          </transition>
        </div>
      </div>

      <div class="topic-preview-container">
        <div
          class="topic-preview-section"
          @click="lightbox()"
        >
          <preview
            :key="topics.filter(k => k.id == topLevelAsset.id)[0].id"
            :topicId="topLevelAsset.id"
          />
        </div>
      </div>
    </div>
    <div v-if="mode == 'preview'">
      <mobile-preview />
    </div>


    <div class="asset-association-container">
      <div class="container-row">
        <div class="container-content">
          <product-association
            v-if="currentUser.permissions.can_read_topics_product_associations && draftMode"
            :asset="rootTopic"
            class="product-association-container"
          />
          <div
            v-if="draftMode"
            class="topic-selection"
          >
            <label>Topic Type</label>
            <select
              v-model="rootTopic.topic_type"
              class="topic-type"
            >
              <option value="null">
                - Select Topic Type -
              </option>
              <option
                v-for="(topicType, i) in topicTypes"
                :key="i"
                :value="topicType"
                :topicType="topicType"
              >
                {{ topicType }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <metadata :passedAsset='rootTopic'>
      <template v-slot:left>
        <div class="module-section section">
          <div class="content-notes">
            <label>Notes</label>
            <textarea v-model="rootTopic.content_notes" class="hlt-textarea" />
          </div>
        </div>
        <div class="module-section section">
          <div class="content-notes">
            <label>Featured Attachment ID - Deprecated</label>
            <input v-model="rootTopic.featured_attachment_id" disabled="true" class="hlt-input" />
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div class="module-section section">
          <asset-associations-table
            :asset-associations="tableAssociations"
            class="topic-table"
          />
        </div>

        <div class="module-section section">
          <label>Versions</label>
          <versions-table
            :asset="rootTopic"
            asset-name="topic"
          />
        </div>
      </template>
    </metadata>

    <edit-footer>
      <template v-slot:left>
        <div class="left">
          <button
            class="hlt-button default draft"
            :class="{ active : draftMode }"
            @click="toggleDraft(true)"
          >
            Draft
          </button>
          <button
            class="hlt-button default live"
            :class="{ active : !draftMode }"
            @click="toggleDraft(false)"
          >
            Live
          </button>
        </div>
      </template>
      <template :asset='rootAsset' v-slot:center>
        <div class="center">
          <div v-if="draftMode">
            <div
              v-if="canWriteToAsset"
              class="center-actions"
            >
              <select
                v-model="rootTopic.state"
                class="hlt-select"
              >
                <option>Select State</option>
                <option
                  v-for="(state, i) in states"
                  :key="i"
                  :state="state"
                >
                  {{ state }}
                </option>
              </select>

              <button
                v-if="!saveDisabled"
                class="hlt-button primary save-button"
                :class="{ grey: !draftMode }"
                @click="draftMode && save()"
              >
                Save Changes
              </button>
              <button
                v-else
                class="hlt-button primary disabled save-button"
              >
                <spinner /> Save Changes
              </button>
            </div>
            <div v-else>
              <button
                v-if="currentUser.permissions.can_write_change_requests"
                class="hlt-button primary"
                @click="nextAssetAssignment('complete')"
              >
                Complete
              </button>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div class="right">
          <div v-if="!canWriteToAsset && currentUser.permissions.can_write_change_requests && draftMode">
            <button
              class="hlt-button secondary"
              @click="nextAssetAssignment('skip')"
            >
              Skip
            </button>
          </div>
          <div v-if="canWriteToAsset && canOverwriteLive && draftMode">
            <button
              class="hlt-button secondary overwrite"
              @click="overwriteLive()"
            >
              Overwrite Live
            </button>
          </div>
        </div>
      </template>
    </edit-footer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import HierarchyTopNav from './HierarchyTopNav'
import HierarchyColumn from './HierarchyColumn'
import ProductAssociation from '../shared/ProductAssociation'
import railsRoutes from '../../../store/rails-routes'
import Preview from './Preview'
import Spinner from '../shared/Spinner'
import axios from 'axios'
import DeleteConfirmation from '../shared/DeleteConfirmation'
import AppPermissionAlert from '../shared/AppPermissionAlert'
import AssetAssociationsModal from '../shared/AssetAssociationsModal.vue'
import EditFooter from '../shared/page_components/EditFooter'
import assetAssignment from '../../../mixins/asset_assignment.js'
import DuplicateWarning from '../shared/DuplicateWarning'
import Metadata from '../shared/Metadata'
import AssetAssociationsTable from '../shared/AssetAssociationsTable'
import VersionsTable from '../shared/VersionsTable'
import AssetDescription from '../shared/AssetDescription'
import AssetDescriptionMixin from '../../../mixins/asset-description'
import SaveModal from '../shared/SaveModal.vue'
import PreviewToggle from '../shared/PreviewToggle.vue'
import MobilePreview from './MobilePreview.vue'

export default {
  name: 'Edit',
  components: {
    HierarchyColumn,
    ProductAssociation,
    DeleteConfirmation,
    Preview,
    Spinner,
    AppPermissionAlert,
    AssetAssociationsModal,
    HierarchyTopNav,
    EditFooter,
    DuplicateWarning,
    Metadata,
    AssetAssociationsTable,
    VersionsTable,
    AssetDescription,
    SaveModal,
    PreviewToggle,
    MobilePreview
  },
  mixins: [
    assetAssignment,
    AssetDescriptionMixin
  ],
  props: {
    topicId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      content: '<h1>Html For Editor</h1>',
      topicTypes: [
        'Standard',
        'Reference',
        'Case Study',
        'Asset Tray'
      ],
      saveDisabled: false,
      hierarchyVisible: true,
      displayLightbox: false,
      displayAssetId: true,
      overwritingAlready: false,
      availableContexts: [
        'Primary Action'
      ],
      mode: 'edit'
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'topLevelAsset',
      'hierarchyPath',
      'topics',
      'deleteAsset',
      'deletableAsset',
      'draftMode',
      'canWriteToAsset',
      'hasAppPermission',
      'currentUser',
      'modifiedAssets'
    ]),
    rootTopic () {
      if (this.topics.length > 0) {
        return this.topics.filter(k => k.id == this.topLevelAsset.id)[0]
      } else {
        return null
      }
    },
    states () {
      return this.$assetStates
    },
    appsCount () {
      if (this.deletableAsset.id) {
        return this.deletableAsset.root_apps.length
      } else {
        return 'unknown'
      }
    },
    descendantsCount () {
      if (this.deletableAsset.id) {
        return this.deletableAsset.descendants_count
      } else {
        return 'unknown'
      }
    },
    canOverwriteLive () {
      return this.currentUser.permissions.can_overwrite_live_topics
    },
    tableAssociations () {
      if (this.topics && this.topics.length > 0) {
        const assets = this.topics.filter(k => !k.deleted && !k.live_layer)
        let assetAssociations = []
        if (assets) {
          assets.map(h => {
            assetAssociations = assetAssociations.concat(h.asset_associations.filter(m => m && !m.deleted))
          })
        }
        return assetAssociations
      } else {
        return []
      }
    }
  },
  watch: {
    deleteAsset (val, oldVal) {
      if (val == true) {
        if (this.deletableAsset.id && !this.deletableAsset.live_layer) {
          this.delete({ record: this.deletableAsset, url: railsRoutes.api_path({ type: 'topics' }) }).then(() => {
            this.$notify({ title: 'Topic Deleted', type: 'success' })

            if (this.deletableAsset.id == this.topLevelAsset.id) {
              if (this.$router) {
                this.$router.push({ path: railsRoutes.cms_path({ type: 'topics' }) })
              }
            } else {
              if (this.$router) {
                var parent = this.topics.filter(k => k.id == this.deletableAsset.parent_id)[0]
                this.removeTopic({ record: this.deletableAsset })

                this.selectAsset({ asset: parent })
                this.$router.push({ path: railsRoutes.cms_path({ type: 'topics', id: parent.id }) })
              }
            }
            if (this.$modal) {
              this.setDeleteAsset({ value: false })
              this.resetDeletableAsset()
              this.$modal.hide('delete-modal')
            }
          })
        }
      }
    }
  },
  created () {
    this.setAssetType({ assetType: 'topic' })
    this.loadAsset()
  },
  methods: {
    ...mapActions([
      'edit',
      'apiTopicHierachyById',
      'buildHierarchyFromPath',
      'batchUpdate',
      'delete',
      'selectTopic',
      'deselectTopics',
      'addTopic',
      'setTopics',
      'selectAsset'
    ]),

    ...mapMutations([
      'setAsset',
      'setTopLevelAsset',
      'removeTopic',
      'setAssetType',
      'setDeleteAsset',
      'setDeletableAsset',
      'resetDeletableAsset',
      'toggleDraftMode',
      'setErrors',
      'resetTopics',
      'initTopics',
      'setImmutableTopics'
    ]),
    loadAsset () {
      if (!this.topics.filter(k => k.id == this.topicId)[0]) {
        this.apiTopicHierachyById({ topicId: this.topicId }).then(response => {
          this.refreshHierarchy(response.data.records)
        })
      }
    },
    refreshHierarchy (records) {
      // Set product association and asset association ids returned from the api
      const newTopics = records
      var routeRecord = newTopics.filter(k => k.id === parseInt(this.topicId))[0]
      var rootRecord = newTopics.filter(k => k.id === routeRecord.root_id)[0]

      this.setTopLevelAsset({ asset: rootRecord })
      this.setTopics({ assets: newTopics }).then(() => {
        this.selectAsset({ asset: routeRecord })
        if (routeRecord.live_layer) {
          this.toggleDraftMode()
        }
      })
    },
    lightbox () {
      this.displayLightbox = !this.displayLightbox
    },
    toggleHierarchy () {
      this.hierarchyVisible = !this.hierarchyVisible
    },
    toggleDraft (val) {
      let rootId = null
      if (val) {
        rootId = this.asset.draft_root_id
      } else {
        rootId = this.asset.live_root_id
      }
      if (rootId) {
        this.$router.push({ name: 'topic', params: { id: rootId } })
        this.$router.go()
      }
    },
    save () {
      if (!this.saveDisabled) {
        this.saveDisabled = true
        if (this.asset.id) {
          this.batchUpdate({ assets: this.modifiedAssets, url: railsRoutes.api_path({ type: 'topics' }) }).then(response => {
            this.refreshHierarchy(response.data.records)
            this.saveDisabled = false
            this.$notify({ title: 'Topic Saved', type: 'success' })
            this.setEditingAssetDescription(false)
            this.setErrors({ errors: {} })
          }).catch(error => {
            console.error('Error saving topic', error)
            this.saveDisabled = false
            this.$notify({ title: 'Error Saving Topic', type: 'error' })
            this.setErrors({ errors: error.response.data.errors })
          })
        } else {
          // This should never happen
          this.saveDisabled = false
          this.$notify({ title: 'Error Saving Topic', type: 'error' })
        }
      }
    },
    overwriteLive () {
      this.$modal.show('save-modal')
    },
    overwrite () {
      if (this.canWriteToAsset && this.draftMode && this.canOverwriteLive && !this.overwritingAlready) {
        this.overwritingAlready = true

        axios.put(railsRoutes.api_path({
          type: 'topics',
          action: 'overwrite_live',
          id: this.topLevelAsset.id
        }), {
          topics: this.modifiedAssets
        }).then(response => {
          this.overwritingAlready = false
          if (response.data.records.length > 0) {
            for (const i in response.data.records) {
              this.addTopic({ record: response.data.records[i] })
              if (response.data.records[i].id == this.topLevelAsset.id) {
                var rootRecord = this.topics.filter(k => k.id == this.topLevelAsset.id)[0]
                this.setTopLevelAsset({ asset: rootRecord })
              }
            }
          }
          this.$notify({ title: 'Live Layer Overwriten', type: 'success' })
          if (this.$modal) {
            this.$modal.hide('overwrite-live-modal')
          }
        }).catch(error => {
          this.overwritingAlready = false
          console.error('Error overwriting live', error)
          this.$notify({ title: 'Error Overwriting Live Layer', type: 'error' })
          if (this.$modal) {
            this.$modal.hide('overwrite-live-modal')
          }
        })
      }
    },
    deleteRecord () {
      if (this.$modal) {
        this.setDeletableAsset({ asset: this.topLevelAsset })
        this.$modal.show('delete-modal')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.toggle-container {
  width: 97%;
  display: flex;
  justify-content: right;
}

.duplicate-warning-container {
  display: flex;
  justify-content: center;
  margin: 10px 30px;
}
.center-actions {
  display: flex;
  align-items: flex-end;
}
.topic-ids {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  color: $blue-2;
  font-weight: 700;
  margin-left: 30px;
}

.asset-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .asset-description-inner {
    width: 100%;
  }
}

.topic-preview-container {
  width: 80%;
  max-width: 960px;
  margin: 0 auto;
  margin-bottom: 100px;
  .product-association-container {
    clear: both;
    padding-top: 32px;
    margin-top: 100px;
    margin-bottom: 30px;
  }

  .primary-action-button {
    background: $orange-1;
    border: none;
    margin: 5px 0;
  }
}
.asset-association-container {
  margin: auto;
  border-top: 4px solid $grey-4;
  width: 100%;
  display: flex;
  height: auto;
  padding: 30px 60px;
  align-items: center;
  .product-association-container {
    ::v-deep .label-heading {
      flex: 1;
    }
  }
  .container-row {
    flex: 1;
    .container-content {
      max-width: 1200px;
      margin: auto;
    }
  }
  .left {
    margin: 0;
    padding: 30px 60px;
    width: 70%;
    float: left;
  }
  .right {
    margin: 0;
    padding-right: 100px;
    background: $grey-4;
    width: 30%;
    float: left;
    min-height: 100%;
  }
  .topic-type {
    padding: 10px;
    height: 100%;
    width: 25%;
    margin: 0px;
    border: 2px solid $grey-3;
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 25px;
  }
}
.topic-selection {
  display: block;
  padding: 20px 0;
  label {
    font-weight: 700;
    margin-bottom: .25rem;
  }
}
.hierarchy-container {
  width: 95%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  min-height: 30px;

  .hierarchy-inner-container {
    border: 4px solid $grey-3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 250px;
    display: flex;
    overflow-x: scroll;
  }

  .slow-fade-enter-active {
    animation: slow-fade 1s ease both;
  }

  @keyframes slow-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .slide-fade-enter-active {
    animation: scale-down-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  .slide-fade-leave-active {
    animation: scale-up-ver-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    height: 0px;
  }

  @keyframes scale-up-ver-top {
    0% {
              transform: scaleY(1);
              transform-origin: 100% 0%;
    }
    100% {
              transform: scaleY(0.3);
              transform-origin: 100% 0%;
    }
  }

  @keyframes scale-down-ver-top {
    0% {
              transform: scaleY(0.3);
              transform-origin: 100% 0%;
    }
    100% {
              transform: scaleY(1);
              transform-origin: 100% 0%;
    }
  }

  .fold {
    height: 30px;
    color: white;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    bottom: -30px;
    left: 25%;
    width: 50%;
  }

  .folddown {
    border-bottom: 4px solid $grey-3;
    top: -30px;
    div {
      position: absolute;
      top: 15px;
      width: 100%;
    }
  }
}
.metadata-section {
  margin-top: 20px;
  background: $grey-4;
  padding: 30px 60px;
  .metadata-container {
    max-width: 1200px;
    margin: auto;
    display: flex;
  }
}

.draft-live-footer {
  .hlt-select {
    margin: 0 30px;
    width: 300px;
  }
}

</style>
