<template>
  <select
    v-model="$attrs.value.asset_type"
    class="hlt-select"
  >
    <option :value="null">
      Select Type
    </option>
    <option
      v-for="(type, index) in assetTypes"
      :key="'asset-type-' + index"
      :value="availableAssetTypes[type]"
    >
      {{ type }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'AssetSelectType',
  props: {
    assetTypes: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      availableAssetTypes: {
        'Attachment': 'Attachment',
        'AttachmentCategory': 'AttachmentCategory',
        'Category': 'Category',
        'CategoryType': 'CategoryType',
        'Deck': 'Deck',
        'FlashcardCategory': 'FlashcardCategory',
        'Grouping': 'Grouping',
        'Item': 'V3Flashcard',
        'Learning Module': 'LearningModule',
        'ProgressGrouping': 'ProgressGrouping',
        'TermCategory': 'TermCategory',
        'Topic': 'Topic',
        'TopicCategory': 'TopicCategory',
        'WuxTestSimulations': 'WuxTestSimulations'
      }
    }
  }

}
</script>
