<template>
  <div
    v-if="assets"
    :id="'hierarchy-column-' + parentAssetId()"
    class="hierarchy-column"
  >
    <draggable
      v-model="assets"
      group="assets"
      class="draggable"
      @change="draggedAsset($event)"
    >
      <transition-group :key="transitionGroupKey">
        <div
          v-for="localAsset in assets"
          :id="'hierarchy-asset-' + localAsset.id"
          :key="'row-' + localAsset.id"
          :class="cssClass(localAsset)"
          class="hierarchy-asset"
        >
          <div
            class="hierarchy-item"
            @click="selectAsset({ asset: localAsset })"
          >
            <div
              class="hierarchy-title"
              :title="localAsset.title"
              v-html="truncatedTitle(localAsset)"
            />
          </div>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Draggable from 'vuedraggable'

export default {
  name: 'Hierarchy',
  components: {
    Draggable
  },
  props: ['parentAsset'],
  data () {
    return {
      showMenu: {}
    }
  },
  computed: {
    transitionGroupKey () {
      if (this.parentAsset) {
        return `column-${this.parentAsset.id}`
      } else {
        return 'column-root'
      }
    },
    assets: {
      get () {
        if (this.parentAsset == null) {
          if (!this.topLevelAsset.id) {
            return []
          } else {
            return this.topics.filter(k => k.id == this.topLevelAsset.id)
          }
        } else {
          return this.subTopics(this.parentAsset.id)
            .filter(k => !k.deleted)
            .filter(k => k.live_layer != this.draftMode)
        }
      },
      set (value) {
        if (this.draftMode) {
          let changedAsset = null // Used for dragging between columns, draggedAsset doesn't work right between columns
          for (var i in value) {
            if (this.parentAsset.id != value[i].id) { // Dont allow parent assets to become children of themselves
              value[i].order = parseInt(i)

              if (value[i].path.split('.').slice(0, -1).join('.') != this.parentAsset.path) { // Child was dragged between columns
                value[i].parent_path = this.parentAsset.path
                changedAsset = value[i]
              }

              value[i].parent_id = this.parentAsset.id
              value[i].selected = false
              this.addModifiedAssetId({ id: value[i].id })
              this.updateTopicOnly({ asset: value[i] })
            }
          }

          if (changedAsset) {
            this.selectAsset({ asset: changedAsset })
          }
        }
      }
    },
    ...mapGetters([
      'topics',
      'subTopics',
      'topLevelAsset',
      'asset',
      'draftMode'
    ])
  },
  updated () {
    this.scrollHierarchy()
  },
  mounted () {
    this.scrollHierarchy()
  },
  methods: {
    draggedAsset (event) {
      if (event.moved) {
        this.selectAsset({ asset: event.moved.element })
      }
    },
    ...mapMutations([
      'updateTopicOnly',
      'addModifiedAssetId',
      'syncTopicsByParentId'
    ]),
    ...mapActions([
      'selectAsset'
    ]),
    scrollHierarchy () {
      if (this.asset.path) {
        let horizontalScroll = document.querySelector('.hierarchy-inner-container')
        if (horizontalScroll) {
          horizontalScroll.scrollLeft = (this.asset.path.split('.').length + 1) * 480
        }

        let verticalScroll = document.getElementById(`hierarchy-column-${this.parentAssetId()}`)
        if (verticalScroll) {
          let selectedAsset = this.assets.filter(x => x.selected)[0]

          verticalScroll.scrollTop = (this.assets.indexOf(selectedAsset) - 1) * 44
        }
      }
    },
    truncatedTitle (localAsset) {
      if (localAsset.title && (localAsset.title.length > 55)) {
        return localAsset.title.substring(0, 55) + '...'
      } else {
        return localAsset.title
      }
    },
    cssClass (localAsset) {
      if (localAsset.selected) {
        if (localAsset.id == this.asset.id) {
          return 'active'
        } else {
          return 'selected'
        }
      } else {
        return ''
      }
    },
    parentAssetId () {
      if (this.parentAsset == null) {
        return 0
      } else {
        return this.parentAsset.id
      }
    },
    ...mapActions(['edit'])
  }
}
</script>
<style lang="scss" scoped>
.hierarchy-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 6px;
}

.hierarchy-item {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.hierarchy-column {
  height: 100%;
  min-width: 480px;
  max-width: 480px;
  overflow-y: scroll;
  background-color: #E2E2E2;

  .draggable {
    height: 100%;
    background-color: white;
    margin-right: 10px;
  }

  &.active {
    background-color: #EAF7FA;
  }

  .hierarchy-asset {
    padding: 4px 6px;
    min-height: 44px;
    border-bottom: 2px solid $white-1;
    background-color: white;

    svg {
      margin: auto 8px auto 4px;
    }

    &.active {
      background-color: #45B0D9;
      color: white;

      svg g {
        stroke: white;
      }
    }

    &.selected {
      background-color: rgba($blue-4, 0.2);
      color: #2e414d;

      svg g {
        stroke: white;
      }
    }
  }
}
</style>
