<template>
  <div
    class="hierarchy-item"
    @click="selectGroup()"
  >
    <folder-svg />
    <div
      class="hierarchy-title"
      :title="navigationGroup.name"
      v-html="truncatedTitle()"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import axios from 'axios'
import FolderSvg from '@/assets/images/icons/folder.svg'

export default {
  components: {
    FolderSvg
  },
  props: [
    'row',
    'columnIndex'
  ],
  data () {
    return {
      showMenu: {}
    }
  },
  computed: {
    navigationGroup () {
      return this.row.rowObject
    }
  },
  methods: {
    truncatedTitle () {
      if (this.navigationGroup.name && (this.navigationGroup.name.length > 55)) {
        return this.navigationGroup.name.substring(0, 55) + '...'
      } else {
        return this.navigationGroup.name
      }
    },
    selectGroup () {
      this.$store.commit('appHierarchy/selectRow', {
        columnIndex: this.columnIndex,
        row: this.row
      })

      // EDIT ASSET
      this.edit(this.navigationGroup)

      // SET ASSET TYPE
      this.setAssetType({ assetType: 'navigation_group' })

      // 1. query the api for nav items
      // 2. push a new row into appHierarchyColumns
      axios.get(railsRoutes.api_path({ type: 'navigation_items' }), {
        params: { 'navigation_group_id': this.navigationGroup.id }
      }).then(response => {
        this.$store.commit('appHierarchy/addHierarchyColumn', {
          parent: this.navigationGroup,
          parentType: 'NavigationGroup',
          rows: response.data.records.map(x => ({ rowType: 'NavigationItem', rowObject: x })),
          columnIndex: this.columnIndex
        })
      })
    },
    ...mapMutations([
      'setAssetType'
    ]),
    ...mapActions([
      'edit'
    ])
  }
}
</script>
