<template>
  <div class="review-container">
    <div
      :id="'deck-review-' + index"
      class="review-content-box"
    >
      <div class="review-content-section">
        <div class="review-asset-section">
          <div class="column">
            <label class="review-label weight-600">Rating</label>
            <input
              v-model="review.rating"
              class="review-input hlt-input"
              type="text"
              required
              :class="{'error': review.errors && (review.errors.rating || review.errors.asset)}"
              placeholder="Rating"
              :disabled="!draftMode"
            >
            <error-message
              v-if="review.errors && review.errors.rating"
              message="must have a valid rating"
            />
          </div>
          <div class="column">
            <label class="review-label weight-600">Review Date</label>
            <div
              class="selected-date"
              @click="toggleEditingReviewDate"
            >
              <input
                v-model="review.review_date"
                class="review-input hlt-input"
                type="text"
                required
                :class="{'error': review.errors && review.errors.review_date}"
                placeholder="MM/DD/YYYY"
                :disabled="!draftMode"
                @change="resetCalendar"
              >
            </div>
            <FunctionalCalendar
              v-show="editingReviewDate"
              ref="reviewDate"
              v-model="selectedReviewDate"
              :configs="calendarConfigs"
              @dayClicked="toggleEditingReviewDate"
            />

            <error-message
              v-if="review.errors && review.review_date"
              message="must have a valid date"
            />
          </div>
        </div>
        <div class="review-title review-area">
          <label class="review-label weight-600">Title</label>
          <input
            v-model="review.title"
            class="review-input hlt-input"
            type="text"
            required
            :class="{'error': review.errors && review.errors.title }"
            placeholder="Write review title"
            :disabled="!draftMode"
          >
          <error-message
            v-if="review.errors && review.errors.title"
            message="can't be blank"
          />
        </div>
        <div class="review-description review-area">
          <div
            class="review-container description-area"
            @click="e => moduleToggle(e, 'reviewDescription', 'review-description')"
          >
            <div
              v-if="!editing['reviewDescription']"
              class="review-edit-html-description"
            >
              <label
                class="container-label weight-600"
                for="c-description"
              >Review Text</label>
              <div
                :class="[{ 'empty-content': emptyContent(review.description) , 'disabled': !draftMode }, 'container-textarea']"
                name="c-description"
                v-html="review.review_text || 'Write review text'"
              />
            </div>

            <div
              v-if="editing['reviewDescription']"
              class="review-edit-active description-active"
            >
              <label
                class="container-label weight-600"
                for="description"
              >Review Text</label>
              <tinymce-editor
                ref="review-description"
                v-model="review.review_text"
                v-on-clickaway="e => moduleToggle(e, 'reviewDescription', 'review-description')"
                :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                class="review-tinymce"
                name="title"
                @onInit="setCaretPosition"
              />
            </div>
          </div>
        </div>
        <div class="review-title review-area">
          <label class="review-label weight-600">By Line</label>
          <input
            v-model="review.by_line"
            class="review-input hlt-input"
            type="text"
            required
            :class="{'error': review.errors && review.errors.title }"
            placeholder="Write review title"
            :disabled="!draftMode"
          >
          <error-message
            v-if="review.errors && review.errors.title"
            message="can't be blank"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TinymceEditor from '@tinymce/tinymce-vue'
import tinymce from '../../../mixins/tinymce'
import { mixin as clickaway } from 'vue-clickaway2'
import { mapGetters } from 'vuex'

export default {
  components: {
    TinymceEditor
  },
  mixins: [
    clickaway,
    tinymce
  ],
  props: {
    review: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    },
    draftMode: {
      type: Boolean
    }
  },
  data () {
    return {
      editing: {
        reviewDescription: false
      },
      calendarData: {},
      calendarConfigs: {
        sundayStart: true,
        dateFormat: 'yyyy-mm-dd',
        isDatePicker: true,
        isDateRange: false,
        isMultiple: false,
        calendarsCount: 1,
        withTimePicker: false,
        changeMonthFunction: true
      },
      editingReviewDate: false
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'errors'
    ]),
    selectedReviewDate: {
      get () {
        return this.calendarData
      },
      set (value) {
        if (value.selectedDate !== false) {
          this.calendarData = value
          this.review.review_date = value.selectedDate
        }
      }
    }
  },
  mounted () {
    if (this.$refs.reviewDate) {
      this.$refs.reviewDate.ChooseDate?.(this.review.review_date)
    }
  },
  methods: {
    saveToParent (value) {
      this.$emit('saveDisabled', value)
    },
    moduleToggle (event, prop, reference) {
      if (this.draftMode) {
        if (!event) return
        if (event.target.className.includes('mce') || event.target.id.includes('mce')) {
          return
        }
        Object.keys(this.editing).forEach(key => {
          this.editing[key] = prop == key ? !this.editing[prop] : false
        })
        this.setCharPosition(event, reference)
      }
    },
    emptyContent (content) {
      return content === null || content === ''
    },
    resetCalendar () {
      this.calendarData.currentDate = this.review.review_date
      this.calendarData.selectedDate = this.review.review_date
      this.editingReviewDate = false
    },
    toggleEditingReviewDate () {
      this.editingReviewDate = !this.editingReviewDate
    }
  }
}
</script>

<style lang="scss" scoped>
  .review-content-box {
    .review-content-section {
      padding: 0;
      .review-asset-section {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        height: 100%;
        .review-asset {
          margin-bottom: 1rem;
          .hlt-input, .hlt-select {
            width: 200px;
            margin-bottom: 0;
            @media (max-width: 1500px) {
              margin-right: 15px;
            }
          }
        }
      }
      .container-textarea {
        &.disabled {
          background: $grey-4;
        }
      }
    }
  }
  .vfc-popover-container {
    position: absolute;
    z-index: 900;
  }
.column {
  padding-left: 0px;
}

</style>
