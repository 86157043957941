<template>
  <div class="items-edit-container">
    <div class="items-edit-content">
      <div class="main-container">
        <duplicate-warning v-if="asset.duplicate_question" class="duplicate-item-notice" />
        <div class="toggle-container">
          <preview-toggle @edit="mode = 'edit'" @preview="mode = 'preview'" v-if="asset.type == 'MultipleChoice'" />
        </div>
        <div v-if="mode == 'edit'">
          <item-skeleton :asset="asset" />
        </div>
        <div v-if="mode == 'preview'">
          <multiple-choice-preview v-if="asset.type == 'MultipleChoice'" :asset="asset" />
        </div>
      </div>

      <div class="product-associations-container">
        <div class="rule"></div>
        <div class="product-content">
          <product-association
            v-if="currentUser.permissions.can_read_items_product_associations"
            :asset="asset"
            class="product-association-container"
          />
        </div>
      </div>

      <div
        v-if="asset.latest_prompt_runner"
        class="prompt-runners-container"
      >
        <div class="rule"></div>
        <div class="product-content">
          <prompt-runner
            :asset="asset"
            class="prompt-runner-container"
          />
        </div>
      </div>


      <metadata>
        <template v-slot:left>
          <div class="notes-section section">
            <label>Notes</label>
            <textarea
              v-model="asset.content_notes"
              :disabled="!canWriteToAsset"
              class="hlt-textarea"
              rows="5"
            />
          </div>
          <div class="reference-section section">
            <label>Reference</label>
            <textarea
              v-model="asset.content_reference"
              :disabled="!canWriteToAsset"
              class="hlt-textarea"
              rows="5"
            />
          </div>
          <div class="delta-section section">
            <label>Seconds Per Question Delta</label>
            <input
              v-model="asset.seconds_per_question_delta"
              type="number"
              :disabled="!canWriteToAsset"
              class="hlt-input"
            >
          </div>
        </template>
        <template v-slot:right>
          <div>
            <asset-associations-table :asset-associations="assetAssociations" />
          </div>
          <div class="user-data-tables">
            <label>User Data</label>
            <div
              v-for="(data, index) in asset.app_flashcard_meta_data"
              :key="index"
              :data="data"
              class="asset-pb-data"
            >
              <div class="app-label">
                <div class="app-name">
                  {{ asset.apps.filter(k => k.id == data.app_id)[0].internal_name }}
                </div>
                <div class="reset-app-stats" @click="resetStats(data.app_id)">
                  Reset Stats
                </div>
              </div>
              <table aria-describedby="pbTable" class="hlt-table">
                <thead>
                  <tr>
                    <th>P</th>
                    <th>PB</th>
                    <th>MC</th>
                    <th>MT</th>
                    <th>STD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="p">
                      {{ data.p | numberFix }}
                    </td>
                    <td class="pb">
                      {{ data.pb | numberFix }}
                    </td>
                    <td class="mc">
                      {{ data.mc | numberFix }}
                    </td>
                    <td class="mt">
                      {{ data.mt | numberFix }}
                    </td>
                    <td class="std">
                      {{ data.std | numberFix }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="versions-meta-section">
            <label class="weight-600">Versions</label>
              <versions-table
                :asset="asset"
                asset-name="item"
              />
          </div>
        </template>
      </metadata>
    </div>

    <edit-footer>
      <template v-slot:left>
        <div class="left">

        </div>
      </template>
      <template v-slot:center>
        <div class="center">
          <div v-if="canWriteToAsset">
            <button
              v-if="!saving"
              class="hlt-button primary save-button"
              @click="showSaveModal()"
            >
              Save Changes
            </button>
            <button
              v-else
              class="hlt-button primary disabled save-button"
            >
              <spinner /> Save Changes
            </button>
          </div>
        </div>
      </template>
    </edit-footer>
    <asset-associations-modal />
    <save-modal :asset="asset" @save='save' />
  </div>
</template>

<script>
import AssetAssociationsModal from '../shared/AssetAssociationsModal.vue';
import AssetAssociationsTable from '../shared/AssetAssociationsTable.vue';
import DuplicateWarning from '../shared/DuplicateWarning.vue';
import EditFooter from '../shared/page_components/EditFooter.vue';
import ItemSkeleton from './ItemSkeleton.vue';
import Metadata from '../shared/Metadata.vue';
import MultipleChoicePreview from './mobile_previews/MultipleChoicePreview.vue';
import PreviewToggle from '../shared/PreviewToggle.vue';
import ProductAssociation from '../shared/ProductAssociation.vue';
import PromptRunner from '../shared/PromptRunner.vue';
import SaveModal from '../shared/SaveModal.vue';
import Spinner from '../shared/Spinner.vue';
import VersionsTable from '../shared/VersionsTable.vue';
import assetAssignment from '../../../mixins/asset_assignment.js';
import axios from 'axios';
import railsRoutes from '../../../store/rails-routes';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'Edit',
  components: {
    AssetAssociationsModal,
    AssetAssociationsTable,
    DuplicateWarning,
    EditFooter,
    ItemSkeleton,
    Metadata,
    MultipleChoicePreview,
    PreviewToggle,
    ProductAssociation,
    PromptRunner,
    SaveModal,
    Spinner,
    VersionsTable,
  },
  filters: {
    numberFix (num) {
      if (!num) return
      return num.toFixed(2)
    }
  },
  mixins: [
    assetAssignment
  ],
  props: [
    'itemId'
  ],
  data () {
    return {
      saving: false,
      categories: [],
      mode: 'edit'
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset',
      'currentUser',
      'canWriteToAsset'
    ]),
    assetAssociations () {
      if (this.asset && this.asset.asset_associations) {
        return this.asset.asset_associations.filter(k => !k.deleted)
      } else {
        return []
      }
    },
    appsCount () {
      if (this.asset.id) {
        return this.asset.apps.length
      } else {
        return 'unknown'
      }
    },
    iconUrl () {
      return this.asset.icon_url
    }
  },
  watch: {
    deleteAsset (val, oldVal) {
      if (val == true) {
        if (this.asset.id) {
          this.delete({ record: this.asset, url: railsRoutes.api_path({ type: 'items' }) }).then(response => {
            if (this.$router) {
              this.$router.push({ path: railsRoutes.cms_path({ type: 'items' }) })
            }
          })
        }
      }
    }
  },
  mounted () {
    this.$cable.subscribe({
      channel: 'CmsAssetNotificationsChannel',
      asset: `item${this.itemId}`
    })
  },

  created () {
    this.setAssignment(null)
    this.setNextAssignmentId(null)
    this.setAssetType({ assetType: 'item' })
    this.setDraftMode(true) // resetting so asset associations work when we route here from a qid link
    this.apiItemsById({ itemId: this.itemId }).then(response => {
      var record = response.data.records[0]
      this.edit(record)
    })
  },
  methods: {
    ...mapActions([
      'edit',
      'update',
      'delete',
      'apiItemsById'
    ]),
    ...mapMutations([
      'setAssetType',
      'setErrors',
      'resetErrors',
      'setDraftMode',
      'setAssignment',
      'setNextAssignmentId'
    ]),
    showSaveModal () {
      this.$modal.show('save-modal')
    },
    resetStats (app_id) {
      axios.put(railsRoutes.api_path({ type: 'items', id: this.asset.id, action: 'reset_stats' }), { app_id: app_id }).then(response => {
        this.asset.app_flashcard_meta_data = this.asset.app_flashcard_meta_data.filter(x => x.app_id != app_id)
      })
    },
    save () {
      if (this.canWriteToAsset && !this.saving) {
        this.saving = true
        if (this.asset.id) {
          this.update({ asset: this.asset, url: railsRoutes.api_path({ type: 'items' }) }).then(response => {
            this.edit(response.data.records[0])
            this.resetErrors()
            this.saving = false
            this.$notify({ title: 'Item Saved', type: 'success' })
          }).catch(error => {
            this.setErrors({ errors: error.response.data })
            console.error('Error: ', error.response.data)
            this.saving = false
            this.$notify({ title: 'Error saving Item', type: 'error' })
          })
        } else {
          // This should never happen
          this.saving = false
          this.$notify({ title: 'Error saving Item', type: 'error' })
        }
      }
    },
    deleteRecord () {
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    }
  },
  channels: {
    CmsAssetNotificationsChannel: {
      connected () {
        console.log('connected to channel')
      },
      rejected () {
        console.log('rejected from channel')
      },
      received (data) {
        if (data.email != this.currentUser.email) {
          this.$notify({
            title: data.title,
            type: data.type,
            text: data.text,
            duration: data.duration
          })
        }
      },
      disconnected () {
        console.log('disconnected from channel')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-container {
  width: 100%;
  display: flex;
  justify-content: right;
}
</style>

<style lang="scss">
.items-edit-container {
  .items-edit-content {
    display: flex;
    padding: 0px;
    flex-direction: column;
  }
  .main-container {
    width: 100%;
    padding: 1.75rem 12rem;
    margin-bottom: 2rem;
  }
}
.items-container {
  .items-content {
    display: flex;
    background-color: $blue-5;
    padding: 0px;
  }
  .items-content {
    display: flex;
    background-color: $blue-5;
    padding: 0px;
  }
}
.rule {
  height: 1px;
  border-top: 2px solid $grey-4;
  margin-top: 23px;
  margin-bottom: 24px;
}
.product-associations-container {
  width: 100%;
}
.prompt-runners-container {
  width: 100%;
}

.product-content {
  width: 1200px;
  margin: auto;
}

.metadata-section {
  .asset-pb-data {
    .app-label {
      display: flex;
      flex-direction: row;
      div {
        font-size: 10px;
        font-weight: 600;
        color: $space-black;
        margin-bottom: 4px;
      }
      .app-name {
        flex: 1;
      }
      .reset-app-stats {
        flex: 1;
        text-align: right;
        color: $eastern-blue;
        cursor: pointer;
      }
    }
  }
  .delta-section {
    input {
      width: 20px;
      min-width: 80px;
    }
  }
}
</style>
