import railsRoutes from '../store/rails-routes'
import axios from 'axios'

export default {
  methods: {
    nextAssetAssignment (state) {
      axios.put(railsRoutes.api_path({ type: 'asset_assignments', id: this.asset.id }), { state: state }).then(response => {
        this.$notify({ title: 'Asset Assignment Saved', type: 'success' })
        if (response.data.count > 0) {
          this.$router.push({ name: response.data.next_asset_type, params: { id: response.data.next_asset_id } })
        } else {
          this.$router.push({ name: 'dashboard' })
        }
      }).catch(error => {
        console.error(error)
        this.$notify({ title: 'Error Saving Asset Assignment', type: 'error' })
      })
    }
  }
}
