<template>
  <div
    v-if="draftMode && canWriteToAsset"
    class="hierarchy-top-actions"
  >
    <div
      class="white-button"
      @click.stop="createChild(asset)"
    >
      Add Child
    </div>
    <div
      class="white-button"
      @click.stop="createSibling(asset)"
    >
      Add Sibling
    </div>
    <div
      v-if="topLevelAsset.topic_type != 'Asset Tray'"
      class="white-button"
      @click.stop="addNewAssetAssociation(asset)"
    >
      Add Asset Association
    </div>
    <div
      class="white-button"
      @click.stop="showDeleteConfirmation(asset)"
    >
      Delete
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import topicHierarchyMenu from '../../../mixins/topic-hierarchy-menu'

export default {
  mixins: [ topicHierarchyMenu ],
  computed: {
    ...mapGetters([
      'topLevelAsset',
      'asset',
      'draftMode',
      'canWriteToAsset'
    ])
  }
}
</script>

<style lang="scss" scoped>
.hierarchy-top-actions {
  background-color: $grey-3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
  padding-top: 10px;

  .white-button {
    margin-left: 5px;
    margin-right: 5px;
  }
}
</style>
