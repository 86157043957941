<template>
  <div class="decks-edit-container">
    <div class="deck-edit-content">
      <div class="asset-description-container">
        <div class="asset-description-inner">
          <asset-description :asset="asset" :editing="editingAssetDescription" @set-editing="setEditingAssetDescription"/>
        </div>
      </div>
      <div class="main-container">
        <div class="main-section">
          <div class="left-edit">
            <div class="main-deck-container">
              <div class="deck-name-section">
                <div class="left-section">
                  <label class="deck-label weight-600">Deck Name</label>
                  <input
                    v-model="asset.title"
                    class="deck-input hlt-input deck-name-input"
                    type="text"
                    required
                    :class="{'error': errors['title'] }"
                    placeholder="Write deck title"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors['title']"
                    message="Title can't be blank"
                  />
                  <span
                    v-if="!errors['title'] && draftMode"
                    class="helper-text required-title-text"
                  >Required</span>
                </div>
                <div class="right-section">
                  <label class="deck-label weight-600">Icon Attachment ID</label>
                  <input
                    v-model="asset.icon_id"
                    class="deck-input hlt-input"
                    type="text"
                    :class="{'error': errors['icon_id'] }"
                    placeholder="Attachment ID"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors[&quot;icon_id&quot;]"
                    message="Invalid ID"
                  />
                </div>
              </div>
            </div>
            <div class="main-section-container">
              <h5 class="hlt-header h5 weight-600 deck-text-subheader">
                Cards
              </h5>
              <p
                v-if="!sortedCards"
                class="small-text weight-600"
              >
                Get started by adding an asset to the deck
              </p>
              <div
                v-if="sortedCards"
                class="active-deck-sections"
              >
                <div
                  v-for="(card, index) in sortedCards"
                  :key="'card-' + index"
                >
                  <order-box
                    :order-object="card"
                    order-object-type="Card"
                    :order-object-id="card.order"
                    :anchor-key="&quot;card-&quot; + index"
                    :index="index"
                    :draft-mode="draftMode"
                    :is-draggable="false"
                    :group-size="sortedActiveCards.length"
                    :box-styles="boxStyles"
                    @move="moveBox"
                  >
                    <template v-slot:headerTitle>
                      {{ card.title | truncate(40) }}
                    </template>

                    <template v-slot:content>
                      <card
                        :draft-mode="draftMode"
                        :card="card"
                        :index="index"
                      />
                    </template>
                  </order-box>
                </div>
              </div>

              <delete-order-box-modal @orderBoxDeletion="deleteBox" />

              <button
                v-if="draftMode"
                class="hlt-button secondary"
                @click="addNewCard"
              >
                Add Card
              </button>
            </div>
          </div>
          <div class="right-edit">
            <span class="hlt-header h5 weight-600 outline-header">Outline</span>
            <span
              v-if="!sortedActiveCards"
              class="weight-600 small-text"
            >Get started by adding an asset to the deck</span>
            <ul
              v-show="sortedActiveCards.length > 0"
              class="outline-side-list"
            >
              <li
                v-for="(card, i) in sortedActiveCards"
                :key="'deck-card-' + i"
                class="outline-listing"
              >
                <a
                  v-smooth-scroll="{offset: -150, updateHistory: false}"
                  :href="'#card-' + i"
                  class="outline-link"
                >
                  <span class="outline-title weight-600">{{ card.title | truncate(20) }}</span>
                </a>
              </li>
            </ul>
            <button
              v-if="draftMode"
              class="hlt-button secondary outline-add-button"
              @click="addNewCard"
            >
              Add Card
            </button>
          </div>
        </div>
      </div>
      <div class="association-section">
        <product-association
          v-if="currentUser && currentUser.permissions.can_read_decks_product_associations && asset.id && draftMode"
          :asset="asset"
          class="product-association-container"
          :hide-order="true"
          :hide-categories="true"
        />
      </div>

      <save-modal @save="overwrite" :asset="asset" v-if="asset.id != undefined" />

      <metadata>
        <template v-slot:left>
          <div class="section">
            <label
              for="deck-type"
              class="deck-label"
            >Deck Type</label>
            <select
              v-model="asset.deck_type"
              name="deck-type"
              class="hlt-select"
            >
              <option value="Standard">
                Standard
              </option>
              <option value="Episodes">
                Episodes
              </option>
              <option value="Articles">
                Articles
              </option>
              <option value="Duration">
                Duration
              </option>
            </select>
          </div>
        </template>
        <template v-slot:right>
        </template>
      </metadata>

      <edit-footer>
        <template v-slot:left>
          <div class="left">
            <button
              class="hlt-button default draft"
              :class="{ active : draftMode }"
              @click="toggleDraft(true)"
            >
              Draft
            </button>
            <button
              class="hlt-button default live"
              :class="{ active : !draftMode }"
              @click="toggleDraft(false)"
            >
              Live
            </button>
          </div>
        </template>
        <template v-slot:center>
          <div
            v-if="draftMode"
            class="center"
          >
            <select class="hlt-select">
              <option>Select State</option>
              <option>New</option>
              <option>Review</option>
              <option>Edit</option>
              <option>Preflight</option>
              <option>Approved</option>
            </select>
            <button
              v-if="!saveDisabled"
              class="hlt-button primary save-button"
              :class="{ grey: (!draftMode || !canWriteToAsset) }"
              @click="canWriteToAsset && draftMode && save()"
            >
              Save Changes
            </button>
            <button
              v-if="saveDisabled"
              class="hlt-button primary disabled save-button"
            >
              <spinner /> Save Changes
            </button>
          </div>
          <div
            v-else
            class="center"
          />
        </template>
        <template v-slot:right>
          <div
            v-if="draftMode"
            class="right"
          >
            <button
              class="hlt-button secondary overwrite"
              :class="{ grey: !draftMode || !canOverwriteLive }"
              @click="canWriteToAsset && canOverwriteLive && draftMode && overwriteLive()"
            >
              Overwrite Live
            </button>
          </div>
        </template>
      </edit-footer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Spinner from '../../assets/shared/Spinner'
import railsRoutes from '../../../store/rails-routes'
import ProductAssociation from '../../assets/shared/ProductAssociation'
import axios from 'axios'
import Card from './Card'
import OrderBox from '../../shared/OrderBox'
import DeleteOrderBoxModal from '../../shared/DeleteOrderBoxModal'
import EditFooter from '../../assets/shared/page_components/EditFooter'
import randomize from '../../../mixins/randomize-choices'
import AssetDescription from '../../assets/shared/AssetDescription'
import AssetDescriptionMixin from '../../../mixins/asset-description'
import Metadata from '../../assets/shared/Metadata'
import SaveModal from '../../assets/shared/SaveModal'


export default {
  name: 'Edit',
  components: {
    AssetDescription,
    Card,
    DeleteOrderBoxModal,
    EditFooter,
    OrderBox,
    ProductAssociation,
    Spinner,
    Metadata,
    SaveModal
  },
  mixins: [randomize, AssetDescriptionMixin],
  data () {
    return {
      draftMode: true,
      editing: {
        editingDescription: false,
        editingInformation: false,
        editingCompletion: false
      },
      saveDisabled: false,
      showAddSection: false,
      boxStyles: {
        header: ['shaded-background'],
        content: ['shaded-background']
      }
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset',
      'currentUser',
      'canWriteToAsset',
      'errors'
    ]),
    sortedCards: {
      get () {
        const that = this
        if (this.asset.cards) {
          return that._.orderBy(this.asset.cards, ['order'], ['asc'])
        } else {
          return []
        }
      },
      set (value) {
        this.asset.cards = this.orderArray(value, 'order')
      }
    },
    sortedActiveCards () {
      return this.sortedCards.filter(x => !x.deleted)
    },
    canOverwriteLive () {
      return this.currentUser.permissions.can_overwrite_live_decks
    }
  },
  created () {
    this.setAssetType({ assetType: 'deck' })
    this.apiDecksById({ deckId: this.$route.params.id }).then(response => {
      var record = response.data.records[0]
      this.edit(record)
      this.asset.cards = this._.sortBy(this.asset.cards, ['order']).filter(s => !s.deleted)
      this.draftMode = !this.asset.live_layer
    })
  },
  methods: {
    ...mapActions([
      'edit',
      'delete',
      'update',
      'apiDecksById'
    ]),
    ...mapMutations([
      'setAsset',
      'setAssetType',
      'setDeleteAsset',
      'setDeletableAsset',
      'resetDeletableAsset',
      'setErrors',
      'resetErrors',
      'setOrderBoxReordering'
    ]),
    moveBox ({ index, card, direction }) {
      const incrementedSection = item => {
        item.order += 1
      }

      const decrementedSection = item => {
        item.order -= 1
      }

      const item = this.sortedCards[index]
      if (direction === 'up') {
        const incrementedItem = this.sortedActiveCards[(item.order - 1) - 1]
        incrementedSection(incrementedItem)
        decrementedSection(item)
      } else {
        const decrementedItem = this.sortedActiveCards[(item.order - 1) + 1]
        incrementedSection(item)
        decrementedSection(decrementedItem)
      }

      const obj = {
        ...this.asset,
        cards: this.sortedCards
      }
      this.setAsset({ asset: obj })
      this.setOrderBoxReordering(false)
    },
    disableSaving (value) {
      this.saveDisabled = value
    },
    save () {
      if (!this.saveDisabled) {
        this.saveDisabled = true
        if (this.asset.id) {
          this.update({ asset: this.asset, url: railsRoutes.api_path({ type: 'decks' }) }).then(response => {
            this.edit(response.data.records[0])
            this.saveDisabled = false
            this.resetErrors()
            this.setEditingAssetDescription(false)
            this.$notify({ title: 'Deck Saved', type: 'success' })
          }).catch(error => {
            this.saveDisabled = false
            if (error && error.response) {
              this.setErrors({ errors: error.response.data.errors })
              for (const [key, value] of Object.entries(error.response.data.errors)) {
                const regex = /cards\[(\d+)\]\.(\w+)/
                const result = key.match(regex)
                if (result) {
                  if (this.asset.cards[result[1]].errors === undefined) {
                    this.$set(this.asset.cards[result[1]], 'errors', {})
                  }
                  this.$set(this.asset.cards[result[1]].errors, result[2], value)
                }
              }
            }
            this.$notify({ title: 'Error saving deck', type: 'error' })
          })
        } else {
          // This should never happen
          this.saveDisabled = false
          this.$notify({ title: 'Error saving deck', type: 'error' })
        }
      }
    },
    addNewCard () {
      this.asset.cards.push({
        title: 'New Card',
        description: '',
        asset_type: null,
        asset_id: null,
        featured_image_id: null,
        deleted: false,
        order: this.sortedActiveCards.length + 1
      })
    },
    deleteBox (params) {
      const localCards = this.sortedCards
      localCards[params.index].deleted = true
      localCards[params.index].order = 9999999
      localCards[params.index].errors = {}

      // Adjust orders of everything after this section
      for (let x = params.index + 1; x < localCards.length; x++) {
        localCards[x].order -= 1
      }

      this.$set(this.asset, 'cards', localCards)
    },
    displayAssetTypeName (assetBType) {
      if (assetBType === 'V3Flashcard') {
        return 'Item'
      } else {
        return assetBType
      }
    },
    toggleDraft (val) {
      if ((this.asset.live_deck_id && val === false) || (this.asset.draft_deck_id && val === true)) {
        this.draftMode = !this.draftMode
        var recordId = null
        if (this.draftMode) {
          recordId = this.asset.draft_deck_id
        } else {
          recordId = this.asset.live_deck_id
        }
        if (this.$router) {
          this.$router.push({ path: `/decks/${recordId}/edit` })
          this.setAsset({ asset: {} })
        }
      }
    },
    overwrite () {
      if (!this.saveDisabled) {
        this.saveDisabled = true

        if (this.canWriteToAsset && this.draftMode && this.canOverwriteLive) {
          axios.put(railsRoutes.api_path({ type: 'decks', id: this.asset.id, action: 'overwrite_live' }), {
            deck: this.asset
          }).then(response => {
            this.saveDisabled = false

            if (response.data.records.length > 0) {
              const record = response.data.records[0]
              this.edit(record)
            }
            this.$notify({ title: 'Live Layer Overwriten', type: 'success' })
            this.$modal.hide('save-modal')
          }).catch(error => {
            console.error('Error: ', error)
            this.saveDisabled = false
            this.$notify({ title: 'Error Overwriting Live Layer', type: 'error' })
            this.$modal.hide('save-modal')
          })
        }
      }
    },
    overwriteLive () {
      this.$modal.show('save-modal')
    },
    cancel () {
      this.$modal.hide('save-modal')
    }
  }
}
</script>

<style lang="scss">
.decks-edit-container {
  position: relative;
  height: 100%;

  .asset-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .asset-description-inner {
      width: 100%;
    }
  }

  label {
    margin: 0;
  }
  .small-text {
    font-size: 14px;
    color: $space-black-50;
  }
  .deck-input {
    margin: 0 0 5px 0;
  }
  .deck-name-input {
    margin: 0;
  }
  .helper-text {
    color: $space-black-50;
    font-size: .75rem;
  }
  .main-deck-container {
    padding-bottom: 20px;
  }
  .outline-link {
    color: $space-black;
    transition: .3s;
    &:hover {
      color: $space-gray;
      cursor: pointer;
    }
  }
  .container-textarea {
    font-size: 14px;
    padding: 10px;
    height: auto;
    min-height: 150px;
    border: 1px solid $space-gray;
    background: $white-2;
    border-radius: 4px;
    &:hover {
      border: 1px solid $sky-blue;
    }
  }
  .empty-content {
    color: $space-gray;
  }
  .deck-edit-content{
    display: flex;
    padding: 0px;
    justify-content: space-between;
    flex-direction: column;
  }
  .main-container {
    width: 100%;
    padding: 0px 60px;
    margin-bottom: 2rem;
    .main-section {
      display: flex;
      justify-content: space-between;
      .left-edit {
        flex: 4;
        padding-right: 44px;
      }
      .right-edit {
        flex: 1;
      }
    }
  }
  .main-section-container {
    padding-bottom: 100px;
  }
  .deck-name-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .left-section {
      flex: 1;
      padding-right: 24px;
    }
  }
  .deck-container {
    margin-bottom: 24px;
  }

  .asset-b-input {
    margin-bottom: 4px;
  }
  .section-area {
    padding-bottom: 24px;
  }
  .deck-edit-active .mce-tinymce {
    border: 1px solid $sky-blue;
    border-radius: 4px;
  }
  .outline-title {
    font-size: 12.8px;
  }
  .outline-side-list {
    margin: 0;
    margin-bottom: 16px;
  }
  .outline-listing {
    list-style: none;
  }
  .outline-asset-title {
    font-size: 12.8px;
  }
  .outline-header {
    display: block;
    margin: 0;
    margin-bottom: 8px;
  }
  .association-section {
    padding: 1.75rem 12rem;
    border-top: 4px solid $grey-4;
    margin-bottom: 4rem;
  }
  .decks-footer {
    width: 100%;
    padding: 1.75rem 8rem;
    background: $blue-5;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    .deck-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 5rem;
    }
    .deck-select {
      width: 50%;
    }
  }
  .draft-live-footer {
    .hlt-select {
      margin: 0 30px;
      width: 300px;
    }
  }
  .deck-text-header {
    margin-bottom: 13px;
  }
  .deck-text-subheader {
    margin: 0;
    padding-bottom: 8px;
  }
}
</style>
