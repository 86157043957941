<template>
  <modal
    name="add-asset-association-modal"
    height="auto"
    :scrollable="false"
    class="vue-modal add-asset-association-modal"
    width="45%"
    :click-to-close="false"
    @before-open="beforeOpen"
  >
    <div
      v-on-clickaway="resetModal"
      class="vue-modal-inner"
      @click.stop=""
    >
      <div class="vue-modal-topbar">
        <h4>{{ editText }} {{ humanContext }}</h4>
      </div>
      <div class="modal-container">
        <div class="input-flexbox">
          <div class="input">
            <label>Asset Type</label>
            <select
              v-model="assetAssociation.asset_b_type"
              name="asset_type"
              class="asset-type-selection"
              :disabled="!draftMode || !canWriteToAsset"
            >
              <option value="">
                Select Asset Type
              </option>
              <option value="Attachment">
                Attachment
              </option>
              <option value="V3Flashcard">
                Item
              </option>
              <option value="Topic">
                Topic
              </option>
              <option value="Grouping">
                Grouping
              </option>
            </select>
          </div>
          <div class="input">
            <label>Asset ID</label>
            <input
              v-model="assetAssociation.asset_b_id"
              type="number"
              :class="{'error': errorAsset}"
              :disabled="!draftMode || !canWriteToAsset"
            >
            <error-message
              v-if="errorAsset"
              :message="prettyErrors"
            />
          </div>
          <div class="input">
            <label>Order</label>
            <input
              v-model="assetAssociation.order"
              name="order"
              type="number"
              :disabled="!draftMode || !canWriteToAsset"
            >
          </div>
          <div
            v-if="['V3Flashcard'].includes(assetAssociation.asset_a_type)"
            class="input"
          >
            <label>Display Type</label>
            <select
              v-model="assetAssociation.display_type"
              name="display-type"
              class="context-selection"
              :disabled="!draftMode || !canWriteToAsset"
            >
              <option value="">
                Select Display Type
              </option>
              <option value="button_after">Button After</option>
              <option value="embed_before">Embed Before</option>
              <option value="scenario_box">Scenario Box</option>
            </select>
          </div>
          <div class="input" v-if="showButtonText">
            <label>Button Text</label>
            <multiselect
              ref="multiselect"
              v-model="assetAssociation.button_text"
              class="button-input-type-selection"
              :options="buttonTextOptions"
              :multiple="false"
              :disabled="!draftMode || !canWriteToAsset"
              :searchable="true"
              :max-height="150"
              :show-no-results="true"
              :allow-empty="true"
              :clear-on-select="false"
              :show-labels="false"
              placeholder="Type or select text"
              @search-change="onInput"
            >
              <template #noResult>
                <a @click.prevent.stop="addNewOption(assetAssociation.button_text)">
                  Add new text
                </a>
              </template>
            </multiselect>
          </div>
          <div
            v-if="availableContexts"
            class="input"
          >
            <label>Button Location</label>
            <select
              v-model="assetAssociation.asset_a_context"
              name="context"
              class="context-selection"
              :disabled="!draftMode || !canWriteToAsset"
            >
              <option value="">
                Select Button Location
              </option>
              <option
                v-for="(text, index) in availableContexts"
                :key="index"
                :text="text"
                :value="text"
              >
                {{ text }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="vue-modal-bottombar modal-buttons">
        <button
          v-if="canWriteToAsset"
          class="button solid-green-button modal-button"
          :disabled="createDisabled"
          @click="saveAssociation()"
        >
          <span>{{ saveText }}</span>
        </button>
        <button
          class="button modal-button"
          @click="resetModal()"
        >
          Cancel
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import railsRoutes from '../../../store/rails-routes'
import { mixin as clickaway } from 'vue-clickaway2'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  mixins: [ clickaway ],
  data () {
    return {
      errors: {},
      availableContexts: null,
      assetAssociation: {
        asset_a_type: null,
        asset_a_id: null,
        asset_a_context: null,
        asset_b_context: null,
        asset_b_type: null,
        asset_b_id: null,
        button_text: null,
        order: null,
        display_type: null
      },
      buttonTexts: [
        'View additional text',
        'See related topic',
        'View passage',
        'View graph',
        'View case',
        'View table',
        'View exhibit',
        'Launch quiz',
        'View image',
        'Launch audio',
        'Launch video',
        'View mnemonic',
        'View strategy',
        'Tap to Interact'
      ],

      humanContext: null,
      saveText: '',
      editText: '',
      editing: false
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'topics',
      'draftMode',
      'canWriteToAsset'
    ]),
    showButtonText () {
      return this.assetAssociation.display_type?.includes('button') ||
        ['Topic'].includes(this.assetAssociation.asset_a_type)
    },
    buttonTextOptions () {
      let textArr = this.buttonTexts
      return textArr.sort()
    },
    computedContext () {
      if (this.context == null) {
        return ''
      } else {
        return this.context
      }
    },
    createDisabled () {
      return (
        (this.assetAssociation.asset_b_type === '') ||
        (this.assetAssociation.asset_b_id === '') ||
        (this.availableContexts && !this.assetAssociation.asset_a_context)) ||
        !this.draftMode
    },
    errorAsset () {
      return !!(this.errors['asset_b'])
    },
    prettyErrors () {
      if (this.errors['asset_b']) {
        if (this.errors['asset_b'].indexOf("can't be blank") >= 0) {
          return 'Invalid Asset ID'
        }
        if (this.errors['asset_b'].indexOf('must be a live asset') >= 0) {
          return 'Must be a Live Asset ID'
        }
      }
      return 'Error'
    }
  },
  methods: {
    onInput (value) {
      if (!value) return
      this.assetAssociation.button_text = value
    },
    addNewOption (option) {
      this.buttonTexts.push(option)
      this.assetAssociation.button_text = option
    },
    beforeOpen (event) {
      if (event && event.params) {
        if (event.params.assetAssociation) {
          this.assetAssociation = event.params.assetAssociation
        }

        if (event.params.availableContexts) {
          this.availableContexts = event.params.availableContexts
        }

        if (event.params.humanContext) {
          this.humanContext = event.params.humanContext
        }

        if (event.params.saveText) {
          this.saveText = event.params.saveText
        }

        if (event.params.editText) {
          this.editText = event.params.editText
        }

        if (event.params.editing) {
          this.editing = event.params.editing
        }
      }
    },
    saveAssociation () {
      if (this.assetAssociation.asset_b_id) {
        // 1. Send the asset_association to the API to validate it
        // 2. If errors are returned, display the error message in the modal form
        // 3. If no errors, push to the asset and close the modal
        axios.post(railsRoutes.api_path({ type: 'asset_associations', action: 'validate' }),
          { asset_association: this.assetAssociation }).then(response => {
          if (!this.editing) {
            if (this.assetAssociation.asset_a_type == 'Topic') {
              this.addTopicAssetAssociation({ id: response.data.asset_association.asset_a_id, association: response.data.asset_association })
            } else if (response.data.asset_association.asset_a_type == 'V3Flashcard') {
              this.addAssetAssociation({ association: response.data.asset_association })
            }
          }
          this.resetModal()
        }).catch(error => {
          console.error(error)
          this.errors = error.response.data.errors
          this.$notify({ title: `Error adding ${this.humanContext}`, type: 'error' })
        })
      }
    },
    resetModal () {
      this.assetID = ''
      this.assetAssociation = {
        asset_a_type: null,
        asset_a_id: null,
        asset_a_context: null,
        asset_b_context: null,
        asset_b_type: null,
        asset_b_id: null,
        button_text: null,
        order: null,
        display_type: null
      }
      this.saveText = ''
      this.editText = ''
      this.humanContext = ''
      this.availableContexts = null

      this.errors = {}
      if (this.$modal) {
        this.$modal.hide('add-asset-association-modal')
      }
    },
    ...mapMutations([
      'addAssetAssociation',
      'addTopicAssetAssociation'
    ])
  }
}
</script>
<style lang="scss">
.add-asset-association-modal {
  .modal-container {
    padding: 20px;
    margin-bottom: 20px;
    .input-flexbox {
      display: flex;
      flex-wrap: wrap;
      .input {
        width: 230px;
        margin-right: 40px;
      }
    }

    .row {
      margin-left: 0;
      margin-bottom: 20px;
      margin-bottom: 1.25rem;
    }
    .button-input-type-selection {
      [type='text'] {
        border: none;
        box-shadow: none;
      }
    }
    .multiselect__placeholder {
        color: #0a0a0a;
        font-size: 1rem;
        display: inline-block;
        margin-bottom: 10px;
        padding-top: 2px;
    }

    .multiselect__tags {
      border: 1px solid #cacaca !important;
      padding: 8px 6px 0;
      border-radius: 0;
      height: 40px;
    }
    .multiselect__content, .multiselect__content, .multiselect__content-wrapper {
      height: 150px !important;
      max-height: 150px !important;
      margin-bottom: 20px;
    }
    .multiselect__content-wrapper {
      margin-top: 0;
    }
    .multiselect__single {
      font-size: 1rem;
    }
    .multiselect__select {
      width: 26px;
    }
    .multiselect__input {
      background: transparent;
    }
    .button-input-type-selection [type='text'] {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding-bottom: 12px;
    }
    .multiselect__select:before {
        border-width: 6px 4px 0;
        border-color: #999 transparent transparent;
    }
    .multiselect__option {
      color: $grey-1;
    }
    .multiselect__option--selected {
      background: #f3f3f3;
      color: $blue-1;
      font-weight: 700;
    }

    .multiselect__option--highlight {
      background: $blue-4;
      outline: none;
      color: #fff;
    }
  }

  label {
    font-size: 1em;
    font-weight: 600;
  }

  input {
    margin-bottom: 5px;
  }
}

</style>
